import {StyleSheet} from "react-native";
import {theme} from "../../../constants/theme";

export const styles = StyleSheet.create({
    container: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: theme.spacing(1),
    },
    legendBox: {
        width: theme.spacing(5),
        height: theme.spacing(3),
        marginRight: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        borderWidth: 2,
        borderColor: theme.palette.WHITE,
    },
    text: {
        ...theme.typography.subtitle,
    },
});
