import {StyleSheet} from "react-native";
import {theme} from "../../constants/theme";

export const containerStyles = StyleSheet.create({
    container: {
        backgroundColor: theme.palette.WHITE,
        flex: 1,
        overflow: "scroll",
    },
});

export const contentStyles = StyleSheet.create({
    heading: {
        paddingTop: theme.spacing(2),
        paddingHorizontal: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    mainText: {
        paddingHorizontal: theme.spacing(2),
        marginBottom: theme.spacing(2),
        fontSize: 16,
        lineHeight: 18,
    },
    graphicContainer: {
        width: "50%",
        justifyContent: "center",
        marginTop: theme.spacing(4),
    },
    image: {
        maxWidth: 300,
        maxHeight: 300,
    },
    rewardsBtn:{
        margin: 18
    }
});