import React from 'react';
import {StyleSheet, TouchableOpacity, View, FlatList, ScrollView} from 'react-native';
import connect from "../../../constants/connect";
import { Routes } from "react/navigation/root/root.paths";
import Strings from "../../../constants/localization/localization";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import { ParkSession } from "../../../model/ParkSession";
import { ParkSessionDTO } from "../../../model/ParkSessionDTO";
import { useActiveParkSession, useParkSessionsWithCursor } from '../../../api/parkSession/parkSession.api';
import { usePark } from '../../../api/park/park.api';
import localizeCurrency from '../../../constants/localization/localizeCurrency';
import { useTerritory } from '../../../api/territory/territory.api';
import moment from 'moment-timezone';
import { getId } from "../../../constants/Util";
import {createRoute, NavigationProps} from "../../../navigation/constants";
import ParkableBaseView from "../../common/ParkableBaseView";

const SEARCH_COUNT = 10;

const sessionDateFormat = "hh:mma, dddd DD MMMM YYYY";

const NewSessionHistoryView = (props: NavigationProps<Routes.SessionHistoryView>) => {
    const {navigation} = props;

    const {size, setSize, isValidating, data, mutate} = useParkSessionsWithCursor(SEARCH_COUNT);

    const {parkSession } = useActiveParkSession();

    const renderItem = (item: { index: number, item: ParkSession }) => {
        return <SessionRow key={item.index} session={item.item}/>
    }

    const handleLoadMore = () => {
        const shouldFetchMore = (() => {
            if (size <= 1) {
                return true;
            }
            if (data?.[size - 1]?.parkSessions?.length === 0) {
                return false;
            }
            return data?.[size - 1]?.cursor !== data?.[size - 2]?.cursor
        })();

        if (shouldFetchMore) {
            void setSize(size + 1);
        }
    };

    const renderFooterList = () => {
        if (!isValidating) {
            return null;
        }
        return <View style={{flex:1}}>
            <TableRow contentLeft={<Spinner/>}>
                {Strings.loading}
            </TableRow>
        </View>
    }

    const handlePress = (session: ParkSession) => {
        navigation.push(Routes.SessionHistoryDetailView, {sessionId: session.id});
    }

    const SessionRow = (props: {session: ParkSession}) => {
        const {session} = props;

        const {transactionAmount, park: parkId} = session;

        const {park} = usePark(getId(parkId));

        const {territory} = useTerritory(park?.territory);

        return (
            <TouchableOpacity key={session.id} onPress={()=> {
                if ((session.transactionSuccess === false || session.transactionSuccess === null)
                && (session.startedAt !== null && session.endedAt !== null)) {
                    return redirectToRetryTransaction(session.id);
                }
                handlePress(session)
            }}>
                <TableRow
                    label={moment(session?.startedAt || undefined).tz(park?.timeZoneId??'UTC').format(sessionDateFormat)}
                    chevronText={(transactionAmount !== null)?localizeCurrency(transactionAmount, territory?.currencyCode, false): Strings.not_available}
                    chevron>
                    {park?.address?? Strings.cannot_find_location}
                </TableRow>
            </TouchableOpacity>
        );
    }

    const onRetryPaymentSuccess = (updated: ParkSession | ParkSessionDTO | null) => {
        console.log("retry payment updated", updated);
        void mutate();
    }

    const redirectToRetryTransaction = (sessionId: number) => {
        return navigation.push(Routes.RetryPaymentRequest, {sessionId, callback: onRetryPaymentSuccess});
    }

    const sessions: ParkSession[] = data?.reduce((arr, page) => {
        return [...arr, ...(page.parkSessions??[])];
    }, [] as ParkSession[])??[];
    const filteredSessions = sessions
      .filter(session => session.id !== parkSession?.id)
      .filter(session => !!session.startedAt)

    return (
            <ParkableBaseView scrollable={false}>
                    <View style={styles.header}>
                        <Text h1 bold>{Strings.parking_history}</Text>
                    </View>
                <ScrollView style={styles.container}>
                    <View style={[styles.section]}>
                    {!!data && (sessions.length === 0
                        ? <Text>{Strings.no_parking_session}</Text>
                        : <>
                            <View style={styles.row}>
                                <View style={styles.leftPart}>
                                    <Text strapline bold>{Strings.date_and_location}</Text>
                                </View>
                                <View style={styles.rightPart}>
                                    <Text strapline bold>{Strings.amount}</Text>
                                </View>
                            </View>

                            <FlatList
                                data={filteredSessions}
                                renderItem={renderItem}
                                keyExtractor={(item, index) => `${item?.id}_${index}`}
                                initialNumToRender={10}
                                onEndReached={handleLoadMore}
                                onEndReachedThreshold={0.1}
                                ListFooterComponent={renderFooterList}
                            />
                        </>)
                    }
                    </View>
                </ScrollView>
            </ParkableBaseView>
    );
}

export default connect(() => ({}))(NewSessionHistoryView) as React.FC;

export const SessionHistoryRoute = createRoute({
    path: Routes.SessionHistoryView,
});

const styles = StyleSheet.create({
    header: {
        paddingTop: 9,
    },
    section: {
        paddingTop: 9,
        flex: 1
    },
    footer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bottom: 200
    },
    container: {
        backgroundColor: Colours.WHITE,
    },
    row: {
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10
    },
    leftPart: {
        flex: 2
    },
    rightPart: {
        flex: 1,
        alignItems: 'flex-end'
    }
});
