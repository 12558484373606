import Strings from "../../../constants/localization/localization";
import TableRow from "react/parkable-components/tableRow/TableRow";
import { IconName } from "react/parkable-components/icon/Icons";
import Spinner from "react/parkable-components/spinner/Spinner";
import React, {useCallback, useEffect, useState} from "react";
import {Term} from "../../../model/Term";
import {getNextPaymentDate, getNextPaymentStartDate} from "./getNextPaymentDate";
import Constants from "../../../constants/constants";

type AllProps = {
    anchorDay?: number|null,
    weekAnchorDay?: string|null,
    term: Term,
    price?: number | undefined,
    status?: string|null,
    startDate?: string | null
}

export default function subscriptionPaymentTableRow(props: AllProps){

    const [label, setLabel] = useState("");
    const [icon, setIcon] = useState<IconName|undefined>(undefined);
    const [dateString, setDateString] = useState<string>(Strings.loading);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        console.log("props.anchorDay", props.anchorDay);
        console.log("props.weekAnchorDay", props.weekAnchorDay);
        console.log("props.startDate", props.startDate);
        console.log("props.status", props.status);
        if(!!props.anchorDay || !!props.weekAnchorDay){
            (props.price??0) === 0 ? setLabel(Strings.term_renews) : (props.status === Constants.Subscription_Status_Pending ?
                setLabel(Strings.date_of_first_payment) : setLabel(Strings.next_payment_will_be_on));
            setDateString(calculateNextPaymentDate(props.term, props.anchorDay||null, props.weekAnchorDay||null).format("Do MMMM YYYY"));
            setLoading(false)
            setIcon("calendar");
        }else if(!!props.startDate && props.status === Constants.Subscription_Status_Pending){
            setLabel(Strings.date_of_first_payment);
            setDateString(calculateNextPaymentStartDate(props.startDate, props.term).format("Do MMMM YYYY"));
            setLoading(false)
            setIcon("calendar");
        }
    }, [props.anchorDay, props.weekAnchorDay, props.price, props.startDate]);

    const calculateNextPaymentDate = useCallback((term: Term, anchorDay: number|null, weekAnchorDay: string|null) => {
        return getNextPaymentDate(term, anchorDay, weekAnchorDay);
    }, []);

    const calculateNextPaymentStartDate = useCallback((termStartDate: string, term: Term) => {
        return getNextPaymentStartDate(termStartDate, term);
    }, []);

    return (<TableRow condensed={true} contentLeft={loading && <Spinner />} iconLeft={icon}
                      label={label}>
        {dateString}
    </TableRow>)
}
