import React, {useCallback, useRef} from 'react';
import {StyleSheet, View} from 'react-native';
import TableRow from "react/parkable-components/tableRow/TableRow";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Spinner from "react/parkable-components/spinner/Spinner";
import Colours from "react/parkable-components/styles/Colours";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../constants/localization/localization";
import {Bay} from "../../model/Bay";
import { Routes } from "react/navigation/root/root.paths";
import {ParkingType} from "../../model/Types";
import {usePark} from "../../api/park/park.api";
import {useOrganisation} from "../../api/organisation/organisation.api";
import {useBayGroup} from "../../api/bayGroup/bayGroup.api";
import { useTandemPod } from '../../api/tandem/tandem.api';
import {useNavigation} from "../../navigation/constants";
import { getBaySignage } from 'react/constants/baySignage';
import {BayDTO} from "react/model/BayDTO";
import { BayActivityDTO } from "react/model/BayActivityDTO";

const SelectedBayCard = (props: Props) => {
    const {bay, onBaySelected, parkId, parkingType} = props;

    const { tandemPod, isLoading } = useTandemPod(bay?.tandemPod);
    const { park } = usePark(bay?.park);
    const {organisation} = useOrganisation(park?.organisation)
    const { bayGroup } = useBayGroup(organisation?.id, bay?.group);
    const signageBaysAvailable = props.baysAvailable?.filter(b => !!b.signage) || []; //only with signage bays
    const showWhenOneBayAvailable = useRef<DialogRef|null>(null);
    const availableSignageBays = signageBaysAvailable.length

    const navigation = useNavigation();

    const onChangeBayPress = useCallback(() => {
        navigation.navigate(Routes.SelectBay, {
            title:Strings.change_bay,
            showAllButton:true,
            baysAvailable: signageBaysAvailable,
            onBaySelected,
            bay,
            parkId: parkId,
            parkingType:parkingType,
            bayActivity: props.bayActivity,
        });
    },[signageBaysAvailable, onBaySelected, navigation]);

    const onChangeWarning = () => {
        showWhenOneBayAvailable?.current?.show()
    }

    if (isLoading || !bay) {
        return (
            <TableRow
                condensed
                contentLeft={
                    <View>
                        <Spinner />
                    </View>
                }
                label={Strings.bay_number}
            >
                {Strings.holding_bay + "..."}
            </TableRow>
        );
    }

    const { rowLabel, label } = getBaySignage(bay, bayGroup, tandemPod);

    if (!onBaySelected) {
        return (
            <TableRow
                condensed
                iconLeftProps={{ iconStyle: styles.iconStyle }}
                iconLeft={"baysign"}
                label={rowLabel}
                textProps={{ style: styles.textProps }}
            >
                {label}
            </TableRow>
        );
    }

    if (!availableSignageBays) {
        return (
            <TableRow
                condensed
                iconLeftProps={{ iconStyle: styles.iconStyle }}
                iconLeft={"baysign"}
                label={Strings.park_anywhere_in}
                textProps={{ style: styles.textProps }}
            >
                {bayGroup?.name}
            </TableRow>
        );
    }

    return (
        <View>
            <TableRow condensed
                      iconLeftProps={{ iconStyle: styles.iconStyle}}
                      iconLeft={!!bay ? "baysign" : undefined}
                      chevronText={Strings.change}
                      chevron={true}
                      onPress={availableSignageBays === 0
                        ? onChangeWarning
                        : onChangeBayPress}
                      label={rowLabel}
                      textProps={{ style: styles.textProps }}>
                {label}
            </TableRow>
            <Dialog
                ref={showWhenOneBayAvailable}
                title={Strings.sorry_no_other_available_bays}
                positiveText={Strings.ok}
            />
        </View>
    );
};

type Props = {
    parkingType: ParkingType;
    parkId: number;
    bay:Bay|BayDTO|undefined|null;
    baysAvailable?: Array<Bay|BayDTO>;
    onBaySelected?: (bay: Bay) => void;
    bayActivity?: BayActivityDTO | null;
}

export default SelectedBayCard;

const styles = StyleSheet.create({
    textProps: {
        fontWeight: "500",
    },
    iconStyle: {
        color: Colours.ORANGE
    },
});
