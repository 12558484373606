import React, { useState, useRef, useEffect } from "react";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import Strings from "../../../constants/localization/localization";
import MapView, { PROVIDER_GOOGLE, Region } from "react-native-maps";
import { CasualDisplayType, IMarker, IMarkerExtra, MapPreferences, ParkingType } from "react/model/Types";
import { User } from "react/model/User";
import { buildMarker } from "../../map/ParkMarkerBuilder";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import { Empty } from "react/redux/reducers/user";
import { ParkInfo } from "react/model/ParkInfo";
import MyMarker, { MarkerProps } from "../../map/Marker";
import { useTerritory } from "react/api/territory/territory.api";
import { usePark } from "react/api/park";
import { useUserRoles } from "react/api/user/user.api";
import { BayDTO } from "react/model/BayDTO";
import { useParkingPrice } from "react/api/parkingPrice/parkingPrice.api";
import { getCurrentDailyPricePeriod, getCurrentHourlyPricePeriod } from "react/services/parkingPrice.service";
import { useBayGroup } from "react/api/bayGroup/bayGroup.api";
import Card from "react/parkable-components/card/Card";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import { useDirectionsPressHandler } from "./Map.utils";

type Props = {
    parkId: number;
    mapPark?: ParkInfo;
    user?: User | Empty;
    displayType: CasualDisplayType;
    displayText?: string | undefined;
    parkingType: ParkingType;
    bay?: BayDTO | undefined;
    orgName?: string | undefined;
    isMotorBikeDefault: boolean;
    isHideOrgName?: boolean;
};

export default function LocationCard(props: Props) {
    const mapRef = useRef<MapView>(null);
    const { park } = usePark(props.parkId);
    const { territory } = useTerritory(park?.territory);
    const { userRoles } = useUserRoles();
    const [markerProps, setMarkerProps] = useState<MarkerProps | undefined>(undefined);
    const [mapRegion, setMapRegion] = useState<Region | undefined>(undefined);

    const { bayGroup } = useBayGroup(park?.organisation, props.bay?.group);
    const { pricePeriods } = useParkingPrice(bayGroup?.parkingPrice);
    const hourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;
    const dailyPricePeriod = !!pricePeriods ? getCurrentDailyPricePeriod(pricePeriods) : null;

    const handleDirectionsPress = useDirectionsPressHandler(park);

    useEffect(() => {
        let markerExtra: IMarkerExtra = {
            id: 0,
            parkIsOpen: true,
            userIsParkOwner: false,
            userIsOrgMember: true,
            parkIsFull: false,
            hasEVCharger: true,
            hasLongTerm: true,
            hasShortTerm: true,
            territory: territory?.id || 0,
            zIndex: 999,
            longTermOnlyText: localizeCurrency(props.bay?.pricePerWeek || 0, territory?.currencyCode, false),
            perDayText: localizeCurrency(dailyPricePeriod?.price || 0, territory?.currencyCode, false),
            perHourText: localizeCurrency(hourlyPricePeriod?.price || 0, territory?.currencyCode, false),
            spotsLeftText: localizeCurrency(hourlyPricePeriod?.price || 0, territory?.currencyCode, false),
            perWeekText: localizeCurrency(props.bay?.pricePerWeek || 0, territory?.currencyCode, false),
            perMonthText: localizeCurrency(props.bay?.pricePerMonth || 0, territory?.currencyCode, false),
        };
        if (!!park) {
            if (!!props.user && !!props.mapPark) {
                const mapPref: MapPreferences = {
                    casualDisplayType: props.displayType,
                    hideClosedParks: false,
                    hideFullParks: false,
                    parkingType: props.parkingType,
                };
                const marker: IMarker | null = buildMarker(
                    mapPref,
                    props.mapPark,
                    props.user,
                    userRoles ?? [],
                    props.isMotorBikeDefault
                );
                markerExtra = marker?.extra || markerExtra;
            }
            const markerProps: MarkerProps = {
                marker: {
                    id: "0",
                    time: new Date().getMilliseconds(),
                    location: { latitude: park.latitude, longitude: park.longitude },
                    coordinate: { latitude: park.latitude - 0.0002, longitude: park.longitude },
                    isCampus: false,
                    extra: {
                        ...markerExtra,
                        zIndex: 999,
                        longTermOnlyText: props.displayText || markerExtra.longTermOnlyText,
                        perDayText: props.displayText || markerExtra.perDayText,
                        perHourText: props.displayText || markerExtra.perHourText,
                        spotsLeftText: props.displayText || markerExtra.spotsLeftText,
                    },
                },
                isMotorBikeDefault: props.isMotorBikeDefault,
                preferences: {
                    casualDisplayType: props.displayType,
                    // longTermOnly: false,
                    hideClosedParks: false,
                    hideFullParks: false,
                    parkingType: props.parkingType,
                },
                parkingType: props.parkingType,
                onMarkerPress: () => {},
            };

            setMarkerProps(markerProps);
            const newRegion = {
                longitude: park.longitude,
                latitude: park.latitude,
                latitudeDelta: 0.01,
                longitudeDelta: 0.01,
            };
            setMapRegion(newRegion);
        }
    }, [park, userRoles]);

    return (
        <Card style={styles.card}>
            {mapRegion && (
                <MapView
                    key={"map"}
                    ref={mapRef}
                    pointerEvents={"none"}
                    style={styles.map}
                    provider={PROVIDER_GOOGLE}
                    region={mapRegion}
                    userLocationAnnotationTitle=""
                    pitchEnabled={false}
                    scrollEnabled={false}
                    moveOnMarkerPress={false}
                    rotateEnabled={false}
                    zoomEnabled={false}
                    showsMyLocationButton={false}
                >
                    {!!markerProps && (
                        <MyMarker
                            parkingType={props.parkingType}
                            onMarkerPress={markerProps.onMarkerPress}
                            marker={markerProps.marker}
                            preferences={markerProps.preferences}
                            isMotorBikeDefault={props.isMotorBikeDefault}
                            isHidePrice
                        />
                    )}
                </MapView>
            )}
            <View style={styles.footer}>
                <View style={styles.address}>
                    <View>
                        <Text numberOfLines={2} h5>
                            {park?.address}
                        </Text>
                    </View>
                </View>
                <TouchableOpacity onPress={handleDirectionsPress} style={styles.directions}>
                    <Text small style={{ color: Colours.BLUE, textAlign: "right" }}>
                        {Strings.directions}
                    </Text>
                </TouchableOpacity>
            </View>
        </Card>
    );
}

const styles = StyleSheet.create({
    card: {
        height: 261,
        flex: 1,
        backgroundColor: Colours.WHITE,
    },
    map: {
        flex: 1,
        width: "100%",
        height: "100%",
    },
    footer: {
        flexDirection: "row",
        padding: 9,
        alignItems: "center",
    },
    address: {
        flexBasis: "70%",
        marginTop: 11,
    },
    directions: {
        flexBasis: "30%",
    },
});
