import Colours from "react/parkable-components/styles/Colours";

export default {
  api: {
    name: "demo",
    url: "https://parkable-staging.appspot.com/api/",
  },
  firebase: {
    apiKey: "AIzaSyDWkx_JxlFd0sIlJHR5hiszDG_fHFWLgwk",
    authDomain: "parkable-staging.firebaseapp.com",
    appId: "1:394073742718:web:b86182e6ec15625e8ba72f",
    databaseURL: "https://parkable-staging.firebaseio.com",
    messagingSenderId: "394073742718",
    projectId: "parkable-staging",
    storageBucket: "parkable-staging.appspot.com",
  },
  environmentDisplayName: "DEMO",
  borderColour: Colours.RED,
  teamsClientId: "18cde738-d43b-4280-a669-40f7d5a84343",
  teamsAuthorityUrl: "https://login.microsoftonline.com/",
}
