import Strings from "../../../constants/localization/localization";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import TableRow from "react/parkable-components/tableRow/TableRow";
import { IconName } from "react/parkable-components/icon/Icons";
import Spinner from "react/parkable-components/spinner/Spinner";
import React, {useEffect, useState} from "react";

type AllProps = {
    isWeeklyTerm?: boolean,
    price?: number,
    currencyCode?: string,
    remarks?: string,
}

export default function subscriptionPriceTableRow(props: AllProps){

    const [label, setLabel] = useState("");
    const [icon, setIcon] = useState<IconName|undefined>(undefined);
    const [price, setPrice] = useState(Strings.loading);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(!!props.price){
            const currencyCode = props.currencyCode || 'NZD';
            setLabel(props.isWeeklyTerm ? Strings.weekly_subscription : Strings.monthly_subscription);
            setPrice(`${localizeCurrency(props.price, currencyCode, false)} ${props.isWeeklyTerm ? Strings.per_week : Strings.per_month}`);
        }
        else if(props.price === 0){
            setPrice(Strings.this_sub_is_free);
        }

        setLoading(false);
        setIcon("key");
    }, [props.isWeeklyTerm, props.price, props.currencyCode]);

    return (<TableRow condensed={true} contentLeft={loading && <Spinner/>} iconLeft={icon}
                      label={label}>
        {`${price} ${props.remarks??''}`}
    </TableRow>)
}
