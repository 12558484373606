import { Nully } from "../constants/nully";
import { useAppDispatch, useReduxMaps, useSelector } from "../redux/redux";
import { IRootReducer } from "../redux/reducers/main";
import { usePushNotifications } from "./pushNotifications/pushNotification";
import { useEffect, useMemo, useState } from "react";
import { useCurrentParkingSession } from "../api/parking";
import { useEmployeeSubscriptions } from "../api/employeeSubscription/employeeSubscription.api";
import { Routes } from "./root/root.paths";
import { EmployeeSubscription, EmployeeSubscriptionStatus } from "../model/EmployeeSubscription";
import * as Linking from "expo-linking";
import { FirebaseService } from "../services/firebase.service";
import { getQRAction } from "../redux/actions/qrAction";
import QRAction, { ActionType } from "../model/QRAction";
import QRActionHandler from "../components/common/qrscanner/QRActionHandler";
import { getInitialUrl, Route, useNavigation } from "./constants";
import {useTeamsContext} from "../components/teams/context";

export const useInitialRoute = () : Nully<Route> => {

    const dispatch = useAppDispatch();
    const { user, createUserData } = useSelector((state: IRootReducer) => state.user);
    const navigation = useNavigation();
    const pushRoute = usePushNotifications();
    const [deepLinkRoute, setDeepLinkRoute] = useState<Route>();
    const { parkSession } = useCurrentParkingSession();
    const { data } = useEmployeeSubscriptions();
    const { preferences } = useReduxMaps();

    const landingRoute = useMemo(() => ({ name: Routes.LandingView }), []);
    const subscriptionListRoute = useMemo(() => ({ name: Routes.SubscriptionListView }), []);
    const activeSessionRoute = useMemo(() => ({ name: Routes.ActiveSession }), []);
    const [teamsRoute, setTeamsRoute] = useState<Route|undefined>();

    const {insideTeamsEnvironment, teamsLoggingIn} = useTeamsContext();

    const hasActiveSubscription = data?.subscriptions.some((r: EmployeeSubscription) => [
        EmployeeSubscriptionStatus.Active,
        EmployeeSubscriptionStatus.HostEnding,
        EmployeeSubscriptionStatus.LeaserEnding,
        EmployeeSubscriptionStatus.PastDue
    ].includes(r.status));

    // Don't show ActiveSessionView on app load if it's a reservation.
    const hasActiveSession = parkSession?.startedAt;

    async function handleInitialUrl() {
        const initialURL = await getInitialUrl();
        if (initialURL) {
            await handleDeeplink(initialURL);
        }
    }

    useEffect(() => {
        handleInitialUrl().catch(console.error);
    }, []);

    useEffect(() => {
        if(!insideTeamsEnvironment) {
            const subscription = Linking.addEventListener("url", listener);
            return () => subscription?.remove();
        }
    }, [insideTeamsEnvironment]);

    const listener = async (event: Linking.EventType) => {
        await handleDeeplink(event.url);
    };

    const handleDeeplink = async (deeplink: string) => {
        if (!deeplink) {
            return;
        }

        if(FirebaseService.isSignInWithEmailLink(deeplink)) {
            // This is handled in AuthProvider.tsx
            return;
        }

        //non-QRAction links
        if (deeplink.includes("future_booking")) {
            setDeepLinkRoute({name: Routes.UserBookingsAndRequestsView});
            return;
        }

        if(deeplink.includes("teams-auth-start")){
            setTeamsRoute({ name: Routes.TeamsAuthStart });
            return;
        }
        else if(deeplink.includes("teams-auth-end")){
            setTeamsRoute({ name: Routes.TeamsAuthEnd });
            return;
        }
        else if (deeplink.includes("teams")) {
            console.log("setTeamsRoute({ name: Routes.TeamsLandingPage });");
            setTeamsRoute({ name: Routes.TeamsLandingPage });
            return;
        }

        const regex = /\/\w+$/gi;
        const linkId = (deeplink.match(regex) ?? [null])[0]?.replace("/", "");
        if (linkId) {
            dispatch(getQRAction(linkId, linkHandler));
        }
    };

    const linkHandler = (qrAction: QRAction) => {

        console.log("main Handling QR Action", qrAction, qrAction.actionType);
        const { parkId, bayId, campusId, organisationId } = qrAction.actionParams;
        switch (qrAction.actionType) {
            case ActionType.ViewPark: {
                if (!!parkId) {
                    setDeepLinkRoute({
                        name: Routes.ParkDetailView,
                        params: { parkId }
                    });
                }
                break;
            }

            case ActionType.ViewCampus: {
                if (!!campusId) {
                    setDeepLinkRoute({
                        name: Routes.CampusScreenView,
                        params: { campusId, organisationId, ParkingType : preferences.parkingType }
                    });
                }
                break;
            }

            case ActionType.StartCharging: {
                if (!!parkId && !!bayId) {
                    console.log("qr action is StartCharging", { parkId, bayId });
                    setDeepLinkRoute({
                        name: Routes.StartChargingByLinkView,
                        params: { parkId, bayId }
                    });
                }
                break;
            }
            default:
                break;
        }

        QRActionHandler(qrAction, navigation, preferences);
    };

    const createAccountRoute = useMemo(() => ({
        name: Routes.CreateAccountView,
        params: {
            email: createUserData?.email,
            firstName: createUserData?.firstName,
            lastName: createUserData?.lastName,
            usingSSO: true,
            logoutOnBack: true
        }
    }), [createUserData]);

    console.log("mdtest teamsLoggingIn = ", teamsLoggingIn)


    console.log("mdtest user = ", user)
    if (!user && !createUserData) {
        if(insideTeamsEnvironment && teamsLoggingIn){
            return { name: Routes.TeamsLandingPage };
        }

        console.log("mdtest deeplink = ", deepLinkRoute)
        return landingRoute;
    }

    if (createUserData) {
        return createAccountRoute;
    }

    if (pushRoute) {
        return pushRoute;
    }

    if (deepLinkRoute) {
        return deepLinkRoute;
    }

    if (hasActiveSession) {
        return activeSessionRoute;
    }

    if (hasActiveSubscription) {
        return subscriptionListRoute;
    }
console.log("mdtest: returning null from getinitialroute");
    return null;
};
