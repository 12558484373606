import {StyleSheet} from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import {theme} from "../../constants/theme";

export const contentStyles = StyleSheet.create({
    container: {
        flex: 1,
    },
    section: {
        marginTop: theme.spacing(1),
    },
    infoText: {
        ...theme.typography.subtitle
    }
});

export const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    row: {
        display: 'flex'
    },
    cancelContainer: {
        marginTop: theme.spacing(3)
    },
    currentBalanceTxt:{
        fontSize: 100,
        marginTop: 18,
        lineHeight: 100,
    },
    handsImage: {
        flex: 1,
        aspectRatio: 375/168,
        zIndex: 1,
    },
    activityLabel:{
        marginTop:27,
        marginBottom:0
    },
    describeBox: {
        flexDirection: "row",
    },
    describeText: {
        lineHeight:26
    },
    helpIcon:{
        marginLeft: theme.spacing(1),
        width: 20,
        height: 20,
        opacity: 0.4
    },
    tabs:{
        flexDirection: "row",
        marginBottom: 9
    },
    rewardsTabs:{
        flex: 1
    },
    tabBtn:{
        borderBottomWidth: 1,
        borderColor: Colours.GREY_BORDER,
        width: "50%",
        justifyContent: 'space-between', padding: 9
    },
    selectedTab:{
        backgroundColor: Colours.BLUE,
        color: Colours.WHITE
    },
    pendingLabel:{
        width: "50%",
        justifyContent: 'space-between', padding: 9
    },
    redeemBtn: {justifyContent: 'space-between', marginTop: 27, padding: 9},
    noRewards:{
        marginTop: 18
    }
});

export const dialogStyles = StyleSheet.create({
    container: {
        width:'100%'
    },
    shareBtn: {
        borderColor: Colours.BLACK,
        borderWidth: 1,
        marginTop: 30,
        marginBottom: 10
    },
    closeBtn: {
        marginBottom: 20
    },
    fab: {
        backgroundColor: Colours.ORANGE
    },
    label: {
        color: Colours.ORANGE,
        marginBottom: 0,
        paddingBottom: 0
    },
    text: {
        fontSize:20,
        lineHeight:26,
        paddingLeft: 9,
        paddingRight: 9,
        paddingBottom: 18,
    },
    button:{
        marginBottom: 18,
    },
    icon: {
        color: Colours.BLACK
    }
});
