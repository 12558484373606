import Strings from "../../../constants/localization/localization";
import Text from "react/parkable-components/text/Text";
import Spinner from "react/parkable-components/spinner/Spinner";
import React, {useCallback, useMemo, useState} from "react";
import moment, { Moment } from "moment/moment";
import {connect} from "react-redux";
import {LayoutChangeEvent, StyleSheet, View} from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import {IRootReducer} from "../../../redux/reducers/main";
import {showAlert} from "../../../alerts";
import {EmployeeSubscription} from "../../../model/EmployeeSubscription";
import {updateEmployeeSubscriptionAvailability} from "../../../api/subscription";
import {setEmployeeSubscription} from "../../../redux/actions/subscriptions";
import CalendarDayComponent from "./CalendarDayComponent";
import CalendarButton from "./CalendarButton";
import Dashes from "../../common/Dashes";
import getParkAvailability from "../../../constants/getParkAvailability";
import { getDayAvailabilityInternal } from "../../../constants/availability/getDayAvailability";
import {OrganisationDTO} from "../../../model/OrganisationDTO";
import {Token} from "../../../api/rest";
import get24HoursAvailable from "../../../constants/availability/get24HoursAvailable";
import { useParkingRequestsInSubscriptionBay } from "react/api/parking-request/parking-request.api";
import { useUnshareBayContext } from "./context";
import { UnshareBayDialog } from "react/components/employeeSubscription/UnshareBayDialog";
import { BayInUseDialog } from "./BayInUseDialog";

type ShareBayComponentProps = {
    subscription: EmployeeSubscription
    currencyCode?: string,
    viewCarpark: () => void,
    organisation: OrganisationDTO | null,
    isRewardsEnabled: boolean,
    howRewardsWorksPress: () => void,
    hasBonusSessionMargin: boolean,
}

function shareBayComponent(props: ShareBayComponentProps & ReturnType<typeof getReduxProps> & typeof actions & typeof actions){
    const {
        subscription,
        isRewardsEnabled,
        howRewardsWorksPress,
        hasBonusSessionMargin,
        subSession,
        park,
        api,
        token,
        setEmployeeSubscription,
    } = props

    const CalendarDayComponentSize:number = 3
    const [calendarDayContainerSize, setCalendarDayContainerSize] = useState(0);
    const [disableCalendarDayComponents, setDisableCalendarDayComponents] = useState(false);
    const isOpen24hours = !!park && !!park.availability && get24HoursAvailable(park.availability, park.availability.permanentlyAvailable);

    const context = useUnshareBayContext();

    if(subscription) {
        context?.setSubscription(subscription)
        context?.setCurrentPark(park)
    }

    if(!!subSession) {
        context?.setActiveSession(subSession)
    }

    const availableDays = useMemo(() => {
        const availDays:Moment[] = [];
        if(!!park) {
            const avail = getParkAvailability(park).availability;
            for(let i = 0; i < 7; i++) {
                const day = moment().add(i, 'days')
                const dayAvail = getDayAvailabilityInternal(day.day(), avail);
                if (dayAvail.available) {
                    availDays.push(day);
                    if (availDays.length === CalendarDayComponentSize) {
                        break;
                    }
                }
            }
            if(availDays.length > 0 && availDays.length < CalendarDayComponentSize) {
                const date0:string = availDays[0].format('YYYY-MM-DD');
                //park open only 1-2 days per week
                for(let j = 1; j < 3; j++) {
                    const day0 = moment(date0);
                    const nextAvailDay = day0.add(7 * j, 'days');
                    availDays.push(nextAvailDay);
                    if(availDays.length === CalendarDayComponentSize) {break;}
                }
            }
        }
        return availDays;
    }, [park])

    const onSubViewLayout = useCallback((event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        const componentSize = (width- (3*9))/4;
        setCalendarDayContainerSize(componentSize);
    }, []);

    function updateSubscription(id:number, availDays: string[], showConfirmation: boolean, onDone = () => {}){
         updateEmployeeSubscriptionAvailability(api, token, id, availDays).then((r: any) => {
            setEmployeeSubscription(r.employeeSubscription);

            onDone();
         }).catch((err: any) => {
             showAlert(err?.message??Strings.internal_error_if_persists, Strings.error);
         })
    }

    const disableComponents = (disable: boolean) => {
        console.log("disableComponents = ", disable);
        setDisableCalendarDayComponents(disable);
    }

    const {parkingRequests, mutate: mutateParkingRequests} = useParkingRequestsInSubscriptionBay(subscription.id)

    if(parkingRequests) {
        context?.setParkingRequests(parkingRequests)
    }

    const confirmedBookingsOnDay = (dayKey: moment.Moment) => {
        if(parkingRequests) {
            return parkingRequests.some((request) => request.date === dayKey.format("YYYY-MM-DD"))
        }
    }

    const currentAvailableDays = subscription?.availabilityRules?.availableTo.filter((day) => moment(day.date).isAfter(moment()))
    const calendarDayExists = calendarDayContainerSize !== 0;

    //Only render when park open at least 1 day, i.e. availableDays.length > 0
    return availableDays.length > 0 && <View style={styles.mainView}>
                    <Text h4 bold style={{marginBottom: 3, }}>{Strings.share_my_bay}</Text>
                    {isRewardsEnabled && <View style={{marginBottom: 9}}>
                        <Text grey small>
                            {`${Strings.rewards.not_using_bay_tap_to_share} `}
                                <Text onPress={howRewardsWorksPress} small style={{ color:Colours.BLUE, marginLeft: 9}}>{Strings.rewards.earn_rewards}</Text>
                        </Text>
                    </View>}
                    {!isRewardsEnabled && hasBonusSessionMargin &&
                    <Text grey small style={{marginBottom: 9, }}>{Strings.not_using_bay_tap_to_share_1}
                        <Text grey small style={{marginBottom: 9, }}>{Strings.not_using_bay_tap_to_share_2}</Text>
                    </Text>}
                    {!hasBonusSessionMargin && <Text grey small style={{marginBottom: 9, }}>{Strings.not_using_bay_tap_to_share_1}.</Text>}
                    {isRewardsEnabled && <Text grey small style={{marginBottom: 9, }}>{Strings.rewards.simply_select_the_days_to_share}</Text>}
                    {calendarDayContainerSize === 0 && (
                        <View style={styles.dayLoad}>
                            <Spinner large />
                        </View>
                    )}
                    <View style={styles.subView} onLayout={onSubViewLayout}>
                        {calendarDayExists && (
                                <>
                                <CalendarDayComponent
                                    disabled={disableCalendarDayComponents}
                                    setUpdating={disableComponents}
                                    updateEmployeeSubscription={updateSubscription}
                                    style={[styles.calendarDayComponent, { width: calendarDayContainerSize }]}
                                    date={availableDays[0]}
                                    subscription={subscription}
                                    confirmedBooking={confirmedBookingsOnDay(availableDays[0]) ?? undefined}
                                    sharingToday
                                    activeSession={!!subSession}
                                />
                                <CalendarDayComponent
                                    disabled={disableCalendarDayComponents || (!!subSession && isOpen24hours)}
                                    setUpdating={disableComponents}
                                    updateEmployeeSubscription={updateSubscription}
                                    style={[styles.calendarDayComponent, { width: calendarDayContainerSize }]}
                                    date={availableDays[1]}
                                    subscription={subscription}
                                    confirmedBooking={confirmedBookingsOnDay(availableDays[1]) ?? undefined}
                                />
                                <CalendarDayComponent
                                    disabled={disableCalendarDayComponents}
                                    setUpdating={disableComponents}
                                    updateEmployeeSubscription={updateSubscription}
                                    style={[styles.calendarDayComponent, { width: calendarDayContainerSize }]}
                                    date={availableDays[2]}
                                    subscription={subscription}
                                    confirmedBooking={confirmedBookingsOnDay(availableDays[2]) ?? undefined}
                                />
                                <CalendarButton
                                    disabled={disableCalendarDayComponents}
                                    park={park}
                                    subscription={subscription}
                                    style={[ {height: "auto", width: calendarDayContainerSize }]}
                                    mutate={mutateParkingRequests}
                                />
                            </>
                        )}
                    </View>
                    <BayInUseDialog />
                    <UnshareBayDialog updateSubscriptionDay={updateSubscription} mutate={mutateParkingRequests}/>
                    <Dashes marginTop={18} width={"100%"} height={1} colour={Colours.GREY_BORDER} backgroundColour={Colours.WHITE}/>
                </View>
}

const getReduxProps = (state: IRootReducer, props: ShareBayComponentProps) => {
    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken
    } as Token;

    return {
        api: state.data.api,
        token: tokenObject,
        subscription: props.subscription,
        park: state.parks.parks[props.subscription?.park],
        subSession: state.subscriptions.employeeSubscriptionSessions[props.subscription?.id]
    }
}

const actions = {
    setEmployeeSubscription
}

export default connect(getReduxProps, actions)(shareBayComponent as React.FunctionComponent);


const styles = StyleSheet.create({
    mainView: {
        paddingTop: 24,
    },
    subView: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginTop: 9,
        backgroundColor: Colours.WHITE
    },
    dayComponent: {
        width: "25%"
    },
    calendarDayComponent: {
        height: "auto",
        marginRight: 9
    },
    dayLoad: {
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: Colours.GREEN,
        justifyContent: "center",
    }
});
