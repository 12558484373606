import React, {useState, useRef, useEffect} from "react";
import {ScrollView, StyleSheet, TouchableOpacity, View} from "react-native";
import {connect} from "react-redux";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import {DialogRef} from "react/parkable-components/dialog/Dialog";
import Dialog from "react/components/dialog/Dialog";
import {IRootReducer} from "../../../redux/reducers/main";
import Strings from "../../../constants/localization/localization";
import CreditCardItem from "./CreditCardItem";
import {deleteUserCardAPI, setDefaultCardAPI} from "../../../api/user";
import {userCardsReceived, getUserCards} from "../../../redux/actions/user";
import {showAlert} from "../../../alerts";
import {Token} from "../../../api/rest";
import {Routes} from "react/navigation/root/root.paths";
import {createRoute, NavigationProps} from "../../../navigation/constants";
import { useUserCards } from "../../../api/stripe/stripe.api";
import ParkableBaseView from "../../common/ParkableBaseView";

function CreditCards(props: Props) {
    const [loading, setLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [removePressed, setRemovePressed] = useState(false);
    const [cardToDelete, setCardToDelete] = useState<{ccId: string, index: number}|null>(null);
    const dialogRef = useRef<DialogRef|null>(null)
    const userCards = props.userCards;

    const { mutate } = useUserCards();

    useEffect(() => {
        (async () => {
            try {
                await props.getUserCards();
            } catch (error) {
            } finally {
                setLoading(false)
            }
        })();
    }, []);

    const setDefaultCard = (card: string) => {
        if(props.currentCardId !== card){
            setLoading(true);
            setDefaultCardAPI(props.api, props.token, card).then((r) => {
                setLoading(false);
                props.userCardsReceived(r);
                mutate();
            }).catch((err) => {
                if (!!err && err.message) {
                    showAlert(err.message, Strings.error);
                } else {
                    showAlert(Strings.internal_error_if_persists, Strings.error);
                }
            })
        }
    };

    const onRemoveCCPress = (ccId: string, index: number) => {
        setCardToDelete({ccId, index});
        dialogRef.current?.show();
    };

    const addNewCard = () => {
        const destination = {
            route: Routes.CreditCardView,
            params: {},
        };
        props.navigation.push(Routes.AddNewCard, { destination });
    };

    const onRemovePressed = (_isEditing: boolean) => {
        setIsEditing(_isEditing);
        setRemovePressed(_isEditing);
    };

    const onConfirmDeletionPress = () => {
        if(!cardToDelete) return;
        setLoading(true);
        deleteUserCardAPI(props.api, props.token, cardToDelete?.ccId).then(() => {
            const userCards = [...props.userCards];
            userCards.splice(cardToDelete?.index, 1);
            props.userCardsReceived({cards: userCards, currentCardId: props.currentCardId});

            let isEditing = true;

            if (userCards.length === 1) {
                isEditing = false;
            }
            setLoading(false);
            setIsEditing(isEditing);
            setRemovePressed(isEditing);

        }).catch(() => {
            setLoading(false);
        });
    };


    return <ParkableBaseView scrollable={false} loading={loading}>
        <Text h1>
            {Strings.saved_cards}
        </Text>
        <Text bold strapline style={{marginBottom: 7}}>
            {Strings.your_cards}
        </Text>
        <ScrollView>
            <View style={styles.mainContainer}>
                {userCards && userCards.length > 0 && <View>
                    {userCards.map((card, i) => {
                        return <CreditCardItem
                            key={i}
                            i={i}
                            card={card}
                            currentCardId={props.currentCardId}
                            isEditing={isEditing}
                            onRemovePress={onRemoveCCPress}
                            onCreditCardItemPress={setDefaultCard}
                        />
                    })}
                </View>}
            </View>
        </ScrollView>

        <View style={styles.footer}>
            {removePressed && <View style={styles.bottomButtonGroup}><Button style={styles.doneButton} onPress={onRemovePressed.bind(null, false)} center>{Strings.done}</Button></View>}
            {!removePressed && <View style={styles.bottomButtonGroup}>
                <TouchableOpacity style={styles.bottomButtons} onPress={addNewCard}>
                    <Text bold>{Strings.add_new_card}</Text>
                </TouchableOpacity>
                <View style={{width: "2%"}}/>
                <TouchableOpacity
                    disabled={userCards && userCards.length < 2}
                    style={styles.bottomButtons} onPress={onRemovePressed.bind(null, true)}>
                    <Text bold style={{
                        color: (userCards && userCards.length < 2) ? Colours.GREY_20 : Colours.BLACK
                    }}>{Strings.remove_card}</Text>
                </TouchableOpacity>
            </View>}
        </View>

        <Dialog ref={dialogRef}
                label={Strings.delete_card}
                labelProps={{style: {color: Colours.BLACK, textAlign: 'left'}}}
                title={Strings.delete_card_question}
                titleProps={{style: {textAlign: 'left'}, h2: undefined}}
                positiveText={Strings.delete_card}
                positiveProps={{red: true, textProps: {h5: true}}}
                negativeText={Strings.cancel}
                negativeProps={{textProps: {h5: true}}}
                onPositivePress={onConfirmDeletionPress}/>
    </ParkableBaseView>

}

const mapStateToProps = (state: IRootReducer) => {

    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken
    } as Token;

    return {
        user: state.user.user,
        userId: state.data.userId,
        userCards: state.user.cards,
        userVehicles: state.user.vehicles,
        currentCardId: state.user.currentCardId,
        currentVehicleId: state.user.currentVehicleId,

        currentSession: state.parking.currentSession,

        api: state.data.api,
        token: tokenObject,
        masterStripeApiKey: state.data.masterStripeApiKey,
    };
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & NavigationProps<Routes.CreditCardView>;

const mapDispatchToProps = {
    userCardsReceived,
    getUserCards
};
export default connect(mapStateToProps, mapDispatchToProps)(CreditCards) as React.FunctionComponent<Props>;

export const CreditCardRoute = createRoute({
    path: Routes.CreditCardView,
});

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        paddingHorizontal: 16,
        paddingTop: 16,
    },
    footer: {
        justifyContent: 'center',
        flexDirection: 'row',
        paddingTop: 27,
    },
    bottomButtonGroup: {
        width: "100%",
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    bottomButtons: {
        width: "47%",
        height: 54,
        borderColor: Colours.GREY_BORDER,
        borderRadius: 5,
        borderWidth: 1,
        paddingVertical: 10,
        marginVertical: 5,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    doneButton: {
        width: "100%",
    }
});
