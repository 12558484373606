import {Image, Linking, TouchableOpacity, View} from "react-native";
import React, {useEffect, useState} from "react";
import {createRoute, NavigationProps} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import * as MicrosoftTeams from "@microsoft/teams-js";
const splash = require("assets/meta/large-icon.png");
import Spinner from "react/parkable-components/spinner/Spinner";
import Button from "react/parkable-components/button/Button";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import {authenticateTeamsUser} from "../../api/teams/teams.api";
import {FirebaseService} from "../../services/firebase.service";
import {useCurrentUser} from "../../api/user/user.api";
import {ErrorCodes, scopes, style, validOrigins} from "./constants";
import Strings from "../../constants/localization/localization";
import useConfig from "../../hooks/useConfig";
import {useTeamsContext} from "./context";
import { useSelector } from "../../redux/redux";
import { IRootReducer } from "../../redux/reducers/main";

type Props = NavigationProps<Routes.TeamsLandingPage>;

const TeamsLandingPage =  (props: Props) => {
    const {navigation} = props;
    const [infoText, setInfoText] = useState<string|undefined>(undefined);
    const [errorText, setErrorText] = useState<string|undefined>(undefined);
    const { user } = useSelector((state: IRootReducer) => state.user);
    const [loading, setLoading] = useState(false);
    const [click, setClick] = useState<number|null>(null);
    const {insideTeamsEnvironment, setTeamsLoggingIn} = useTeamsContext();

    useEffect (() => {
        if(!insideTeamsEnvironment) {
            //leave logging for teams debugging
            console.log("not inside teams, navigating to standard landing page");
            navigation.reset({
                routes: [{
                    name: Routes.LoginOrSignUpView,
                }],
            });
            return;
        }

        const fn = async () => {
            const context = await MicrosoftTeams.app.getContext();
            console.log("TEAMS context = ", context.page.frameContext);
            if(context.page.frameContext === "authentication") {
                return;
            }

            let firebaseCustomToken = null;
            let requireInteractiveAuth = false;
            try {
                const token = await MicrosoftTeams.authentication.getAuthToken({silent: true});

                const response = await authenticateTeamsUser(token, context.user?.tenant?.id!);
                firebaseCustomToken = response.firebaseCustomToken;
            }
            catch (err:any) {
                if(err?.errorCode === ErrorCodes.SETUP_REQUIRED) {
                    //show marketing screen
                    setInfoText(Strings.teams_signup_info);
                    return;
                }
                //this error string may have other characters in it so need to search for 'resourceRequiresConsent'
                else if((err?.message || "").search("resourceRequiresConsent") !== -1 || err?.errorCode === ErrorCodes.INTERACTIVE_AUTH_REQUIRED){
                    //nothing to do, just fall through to login code
                    requireInteractiveAuth = true;
                }
                else{
                    setErrorText(err?.message ? Strings.an_error_occured(err?.message + " : " + err.errorCode) : Strings.error_try_again);
                    return;
                }
            }

            setLoading(true);

            if(requireInteractiveAuth){
                console.log("TEAMS LOG: requireInteractiveAuth true");
                firebaseCustomToken = await doInteractiveAuth();
            }

            if(firebaseCustomToken) {
                console.log("TEAMS LOG: firebaseCustomToken succeeded");
                await FirebaseService.signInWithCustomToken(firebaseCustomToken);
            }
        }
        void fn();

    }, [insideTeamsEnvironment]);

    const doInteractiveAuth = async () => {

        try {
            const result = await MicrosoftTeams.authentication.authenticate({
                url: window.location.origin + "/teams-auth-start",
                width: 600,
                height: 535,
            });
        }
        catch(err:any) {
            if (err.message === "consent_required" || err.message === "CancelledByUser") {
                console.log("setErrorText " + Strings.teams_permissions_required);
                setErrorText(Strings.teams_permissions_required);
            } else if (err.message === "FailedToOpenWindow") {
                console.log("setErrorText " + Strings.popups_blocked);
                setErrorText(Strings.popups_blocked);
            } else {
                console.log("setErrorText(" + err.message + ")");
                setErrorText(Strings.oh_no_something_went_wrong + err.message ? err.message + "." : " " + Strings.contact_parkable_support);
            }
            setLoading(false);
            return null;
        }

        const delay = (ms:number) => new Promise(res => setTimeout(res, ms));

        let retries = 1;
        while(retries < 5) {
            try {
                //retry this when it fails as token might need tim eto propagate
                const token = await MicrosoftTeams.authentication.getAuthToken({silent: true});

                const context = await MicrosoftTeams.app.getContext();
                const authResult = await authenticateTeamsUser(token!, context.user?.tenant?.id!);
                return authResult.firebaseCustomToken;
            } catch (err: any) {
                console.log("err.message", err.message);
                if (err.errorCode === ErrorCodes.SETUP_REQUIRED) {
                    //show marketing screen
                    console.log("TEAMS setErrorText " + Strings.teams_signup_info);
                    setInfoText(Strings.teams_signup_info);
                    break;
                } else if (err.message === "consent_required" || err.message === "CancelledByUser") {
                    //we may need to stand-off and wait for the permissions to propagate
                    const delayms = 1000 * 2^retries;
                    console.log("TEAMS delaying: ", delayms);
                    await delay(delayms);
                    ++retries;
                    console.log("TEAMS retrying auth");
                } else {
                    console.log("TEAMS setErrorText(" + err.message + ")");
                    setErrorText(Strings.oh_no_something_went_wrong + err.message ? err.message + "." : " " + Strings.contact_parkable_support);
                    break;
                }
            }
        }
        setLoading(false);
        return null;
    }

    const onWebClick = () => {
        Linking.openURL("https://parkable.com/parking-management-system").catch(console.error);
    };

    useEffect(() => {
        if (user) {
            console.log("mdtest: inside teams landing page,  user is ", user);
            console.log("mdtest: setTeamsLoggingIn(false);");
            setTeamsLoggingIn(false);
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            });
        }
    }, [user]);

    const changeServer = () => {
        setClick(prev => ((prev ?? 0) + 1) % 8);
    };

    useEffect(() => {
        if (click === 0) {
            navigation.push(Routes.ChangeServerView);
        }
    }, [click]);

    const reloadPage = () => {
        navigation.push(Routes.TeamsLandingPage);
    }
    return (
        <View style={[{flex:1, backgroundColor: Colours.WHITE, padding: 10}]}>
            <TouchableOpacity style={style.container} activeOpacity={1} onPress={changeServer}>
                <Image style={style.image} source={splash}  />
            </TouchableOpacity>
            {loading &&  <View style={{alignSelf: "center", flex: 1}}><Spinner large /></View>}
            {errorText && <Text style={style.text} center >{errorText}</Text>}
            {errorText && <Button style={style.text} center onPress={reloadPage}>{Strings.retry}</Button>}
            {infoText && <Text center style={style.text} >{infoText}<TouchableOpacity onPress={onWebClick}><Text style={{ color: Colours.BLUE, fontWeight: "normal", textDecorationLine: "underline" }}>{"www.parkable.com"}</Text></TouchableOpacity>.</Text>}
        </View>
    );
};

export default TeamsLandingPage;

export const TeamsLandingPageRoute = createRoute({
    path: Routes.TeamsLandingPage,
})




