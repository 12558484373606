import { Moment } from "moment-timezone";
import Strings from "../../constants/localization/localization";
import { theme } from "../../constants/theme";
import {
    ParkingRequest,
    ParkingRequestPriority,
    ParkingRequestStatus,
} from "../../model/ParkingRequest";
import { DayTileProps, FutureBookingStatus } from "./day-tile/types";
import { Nully } from "../../constants/nully";
import {Waitlist} from "../../api/waitlist/dto/Waitlist";
import {ParkSessionDTO} from "../../model/ParkSessionDTO";

const getWaitlistForDay = (waitlist: Nully<Waitlist>, dayIndex: number | undefined): number[] | undefined => {
    if (waitlist) {
        switch (dayIndex) {
            case 0:
            case 7: return waitlist.sunday
            case 1: return waitlist.monday
            case 2: return waitlist.tuesday
            case 3: return waitlist.wednesday
            case 4: return waitlist.thursday
            case 5: return waitlist.friday
            case 6: return waitlist.saturday
        }
    }

    return undefined;
}

export const computeFutureBookingStatus = (
    data: Pick<ParkingRequest, "id" | "status" | "priority" | "dayIndex" | 'campus' | 'park'> | undefined,
    waitlist: Nully<Waitlist>,
) => {
    const status = data?.status;
    const priority = data?.priority;
    if (status === ParkingRequestStatus.Accepted) {
        return priority === ParkingRequestPriority.High
            ? FutureBookingStatus.PriorityRequested
            : FutureBookingStatus.Requested;
    }
    if (status === ParkingRequestStatus.Confirmed) {
        return FutureBookingStatus.Confirmed;
    }
    if (status === ParkingRequestStatus.WaitList) {
        if ((getWaitlistForDay(waitlist, data?.dayIndex)
            ?.map((id, index) => ({ id, index }))
            ?.find(p => p.id === data?.id)?.index ?? 9999) < 3) {
            return FutureBookingStatus.WaitListedNearTop;
        }
        return FutureBookingStatus.WaitListed;
    }
    return undefined;
};

export const getDayTileProps = (date: Moment, today: Moment, activeSession?: Nully<ParkSessionDTO>): Omit<DayTileProps, 'loading'> => {
    const isToday = date.clone().startOf("day").isSame(today?.clone().startOf("day"));
    return {
        dayName: date.format("ddd"),
        dayNumber: date.date(),
        isInPast: !date
            .clone()
            .startOf("day")
            .isSameOrAfter(today?.clone().startOf("day")),
        isToday,
        hasActiveSession: !!activeSession,
    };
};

export const legendItems = [
    {
        text: Strings.common.today,
        color: theme.palette.WHITE,
        border: theme.palette.BLUE,        
    },
    {
        text: Strings.future_booking.status.requested,
        color: theme.palette.BLUE,
    },
    {
        text: Strings.future_booking.status.requested_priority,
        color: theme.palette.BLUE,
        badge: "priority" as const,        
    },
    {
        text: Strings.future_booking.status.confirmed,
        color: theme.palette.GREEN,
    },
    {
        text: Strings.future_booking.status.wait_list_near_top,
        color: theme.palette.ORANGE,
        badge: "waitlist" as const,   
    },
    {
        text: Strings.future_booking.status.wait_list,
        color: theme.palette.ORANGE,
    },
];

export const filterAvailableDays = (
    weeks: Array<Moment[/*array from monday to sunday*/]>,
    availability: readonly [
        boolean,
        boolean,
        boolean,
        boolean,
        boolean,
        boolean,
        boolean
    ]
) => weeks.map((week) => week.filter((day, i) => availability[(i + 1) % 7]));

/**
 * [Accepted ->] Priority -> Cancel
 *
 * Confirmed -> Cancel
 *
 * Waitlist -> Cancel
 */

export const computeNextRequestState = (
    currentStatus?: ParkingRequestStatus,
    currentPriority?: ParkingRequestPriority
) => {
    // TODO: How do we handle the current week changes?
    if (currentStatus === ParkingRequestStatus.Accepted) {
        return currentPriority === ParkingRequestPriority.High
            ? {
                nextStatus: ParkingRequestStatus.CancelledUser,
                nextPriority: undefined,
            }
            : {
                nextStatus: undefined,
                nextPriority: ParkingRequestPriority.High,
            };
    }

    if (currentStatus === ParkingRequestStatus.Confirmed) {
        return {
            nextStatus: ParkingRequestStatus.CancelledUser,
            nextPriority: undefined,
        };
    }

    if (currentStatus === ParkingRequestStatus.WaitList) {
        return {
            nextStatus: ParkingRequestStatus.CancelledUser,
            nextPriority: undefined,
        };
    }
    return undefined;
};

export type NextRequestState = ReturnType<typeof computeNextRequestState>;