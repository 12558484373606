import React, {useEffect, useState, useRef} from 'react';
import { View, LayoutChangeEvent} from "react-native";
import Text from "react/parkable-components/text/Text";
import Icon from "react/parkable-components/icon/Icon";
import Strings from "../../constants/localization/localization";
import Colours from "react/parkable-components/styles/Colours";

import moment from "moment";
import {useCurrentParkingSession} from "../../api/parking";
import {showHideReservationTimeOutModal} from "../../redux/actions/modal";
import {useAppDispatch} from "../../redux/redux";
import { useNavigation } from 'react/navigation/constants';
import { Routes } from 'react/navigation/root/root.paths';
import { parkReservationTime } from 'react/constants/Util';

type Props = {
    reservationMinutes?: number | null,
}

export default function ReservationTableRow(props: Props){

    const dispatch = useAppDispatch();

    const timeoutReservationInMinutes = 5;

    const [showTimeOutReservationModal, setShowTimeOutReservationModal] = useState(false);
    const { reservationMinutes } = props;
    const navigation = useNavigation();
    const [remainingText, setRemainingText] = useState("");
    const [holderSize, setHolderSize] = useState<{height:number, width:number, borderRadius:number}>({height: 27, width: 27, borderRadius:13.5});
    const { parkSession, mutate: updateActiveSession } = useCurrentParkingSession();

    useEffect(() => {
        if(parkSession?.reservationStartedAt !== null) {
            const timerId = setInterval(async () => {
                const now = moment();
                const minutesRemaining = parkReservationTime(reservationMinutes) - moment.duration(now.diff(parkSession!.reservationStartedAt)).minutes();
                const minutesToShow = Math.max(0, minutesRemaining);

                setRemainingText(Strings.minutesRemaining(minutesToShow))

                if (minutesToShow === 0) {
                    updateActiveSession();
                    await setShowTimeOutReservationModal(false);
                    dispatch(showHideReservationTimeOutModal(false));
                    navigation.reset({
                        routes: [{
                            name: Routes.ActiveSession
                        }]
                    })
                } else if (minutesToShow <= timeoutReservationInMinutes && !showTimeOutReservationModal) {
                    await setShowTimeOutReservationModal(true);
                    dispatch(showHideReservationTimeOutModal(true));
                }
            }, 1000);
            return () => clearInterval(timerId);
        }
    }, [parkSession, reservationMinutes, showTimeOutReservationModal]);

    const onLayout = (e:LayoutChangeEvent) => {
        const {height, width} = e.nativeEvent.layout;
        let size = (height >= width ? height : width);
        size = size / 2;
        setHolderSize({height: size, width: size, borderRadius: size/2})
    };

    return (
        <View style={{backgroundColor: Colours.ORANGE, flexDirection: 'row', paddingVertical:12, paddingHorizontal:9, marginBottom: 9, justifyContent: 'space-between', alignItems: 'center'}}>
            <View style={{flexDirection: 'row',justifyContent: 'space-between', alignItems: 'center',}}>
                <View style={{backgroundColor: Colours.WHITE, ...holderSize}}>
                    <Icon iconStyle={{position:"absolute", left:-1, top:-1}} name={'reservedfilled'} onLayout={onLayout} />
                </View>
                <Text small style={{paddingLeft:18}}>{Strings.reserved}</Text>
            </View>
            <Text small>{remainingText}</Text>
        </View>
    );
}
