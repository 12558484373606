import { IRootReducer } from "react/redux/reducers/main";
import { connect } from "react-redux";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AppState, AppStateStatus, StyleSheet, View } from "react-native";
import { getEmployeeSubscription, getSessionInSubscriptionBay } from "react/redux/actions/subscriptions";
import * as Push from "../../../navigation/pushNotifications/constants";
import { addNotificationListener } from "react/navigation/pushNotifications/notificationListener";
import { getOrganisation } from "react/redux/actions/organisations";
import { getPark } from "react/redux/actions/parks";
import { showAlert, showConfirmation } from "react/alerts";
import { SubscriptionHeader } from "./SubscriptionHeader";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Select from "react/parkable-components/select/Select";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../../constants/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import { Term } from "react/model/Term";
import SubscriptionPriceTableRow from "./SubscriptionPriceTableRow";
import SubscriptionNextPaymentTableRow from "./SubscriptionPaymentTableRow";
import SubscriptionPastDueComponent from "./SubscriptionPastDueComponent";
import SubscriptionEndedTableRow from "./SubscriptionEndedTableRow";
import SubscriptionEndingTableRow from "./SubscriptionEndingTableRow";
import AccessGateComponent, { ComponentType } from "../../accessControl/AccessGateComponent";
import ShareBayComponent from "./ShareBayComponent";
import Constants from "../../../constants/constants";
import { SubscriptionInviteComponent } from "./SubscriptionInviteComponent";
import ParkInstructions from "../../common/ParkInstructions";
import SelectedVoucherCard from "../../common/voucher/SelectedVoucherCard";
import { ActivityType } from "react/model/Voucher";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import { EmployeeSubscription } from "react/model/EmployeeSubscription";
import { getNextPaymentDate } from "./getNextPaymentDate";
import { retrieveLargestDiscountVoucher } from "react/redux/actions/vouchers";
import moment from "moment";
import Dashes from "../../common/Dashes";
import SubscriptionCreateVehiclePrompt from "../../subscriptions/SubscriptionCreateVehiclePrompt";
import { Token } from "react/api/rest";
import { useRewardOptionsForOrganisation } from "react/api/rewards/rewardOptions.api";
import { useBay } from "react/api/bay/bay.api";
import { SharingReminderDay } from "react/model/Types";
import { setSharingReminderForSubscription, useSharingReminderForSubscription } from "react/api/sharingReminder/sharingReminder.api";
import { DayOptions, TimeOptions } from "react/constants/Util";
import { useNavigation } from "react/navigation/constants";
import { useUserVehicles } from "react/api/vehicle/vehicle.api";
import getParkAvailability from "../../../constants/getParkAvailability";
import {usePark} from "react/api/park";
import UserDetailsCard from "react/components/common/UserDetailsCard";
import { useBayGroup } from "react/api/bayGroup/bayGroup.api";
import { BayGroupType } from "../../../model/BayGroupType";
import { UnshareBayProvider } from "./context";

type AllProps = SubscriptionViewProps & ReturnType<typeof getReduxProps> & typeof actions;

function SubscriptionView(props: AllProps) {
    const navigation = useNavigation();

    const {
        subscription,
        subscriptionId,
        organisations,
        api,
        token,
        style,
        getEmployeeSubscription,
        getSessionInSubscriptionBay,
        getPark,
        getOrganisation,
        voucher,
        retrieveLargestDiscountVoucher,
        parks,
        subscriptionSession,
        parkId,
        subSession,
    } = props;

    const reminderDialog = useRef<DialogRef>(null);
    const [voucherFetched, setVoucherFetched] = useState(false);
    const {data: sharingReminder, mutate} = useSharingReminderForSubscription(subscriptionId);
    const [sharingReminderDay, setSharingReminderDay] = useState<SharingReminderDay|null>(sharingReminder?.sharingReminderDay || null);
    const [sharingReminderTime, setSharingReminderTime] = useState<number | null>(sharingReminder?.sharingReminderTime || null);

    const {vehicles} = useUserVehicles();

    const organisation = useMemo(() => {
        if(!!subscription && !!subscription.organisation && !!organisations) {
            return organisations[subscription.organisation]
        }
    }, [subscription, organisations]);

    const { bay } = useBay(subscription?.park, subscription?.bay);
    const { bayGroup } = useBayGroup(subscription?.organisation, bay?.group);
    const isBaySharingEnabled = bayGroup?.bonusBaysEnabled === null ? true : bayGroup?.bonusBaysEnabled;

    const {options} = useRewardOptionsForOrganisation(subscription?.organisation, subscription?.park, bay?.group);
    const isRewardsEnabled = options?.externalRewardsEnabled as boolean;

    const park = useMemo(() => {
        if(!!parks && !!parkId) {
            return parks[parkId]
        }
    }, [parks, parkId]);

    const isEndedOrUnpaid = useMemo(() => {
        return !!subscription && (subscription.status === Constants.Subscription_Status_Ended || subscription.status === Constants.Subscription_Status_Deleted || subscription.status === Constants.Subscription_Status_Unpaid);
    }, [subscription]);

    const retrieveVoucherForNextPayment = (subscription: EmployeeSubscription) => {
        if(!voucherFetched && !isEndedOrUnpaid && !!parkId) {
            setVoucherFetched(true);
            retrieveLargestDiscountVoucher(parkId, ActivityType.LongTerm, subscription.id, getNextPaymentDate(subscription.term, subscription.anchorDay, subscription.weekAnchorDay));
        }
    };

    useEffect(() => {
        const eventListener = AppState.addEventListener('change', (appState: AppStateStatus) => {
            if (appState === 'active' && !!subscription) {
                getSessionInSubscriptionBay(subscription.id);
            }
        });

        return () => {
            eventListener.remove();
        }
    }, [subscription]);

    useEffect(() => {
        const updateEveryMinute = setInterval(() => {
            if (!!subscription) {
                getSessionInSubscriptionBay(subscription.id);
            }
        }, 60000);

        return () => {
            clearInterval(updateEveryMinute);
        }
    }, [subscription]);

    useEffect(() => {
        if (!!subscription &&
            !!subscription.organisation &&
            !isEndedOrUnpaid &&
            !subscriptionSession) {
            getSessionInSubscriptionBay(subscription.id);
        }
    }, [subscription, subscriptionSession]);

    useEffect(() => {
        const notifListener = addNotificationListener(onNotificationReceived, 'ActiveEmployeeSubscriptionView');
        return () => { notifListener.remove()};
    }, []);

    const isEnded = useMemo(() => {
        return !!subscription && (subscription.status === Constants.Subscription_Status_Ended || subscription.status === Constants.Subscription_Status_Deleted);
    }, [subscription]);

    const isPending = useMemo(() => {
        return !!subscription && Constants.Subscription_Status_Pending === subscription.status;
    }, [subscription]);

    const isPaymentActive = useMemo(() => {
        return !!subscription
            && [Constants.Subscription_Status_Active, Constants.Subscription_Status_Pending].includes(subscription.status)
            && (subscription.pricePerTerm??0) > 0;
    }, [subscription]);

    const isEnding = useMemo(() => {
        return !!subscription && [Constants.Subscription_Status_LeaserEnding, Constants.Subscription_Status_HostEnding].includes(subscription.status);
    }, [subscription]);

    useEffect(() => {
        if(!!subscription && !!parkId && !park) {
            getPark(parkId);
        }
        if(!!subscription && !!subscription.organisation && !organisation) {
            getOrganisation(subscription.organisation);
        }

        if(!!subscription){
            retrieveVoucherForNextPayment(subscription);
        }
    }, [subscription, parkId]);

    const onNotificationReceived = (code: string, notif: any) => {
        if (code === Push.EmployeeSubscriptionCreated || code === Push.EmployeeSubscriptionUpdated || code === Push.EmployeeSubscriptionCancelled) {
            if(code === Push.EmployeeSubscriptionUpdated){
                showConfirmation(notif.body);
            }

            const employeeSubscriptionId = notif.employeeSubscriptionId;
            getEmployeeSubscription(employeeSubscriptionId);

            getSessionInSubscriptionBay(employeeSubscriptionId);
            return true;
        } else {
            return false;
        }
    };

    const howRewardsWorksPress = () => {
        navigation.push(Routes.HowItWorks_Rewards, {organisationId: subscription.organisation, territoryId: organisation?.territory})
    };

    const viewCarpark = useCallback(() => {
        if(!!parkId){
            navigation.push(Routes.ParkDetailView, {parkId})
        }
    }, [parkId, navigation]);

    const isPfBSubscription = useMemo(() => {
        return !!subscription && !!subscription.organisation;
    }, [subscription]);

    const isPublicParker = useMemo(() => {
        return (subscription || {}).groupType === BayGroupType.PublicSubscription
    }, [subscription]);

    const showShareBay = useMemo(() => {
        return !!subscription && !!subscription.organisation && Constants.Active_Subscription_Status_Array.includes(subscription.status)
    }, [subscription]);

    const showPastDueComponent = useMemo(() => {
        return !!subscription && (subscription.status === 'PastDue' || subscription.status === 'Processing')
    }, [subscription]);


    const onRewardsPress = useCallback(() => {
        navigation.push(Routes.MyRewardsView, {organisationId: subscription.organisation, territoryId: park?.territory?.id});
    }, []);

    const onProblemPress = useCallback(() => {
        if(!!park && !!bay && !!subscription){
            navigation.push(Routes.Problem, {
                parkId: park.id,
                bayId: bay.id,
                subscription
            });
        }
    }, [park, bay, subscription, navigation]);

    const onViewSharingCreditPress = useCallback(() => {
        if(!!subscription && !!park) {
            navigation.push(Routes.SubscriptionSharingCreditView,{
                employeeSubscription: subscription,
                parkId: park.id
            })
        }
    },[subscription,park])

    const viewInvoice = useCallback(() => {
        if(!!park && !!subscription) {
            navigation.push(Routes.SubscriptionInvoicesView, {
                park,
                employeeSubscriptionId: subscription?.id
            })
        }
    }, [park, subscription, navigation]);


    const discount = useMemo(() => {
        if(!!voucher && !!subscription){
            const pricePerTerm = subscription!.pricePerTerm??0;
            return (pricePerTerm * ((voucher.percentDiscount??0) / 100.0)) * -1;
        }
        return 0;
    }, [subscription, voucher]);

    const disableCancelButton = useMemo(() => {
        
        return !!subscription &&
            !(subscription.status === Constants.Subscription_Status_Active || (subscription.status === Constants.Subscription_Status_Pending && !subscription?.nextPaymentAttempt));
    }, [subscription]);

    const cancelSubscription = () => {
        if (park && bay) {
            navigation.push(Routes.SubscriptionCancelView, { park, subscription, bay, organisation, isPublicParker })
        }
    };

    const total = (subscription?.pricePerTerm??0) + discount;

    const enableSharingCreditView = isBaySharingEnabled
        && subscription.status === Constants.Subscription_Status_Active
        && !!organisation?.bonusSessionMargin
        && subscription.pricePerTerm > 0;

    const dayOptions: Item[] = Object.values(DayOptions).map(v => ({label: v, value: v}))

    const onChangingReminderNotifications = useCallback(async () => {
        if(!!sharingReminderDay && !!sharingReminderTime) {
            try {
                const result = await setSharingReminderForSubscription(subscriptionId,{sharingReminderDay,sharingReminderTime})
                if(result.data.employeeSubscription === subscriptionId){
                    showConfirmation(Strings.sharing_reminder_is_set, Strings.success)
                    await mutate()
                }
            } catch (e) {
                showAlert((e as any)?.message??Strings.internal_error_if_persists, Strings.error);
            }
        } else if(!sharingReminderTime && sharingReminderDay !== "Off") {
            showAlert(Strings.time_is_not_set)
        }
    }, [sharingReminderDay,sharingReminderTime,subscriptionId])

    const currentSharingReminderText = sharingReminder?.sharingReminderDay === "Off" ?
        sharingReminder?.sharingReminderDay :
        sharingReminder?.sharingReminderDay + ", " + TimeOptions.filter(o => o.value === sharingReminder?.sharingReminderTime).map(o => o.label)

    const pickerStyle = {
        inputIOS: styles.pickerText,
        inputAndroid: styles.pickerText,
        inputWeb: styles.pickerText,
        viewContainer: styles.pickerViewContainer,
    }

    // 24/7 gate access with active subscription in custom hours car parks
    const {park: parkAvailability} = usePark(parkId);
    const carParkHours = parkAvailability ? getParkAvailability(parkAvailability) : undefined;
    const isCarParkOpenNow = carParkHours?.isOpenNow
    const hasAfterHoursAccessToCarParkWithActiveSubscription = park?.carParkAccessWithActiveSubscription ? park?.carParkAccessWithActiveSubscription : false;
    const hasPermissionToOpenGate = park && !isEndedOrUnpaid && (isCarParkOpenNow || hasAfterHoursAccessToCarParkWithActiveSubscription);
    return (
    <View style={styles.scrollView}>
        <View style={[styles.mainView, style]}>
            <SubscriptionHeader
                isEnded={isEndedOrUnpaid}
                isPfB={isPfBSubscription}
                groupType={subscription?.groupType}
                address={park?.address}
                parkDisplayName={park?.displayName??undefined}
                organisationName={organisation?.name}
                hideOrganisationName={park?.hideOrganisationName}
                baySignage={bay?.signage}/>

            {isPending && !subscription?.nextPaymentAttempt && <View>
                <TableRow hideBorder={true} contentLeft={<View style={styles.iconPending}><Text white={true} bold={true}>{Strings.exclamation_mark}</Text></View>}
                          backgroundColor={"#fce086" as Colours}
                          label={Strings.subscription_start_date}
                          buttonProps={{ textProps: {small:true}, style:{width: "auto", marginRight:9, backgroundColor:Colours.ORANGE} }}
                        buttonText={!disableCancelButton ? Strings.cancel : undefined}
                          onButtonPress={cancelSubscription}>
                    {moment(subscription!.start).format("Do MMMM YYYY")}</TableRow>
            </View>}

            {isEndedOrUnpaid && <TableRow
                backgroundColor={Colours.GREY_10}
                textProps={{bold: true}}
                contentLeft={<View style={{...styles.iconPending, backgroundColor: Colours.GREY_50}}><Text white={true} bold={true}>{Strings.exclamation_mark}</Text></View>}
                hideBorder>
                {Strings.this_subscription_has_ended}</TableRow>}

            {!isPending && !isEndedOrUnpaid && <Dashes width={"100%"} height={1} colour={Colours.GREY_BORDER} backgroundColour={Colours.WHITE}/>}

            {vehicles !== undefined
            && vehicles.length === 0
            && <SubscriptionCreateVehiclePrompt/>}

            {showShareBay && isBaySharingEnabled && !!organisation && !isEndedOrUnpaid && (
                <UnshareBayProvider>
                    <ShareBayComponent 
                        viewCarpark={viewCarpark}
                        currencyCode={park?.territory.currencyCode}
                        subscription={subscription as EmployeeSubscription}
                        organisation={organisation}
                        isRewardsEnabled={isRewardsEnabled}
                        howRewardsWorksPress={howRewardsWorksPress}
                        hasBonusSessionMargin={!!organisation.bonusSessionMargin}
                />
                </UnshareBayProvider>
            )}
            {!!subscription && subscription.status === Constants.Subscription_Status_Invited &&
                <SubscriptionInviteComponent subscription={subscription} organisation={organisation??null} />}

            <Text style={styles.detailsHeader} h4 bold>{Strings.subscription_details}</Text>

            {isBaySharingEnabled && subscription.status === Constants.Subscription_Status_Active && <TableRow
                iconLeft={"handwatch"}
                label={Strings.sharing_reminder}
                chevronText={Strings.change}
                chevron={true}
                onPress={() => reminderDialog.current?.show()}
            >
                {!!sharingReminder ? currentSharingReminderText : Strings.not_set}
            </TableRow>}

            {showPastDueComponent && <SubscriptionPastDueComponent api={api} token={token} subscriptionId={subscriptionId} isPfb={isPfBSubscription}/>}

            {hasPermissionToOpenGate && <AccessGateComponent
                showEntranceGates={true}
                    showExitGates={true}
                    displayModalWhenNotInRange={true}
                    parkId={park.id}
                    subSession={subSession}
                    subscriptionId={subscription.id}
                    mutateEmployeeSubscription={getEmployeeSubscription}
                componentType={ComponentType.TableRow}
            />}

            <UserDetailsCard
                hideCreditCard
                parkId={subscription?.park}
                subscription={subscription}
                selectSubscriptionVehicles={!!park?.maxVehiclesPerSubscription}
            />

            <SubscriptionPriceTableRow price={subscription?.pricePerTerm} currencyCode={park?.territory.currencyCode} isWeeklyTerm={subscription?.term === Term.Week}/>

            {!!voucher && !!park && <View>
                <SelectedVoucherCard territory={park.territory} voucher={voucher} park={park} activity={ActivityType.LongTerm} discount={discount}/>

                <TableRow iconLeft={"dollarfilled"} iconLeftProps={{color: Colours.GREEN,}} label={Strings.total_to_be_charged} >
                    {localizeCurrency(total, park.territory.currencyCode)}</TableRow>
            </View>}

            {isPaymentActive &&
            <SubscriptionNextPaymentTableRow price={subscription.pricePerTerm} term={subscription.term} status={subscription.status} startDate={subscription.start}
                                             anchorDay={subscription.anchorDay} weekAnchorDay={subscription.weekAnchorDay} />}

            {isEnding && <SubscriptionEndingTableRow term={subscription?.term??null} anchorDay={subscription?.anchorDay??null} weekAnchorDay={subscription?.weekAnchorDay??null} termStartedAt={subscription?.termStartedAt??null}/>}
            {isEnded && <SubscriptionEndedTableRow term={subscription?.term} endedDate={subscription?.endedAt}/>}
            {!isEndedOrUnpaid && <View>
                <ParkInstructions
                    title={Strings.parking_instructions}
                    text={(!!park?.description && !!park?.instructions) ? park?.description + "\n" + park?.instructions :
                        (!park?.description ? park?.description : park?.instructions)}
                    style={{ marginHorizontal: 0, marginVertical: 0}}
                    showHeading={true}
                    descriptionTextProps={{h4:true, bold: true, style:styles.detailsHeader}}
                    showCollapsed={true}
                />
            </View>}
            <Button iconLeft={"support"} iconLeftProps={{color: Colours.BLACK,}} plain style={{marginTop: 9}} center border textProps={{h4:true}} onPress={onProblemPress}>{Strings.i_have_a_problem}</Button>
            {enableSharingCreditView && <Button style={{marginTop: 9}} center border textProps={{h4:true}} onPress={onViewSharingCreditPress}>{Strings.sharing_credit.view_sharing_credit}</Button>}
            {isRewardsEnabled && <Button iconLeft={"voucher"} style={{marginTop: 9}} center onPress={onRewardsPress} textProps={{h4:true}}>{Strings.rewards.view_rewards}</Button>}
            <Text style={styles.optionsHeader} h4 bold>{Strings.options}</Text>
            <TableRow onPress={viewInvoice} chevron>{Strings.view_invoice}</TableRow>
            <TableRow onPress={viewCarpark} chevron>{Strings.view_car_park}</TableRow>
                {!disableCancelButton &&
                <Button disabled={disableCancelButton} style={styles.cancelButtonStyle} plain small center textProps={{red: true, bold: false}} onPress={cancelSubscription}>
                    {Strings.cancel_subscription}
                </Button>}
        </View>

        <Dialog
            ref={reminderDialog}
            label={Strings.sharing_reminder}
            labelProps={{style: {color: Colours.BLACK, textAlign: 'left'}}}
            title={Strings.sharing_reminder_description}
            titleProps={{style: {textAlign: 'left'}, h2: undefined}}
            positiveText={Strings.set}
            negativeText={Strings.cancel}
            onPositivePress={onChangingReminderNotifications}
            onNegativePress={() => reminderDialog.current?.hide()}>
            <Select label={Strings.frequency.toUpperCase()}
                    items={dayOptions}
                    placeholder={Strings.choose_frequency}
                    onValueChange={value => {setSharingReminderDay(value)}}
                    pickerStyle={pickerStyle}
                    style={{height: 45}}
            />
            <Select label={Strings.time.toUpperCase()}
                    disabled={sharingReminderDay === "Off"}
                    items={TimeOptions}
                    placeholder={Strings.choose_time}
                    onValueChange={value => {setSharingReminderTime(value)}}
                    pickerStyle={pickerStyle}
                    style={{height: 45, backgroundColor: sharingReminderDay === "Off" ? Colours.GREY_50 : Colours.WHITE}}
            />
        </Dialog>
    </View>);
}


type SubscriptionViewProps = {
    subscriptionId: number,
    style: any
}

const getReduxProps = (state: IRootReducer, props: SubscriptionViewProps) => {

    const subscription: EmployeeSubscription | undefined = state.subscriptions.employeeSubscriptions?.[props.subscriptionId];

    const voucher = state.user?.longTermVoucher?.[props.subscriptionId]??null;
    const parkId = subscription?.park || null;

    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken
    } as Token;

    return {
        api: state.data.api,
        token: tokenObject,
        userId: state.data.userId,
        userCards: state.user.cards,
        currentCardId: state.user.currentCardId,
        subscriptionSession: state.subscriptions.employeeSubscriptionSessions[props.subscriptionId],
        organisations: state.organisations.organisations,
        parks: state.parks.parks,
        bays: state.parks.bays,
        dateformat: state.settings.dateformat,
        subscription,
        subSession: state.subscriptions.employeeSubscriptionSessions[subscription?.id],
        voucher,
        parkId
    };
};

const actions = {
    getEmployeeSubscription,
    getPark,
    getOrganisation,
    getSessionInSubscriptionBay,
    retrieveLargestDiscountVoucher,
};

export default connect(getReduxProps, actions)(SubscriptionView as React.FunctionComponent<SubscriptionViewProps>);

const styles = StyleSheet.create({
    scrollView: {
        backgroundColor: Colours.GREY_10,
        marginBottom: 40,
    },
    mainView : {
        flex:1,
        marginHorizontal: 9,
        marginTop:9,
        marginBottom:18,
        paddingHorizontal: 18,
        paddingBottom: 18,
        borderRadius: 8,
        borderColor: Colours.GREY_BORDER,
        borderWidth: 1,
        backgroundColor: Colours.WHITE,
    },
    detailsHeader: {
        marginTop: 18
    },
    optionsHeader: {
        marginTop: 36
    },
    cancelButtonStyle: {
        marginTop: 36,
    },
    iconPending: {
        width: 36,
        height: 36,
        alignItems: "center",
        borderRadius: 50,
        justifyContent: "center",
        backgroundColor: Colours.ORANGE,
        marginLeft: 9
    },
    sharingDropdowns: {
        alignContent: "flex-start",
        marginBottom: 18,
    },
    pickerText: {
        backgroundColor: Colours.TRANSPARENT,
        borderColor: Colours.TRANSPARENT,
    },
    pickerViewContainer: {
        flex: 1,
        justifyContent: "center",
    },
});

interface Item {
    label: string;
    value: any;
    color?: string;
}
