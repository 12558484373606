import { IRootReducer } from "../../redux/reducers/main";
import { connect } from "react-redux";
import { LayoutChangeEvent, Modal, Platform, StyleSheet, View } from "react-native";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { changeSessionBay, getBay, getIsPublicParker, getPark, getUserBays } from "../../redux/actions/parks";
import { retrieveLargestDiscountVoucher } from "../../redux/actions/vouchers";
import { getCurrentParkingSession, getSession, stopParking } from "../../redux/actions/parking";
import Strings from "../../constants/localization/localization";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Text from "react/parkable-components/text/Text";
import { Routes } from "react/navigation/root/root.paths";
import BayNumberHeader from "./BayNumberHeader";
import ComponentCarousel from "../common/ComponentCarousel";
import { CasualDisplayType, ParkingActivity, ParkingType } from "../../model/Types";
import localizeCurrency from "../../constants/localization/localizeCurrency";
import UserDetailsCard from "../common/UserDetailsCard";
import { Bay, Feature } from "../../model/Bay";
import SessionElapsed from "./SessionElapsed";
import TimeElapsedCarouselItem from "./TimeElapsedCarouselItem";
import CurrentChargeCarouselItem from "./CurrentChargeCarouselItem";
import AccessGateComponent, { ComponentType } from "../accessControl/AccessGateComponent";
import SupportFooterView from "../common/SupportFooterView";
import Dialog from "react/components/dialog/Dialog";
import EVCarouselItem from "./ev/EVCarouselItem";
import { getEvSessions } from "../../redux/actions/ev";
import { AvailabilityHelper, futureParkingButtonStatus } from "../parkDetails/startParkingButtonStatus";
import { getSubscriptionInPark } from "../../constants/getActiveSubscriptions";
import { EmployeeSubscription } from "../../model/EmployeeSubscription";
import SelectedVoucherCard from "../common/voucher/SelectedVoucherCard";
import { ActivityType, Voucher } from "../../model/Voucher";
import useSafeArea from "../../hooks/useSafeArea";
import * as Push from "../../navigation/pushNotifications/constants";
import { addNotificationListener } from "../../navigation/pushNotifications/notificationListener";
import ParkInstructions from "../common/ParkInstructions";
import SelectedBayCard from "../common/SelectedBayCard";
import { logEvent } from "react/analytics";
import _ from "lodash";
import { RequestState, useParkingRequestOptions } from "../../api/parkingRequestOptions/parkingRequestOptions.api";
import { useCampusesInOrganisation } from "../../api/campus/campus.api";
import { ParkAvailabilityViewParams } from "../parkDetails/ParkAvailabilityView";
import { getAvailabilityInfo } from "../parkDetails/confirmStartParking/availabilityInfo";
import { useVacateBayDeadline } from "../../api/bay/bay.api";
import { onParkTomorrowPress } from "../../constants/onParkTomorrowPress";
import { useParkingRequestsForUser } from "../../api/parking-request/parking-request.api";
import { useCurrentUser, useUserRoles } from "../../api/user/user.api";
import TandemChatCarouselItem from "./tandemParking/TandemChatCarouselItem";
import TandemLeaveTimeCarouselItem from "./tandemParking/TandemLeaveTimeCarouselItem";
import { useParkSessionV3 } from "../../api/parkSession/parkSession.api";
import { formattedTime } from "../../constants/tandemParking.constants";
import { usePark } from "../../api/park/park.api";
import { useBayGroup } from "../../api/bayGroup/bayGroup.api";
import EstimatedLeaveTimeModal, { EstimatedLeaveTimeModalRef } from "../../components/tandemParking/EstimatedLeaveTimeModal";
import { createRoute, NavigationProps, useNavigation } from "../../navigation/constants";
import { IImageInfo } from "react-native-image-zoom-viewer/built/image-viewer.type";
import ImageViewer from "react-native-image-zoom-viewer";
import ParkableBaseView from "../common/ParkableBaseView";
import ParkImagesCarousel from "../common/ParkImagesCarousel";
import { CarParkNameRow } from "../widgets/table-rows/car-park-row";
import DriveTimeTableRow from "../parkDetails/DriveTimeTableRow";
import { ParkSessionDTOLocal } from "../../model/ParkSessionDTO";
import { useParkingPrice } from "react/api/parkingPrice/parkingPrice.api";
import { getCurrentHourlyPricePeriod } from "react/services/parkingPrice.service";
import { SharingPoolRow } from "react/components/parkDetails/sharing-pool/SharingPoolRow";
import { useSharingPoolBaysForUser } from "react/api/sharingPool/sharingPool.api";
import { userIsOrgMember, userIsParkableAdmin } from "react/constants/getUserMember";
import Icons from "react/parkable-components/icon/Icons";
import LocationCard from "../common/maps/LocationCard_root";

export class ActiveSessionViewParams {
    showEVChargerWarning?: boolean;
}

type AllViewProps = NavigationProps<Routes.ActiveSession> & ReturnType<typeof getReduxProps> & typeof actions;

enum CarouselSize {
    SMALL = 189,
    LARGE = 261
}

function ActiveSessionView(props: AllViewProps) {
    const { top, bottom } = useSafeArea();
    const [showEVChargerDialog, setShowEVChargerDialog] = useState((props.route.params && !!props.route.params.showEVChargerWarning) ?? false);
    const [isOpen, setIsOpen] = useState(false);

    const [loadingParkDetails, setLoadingParkDetails] = useState(false);
    const [loadingStopParking, setLoadingStopParking] = useState(false);
    const [markerText, setMarkerText] = useState("");
    const [carouselSize, setCarouselSize] = useState(CarouselSize.SMALL);
    const stopParkingDialogRef = useRef<DialogRef|null>(null);
    const [availabilityHelper, setAvailabilityHelper] = useState<AvailabilityHelper|undefined>(undefined);
    const [hideReportBtnIcon, setHideReportBtnIcon] = useState<boolean>(false);
    const [subscriptionInPark, setSubscriptionInPark] = useState<EmployeeSubscription|undefined>();
    const voucherDialogRef = useRef<DialogRef|null>(null);
    const [userBays, setUserBays] = useState<Bay[]>([]);

    const leaveTimeDialogRef = useRef<EstimatedLeaveTimeModalRef>(null);

    const {
        currentSession,
        ocpiSessions,
        park,
        bay,
        isPublicParker,
        voucher,
        getPark,
        getBay,
        getIsPublicParker,
        stopParking,
        getCurrentParkingSession,
        getSession,
        currentVehicle,
        getUserBays,
    } = props;

    const navigation = useNavigation();

    const {campuses} = useCampusesInOrganisation(park?.ownerOrganisation);
    const campus = park ? campuses?.find(c => c.parks.includes(park.id)) : undefined;
    const {vacateBayDeadline} = useVacateBayDeadline(bay?.park, bay?.id);

    const {user} = useCurrentUser();
    const { userRoles } = useUserRoles();
    const {parkingRequests} = useParkingRequestsForUser(user?.id);
    const { parkSession, mutate: mutateParkSession } = useParkSessionV3(currentSession?.id);

    const pricePerKWH = currentSession?.pricePerKWH;
    const { pricePeriods } = useParkingPrice(currentSession?.parkingPrice);
    const hourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;

    const { park: mapPark } = usePark(currentSession?.park);
    const { bayGroup } = useBayGroup(park?.organisation, bay?.group);

    const { options } = useParkingRequestOptions(park?.organisation, currentSession?.park);
    const { state } = options ?? {};
    const { bays: sharingPoolBays } = useSharingPoolBaysForUser(
      park?.organisation,
      park?.id,
      {
          feature: currentVehicle?.feature,
          parkingType: ParkingActivity.Casual,
      }
    );
    const isFutureBooking = state === RequestState.FutureBooking;
    const allowAdvanceBooking = state && state !== RequestState.Disabled;

    const futureBookingDisabled = (availabilityHelper || {disableFutureParkButton: true}).disableFutureParkButton
        || (!!bay?.evse && park?.allowEVReservations !== true)
        || !allowAdvanceBooking;
    const bayIsFromSharingPool = (currentSession?.isSharingPool ?? parkSession?.isSharingPool) ?? false;

    const isUserPrivateOrganisation = park?.organisation &&
      (userIsOrgMember(userRoles, park.organisation) ||
        userIsParkableAdmin(userRoles));

    useEffect( () => {
        if (!!currentSession) {
            getSession(currentSession.id);
        }
        const notifListener = addNotificationListener(onNotificationReceived, 'ActiveSessionView');
        return () => { notifListener.remove()};
    }, ["once"]);

    const onNotificationReceived = (code: string, notif: any) => {
        if (code === Push.ParkingEnded || code === Push.SessionEndedInUnavailablePark) {
            setLoadingParkDetails(true);
            // @ts-ignore
            getSession(notif.sessionId, () => setLoadingParkDetails(false));
            return true;
        } else {
            return false;
        }
    };

    const onStopParkingPress = useCallback(() => {
        const params = { userId: `${user?.id??0}`, parkId: `${park?.id??0}` };
        logEvent(undefined, 'activesession_stopparking', params);
        stopParkingDialogRef.current?.show();
    }, []);

    const onConfirmStopParking = useCallback(() => {
        if (!currentSession) {
            return
        }

        if(!!currentSession.endedAt){
            //Ive seen this screen get stuck and cant figure out why it is doing so.  as a temp fix doing this - MD
            navigateToSummaryView();
            return;
        }

        setLoadingStopParking(true);
        stopParking(currentSession.id, props.park?.territory,
            (result) => {
                console.log("stop parking updated session", result);
                const params = { userId: `${user?.id??0}`, parkId: `${park?.id??0}` };
                logEvent(undefined, 'activesession_endsession', params);
            },
            (error) => {
                console.log("stop parking error", error);
                setLoadingStopParking(false);
            })
    }, [currentSession, props.park?.territory]);

    const navigateToSummaryView = () => {
        navigation.reset({
            routes: [{
                name: Routes.SessionSummary
            }]
        })
    };

    const onProblemPress = useCallback(() => {
        if(currentSession) {
            navigation.push(Routes.Problem, { sessionId: currentSession.id, parkId: currentSession.park, bayId: currentSession.bay ?? undefined })
        }
    }, [park, bay, currentSession]);

    useEffect(() => {
        if(!!props.currentSession) {

            let carouselSize = CarouselSize.LARGE;
            if (!!props.currentSession.bay) {
                carouselSize = CarouselSize.SMALL;
            }
            setCarouselSize(carouselSize);
        }else{
            getCurrentParkingSession();
        }

    }, [currentSession]);

    useEffect(() => {

        if(!!props.currentSession && !!props.bay && !!props.bay.evse/* && (!props.ocpiSession || props.ocpiSession.status !== SessionStatus.ACTIVE)*/) {

            props.getEvSessions(props.currentSession.id);

            const interval = setInterval(() => {
                if(props.currentSession) {
                    props.getEvSessions(props.currentSession.id);
                }
            }, 2000);
            return () => clearInterval(interval);
        }

    }, [props.currentSession, props.bay]);

    useEffect(() => {
        const subscriptionInPark = !!currentSession?.park ?
                    getSubscriptionInPark(Object.values(props.employeeSubscriptions),
                                            currentSession.park)
                    : undefined;
        setSubscriptionInPark(subscriptionInPark);
    }, [props.employeeSubscriptions, currentSession]);

    function onShowConnectNozzle(bay: Bay, session: ParkSessionDTOLocal) {
        if(park){
            navigation.navigate(Routes.ConnectEVNozzle, {bayId: bay.id, parkId: park.id, sessionId: session.id});
        }
    }

    const carouselChildren = () => {

        let components = [] as Array<JSX.Element>;

        if(!!park && !!currentSession && !!parkSession) {

            const accessGateComponent = <AccessGateComponent
                parkId={park.id}
                key={"accessGate"}
                showEntranceGates={true}
                showExitGates={true}
                displayModalWhenNotInRange={true}
                componentType={ComponentType.Carousel}
                style={{ borderRadius: 9, height: carouselSize, width: carouselSize, marginRight: 9 }} />;
            components.push(accessGateComponent);

            if (bay && bay.tandemPod) {
                const tandemChatCarouselItem = (
                    <TandemChatCarouselItem
                        navigation={navigation}
                        bay={bay}
                        session={parkSession}
                        style={{ width: carouselSize, height: carouselSize, marginRight: 9 }}
                    />
                );
                const tandemLeaveTimeCarouselItem = (
                    <TandemLeaveTimeCarouselItem
                        session={parkSession}
                        handleClick={() => leaveTimeDialogRef.current?.show()}
                        style={{ width: carouselSize, height: carouselSize, marginRight: 9 }}
                    />
                );
                components.push(tandemChatCarouselItem, tandemLeaveTimeCarouselItem);
            }

            if(!!bay && !!bay.evse){

                const chargeTimeElapsedCarouselItem = <EVCarouselItem sessions={ocpiSessions} key={"chargeTime"}
                                                                                     territory={park?.territory}
                                                                                     showConnectNozzle={() => onShowConnectNozzle(bay, currentSession)}
                                                                                     style={{borderRadius: 9, height: carouselSize, width: carouselSize, marginRight: 9}}/>;
                components.push(chargeTimeElapsedCarouselItem);
            }

            const timeElapsed = <TimeElapsedCarouselItem key={"TimeElapsedCarouselItem"} session={currentSession} park={park} style={{borderRadius: 9, height: carouselSize, width: carouselSize, marginRight: 9}}/>;
            components.push(timeElapsed);

            const currentCost = <CurrentChargeCarouselItem key={"CurrentChargeCarouselItem"}
                                                           currencyCode={park?.territory.currencyCode || ""}
                                                           ocpiSessions={ocpiSessions}
                                                           session={currentSession}
                                                           park={park}
                                                           style={{borderRadius: 9, height: carouselSize, width: carouselSize, marginRight: 9}}/>;
            components.push(currentCost);

            const images = !!park && <ParkImagesCarousel key={"ParkImagesCarousel"} park={park} height={carouselSize} width={carouselSize} />
            components = components.concat(images);
        }

        return components;
    };

    useEffect(() => {

        if(!currentSession) {
            return;
        }

        const {
            endedAt,
            park,
            bay,
        } = currentSession;

        if(endedAt){
            //session just ended, show summary
            navigateToSummaryView();
        }

        const parkPromise = !props.park ? new Promise<any>((resolve, reject) => getPark(park, (park) => {
            resolve(park)
        }, (err: Object) => reject(err))) : Promise.resolve();

        const publicParkerPromise = isPublicParker === undefined ? new Promise<void>((resolve, reject) => getIsPublicParker(park, () => resolve(), (err: any) => reject(err))): Promise.resolve();
        const bayPromise = (!!bay && !props.bay) ? new Promise<Bay | undefined>((resolve, reject) => getBay(park, bay, (bay: Bay) => resolve(bay), (err: any) => reject(err))) : Promise.resolve();

        if(!props.park || isPublicParker === undefined || (!!bay && !props.bay)){
            setLoadingParkDetails(true);
        }
        Promise.all([parkPromise, publicParkerPromise, bayPromise])
            .catch(console.error)
            .then(() => setLoadingParkDetails(false));

    }, [currentSession]);

    useEffect(() => {
        if(!!props.currentSession && props.park) {
            setMarkerText(localizeCurrency(hourlyPricePeriod?.price || 0,
                props.park.territory.currencyCode,
                false,
                false,
                true));
        }
    }, [props.currentSession, props.park]);

    useEffect(() => {
        setLoadingStopParking(false);
        if(!!props.park) {
            props.retrieveLargestDiscountVoucher(props.park.id, ActivityType.Casual);
        }
    }, [props.park, props.retrieveLargestDiscountVoucher, setLoadingStopParking]);


    const myVoucher = useRef<Voucher>(voucher);
    useEffect(() => {
        if(!myVoucher.current && !!voucher ||
                !!myVoucher.current && !!voucher && myVoucher.current.id != voucher.id) {
            voucherDialogRef.current?.show();
        }
    }, [voucher, myVoucher, voucherDialogRef]);

    useEffect(() => {
        (async () => {
            if(!!park) {
                const futureButtonStatus = futureParkingButtonStatus(park, subscriptionInPark, isFutureBooking);
                setAvailabilityHelper(futureButtonStatus);
            }
        })()
    }, [park, subscriptionInPark, isFutureBooking]);

    const onPFBParkTomorrowPress = () => {
        if (park) {
            void onParkTomorrowPress(park?.id, park?.organisation, null, user?.id, parkingRequests??[], navigation);
        }
    };

    const onFutureBookingPress = () => navigation.push(Routes.FutureBookingView, {
        organisationId: park?.organisation ?? undefined,
        parkId: park!!.id,
        campusId: campus?.id,
    });

    const onReportBtnLayout = (event: LayoutChangeEvent) => {
        event.nativeEvent.layout.width < 160 && setHideReportBtnIcon(true);
    };

    const backMap = () => {
        navigation.reset({
            routes: [{
                name: Routes.ParkableMapView
            }]
        })
    };

    useEffect(() => {
        if (!currentSession) {
            return;
        }
        if (currentSession.isSharingPool) {
            setUserBays(sharingPoolBays ?? []);
        } else {
            getUserBays(currentSession.park, ParkingActivity.Casual, undefined, setUserBays);
        }
    }, [currentSession, sharingPoolBays?.length]);

    const onChangeBay = useCallback(async (bay: Bay) => {
        if(!!currentSession && !!bay && !!bay.group && !!park?.organisation) {
            setLoadingParkDetails(true);
            try {
                await props.changeSessionBay(currentSession?.id, bay.id, bay.group, park.organisation);
            } catch (error) {
            } finally {
                setLoadingParkDetails(false);
            }
        }
    }, [currentSession, park]);

    const isMotorBikeDefault = currentVehicle?.feature === Feature.MOTORBIKE;

    const availabilityInfo = useMemo((): ParkAvailabilityViewParams | null => {
        if(!park || !bay) {
            return null;
        }
        return getAvailabilityInfo(park, bay.id, vacateBayDeadline??null)
    }, [park, bay, vacateBayDeadline]);

    const showParkAvailabilityView = () => {
        if (availabilityInfo) {
            navigation.push(Routes.ParkAvailabilityView, availabilityInfo);
        }
    };

    return (<ParkableBaseView backButtonOverride={backMap}
                              loading={loadingStopParking || loadingParkDetails}>

        <View>

            <BayNumberHeader bay={props.bay} bayGroup={bayGroup}/>
            {!bay && <Text h2 numberOfLines={3}>{props.park?.address || ""}</Text>}

            <ComponentCarousel style={{height: carouselSize, marginBottom: 18}}>
                {carouselChildren()}
            </ComponentCarousel>

        <Button red center textProps={{h3:true}}
                onPress={onStopParkingPress}>{!!props.bay?.evse ? Strings.stop_session : Strings.stop_parking}</Button>
        <View style={{flexDirection: 'row', justifyContent: "space-between", marginTop: 9}}>
            <Button iconLeft={hideReportBtnIcon ? undefined : "support"} iconLeftProps={{color: Colours.BLACK}}
                    plain center border textProps={{h5:true}} style={{flex: 1}}
                    onLayout={onReportBtnLayout}
                    onPress={onProblemPress}>{Strings.i_have_a_problem}</Button>
            {(park?.organisation !== null && isUserPrivateOrganisation) && !(bay?.evse) && <View style={{width: 9}}/>}
            {(park?.organisation !== null && isUserPrivateOrganisation && allowAdvanceBooking) && !(bay?.evse) && (
                isFutureBooking ? (
                    <Button
                        center
                        disabled={futureBookingDisabled}
                        plain
                        style={{ flex: 1 }}
                        border
                        textProps={{ h5: true }}
                        onPress={onFutureBookingPress}>
                        {Strings.future_booking.future_booking}
                    </Button>
                ) : (
                    <Button disabled={futureBookingDisabled} border center plain textProps={{ h5: true }}
                            style={{ flex: 1 }} onPress={onPFBParkTomorrowPress}>
                        {availabilityHelper?.futureParkingButtonText ?? Strings.loading}
                    </Button>
                ))}
        </View>

            <Text h2 style={{marginTop: 54}}>{Strings.parking_details}</Text>

            {bayIsFromSharingPool && <SharingPoolRow />}

            {availabilityInfo && <TableRow condensed style={{alignContent: "flex-start"}}
                                           chevron
                                           backgroundColor={availabilityInfo.bonusBayStyle ? Colours.ORANGE : Colours.GREY_10}
                                           iconLeft={"parkingfilled"}
                                           disabled={availabilityInfo.disabled}
                                           onPress={showParkAvailabilityView}
            >
                {availabilityInfo.info}
            </TableRow>}

            {park && bay &&
                <>
                    <CarParkNameRow displayName={park?.displayName}/>
                    <DriveTimeTableRow showDriveTime={false} endLatitude={park?.latitude} endLongitude={park?.longitude} park={park || null} showLocationLabel={true}/>
                    <SelectedBayCard
                        bay={bay}
                        onBaySelected={onChangeBay}
                        baysAvailable={userBays}
                        parkId={park.id}
                        parkingType={ParkingType.CASUAL}
                    />
                </>}

            <SessionElapsed session={props.currentSession}
                            park={props.park ?? null}
                            parkId={props.park?.id}
                            isReservation={!props.currentSession?.startedAt}
                            currencyCode={props.park?.territory.currencyCode}
                            ocpiSessions={props.ocpiSessions}/>

            { bay && bay.tandemPod &&
                <TableRow
                    condensed
                    chevron
                    chevronText={Strings.change}
                    iconLeft="handwatch"
                    label={Strings.tandem_parking.estimated_leave_time}
                    onPress={() => leaveTimeDialogRef.current?.show()}
                >
                    {formattedTime(parkSession?.estimatedLeaveTime)}
                </TableRow>}

            <UserDetailsCard hideCreditCard />

            {bay?.evse && !!pricePerKWH &&
            <TableRow condensed textProps={{small: true}} iconLeft={"electricvehicleplugoutlined"}>
                {localizeCurrency(pricePerKWH, park?.territory.currencyCode, false) + " " + Strings.perKwh}
            </TableRow>}

            {!!park && <SelectedVoucherCard territory={park.territory} voucher={voucher} park={park}
                                            activity={ActivityType.Casual}/>}
            <ParkInstructions title={Strings.parking_instructions}
                              text={(!!park?.description && !!park?.instructions) ? park?.description + "\n" + park?.instructions :
                                  (!park?.description ? park?.description : park?.instructions)}
                              style={{marginHorizontal: 0, marginVertical: 0}}
                              showHeading={true}
                              descriptionTextProps={{h2: true, style: {marginTop: 54}}}
                              showCollapsed={true}/>
            <Text h2 style={{marginTop: 54}}>{Strings.location}</Text>
            {currentSession?.park && <LocationCard parkingType={ParkingType.CASUAL} parkId={currentSession?.park}
                                                   displayType={CasualDisplayType.PRICE_PER_HOUR}
                                                   displayText={markerText}
                // @ts-ignore - because location card component doesn't support ParkDTO type for mapPark property
                                                   mapPark={mapPark} user={user ?? undefined}
                                                   isMotorBikeDefault={isMotorBikeDefault}/>}


            <SupportFooterView/>
            <Button border center plain textProps={{h5: true, bold: true}} style={[{marginBottom: 40, marginTop: 17}, {bottom}]} onPress={backMap}>
                {Strings.back_to_map}
            </Button>
        </View>

        <Modal
            animationType="fade"
            visible={isOpen}
            transparent={true}>
            <View style={styles.modalContainer}>
                <ImageViewer
                    enableSwipeDown
                    saveToLocalByLongPress={false}
                    onClick={() => setIsOpen(false)}
                    onCancel={() => setIsOpen(false)}
                    imageUrls={(props.park?.images || []).map(i => {
                        return {url: i} as IImageInfo
                    })}/>
            </View>
        </Modal>

        <Dialog ref={stopParkingDialogRef}
                label={Strings.stop_parking}
                labelProps={{style: {color: Colours.BLACK, textAlign: 'left'}}}
                title={Strings.confirm_end_parking}
                titleProps={{style: {textAlign: 'left'}, h2: undefined}}
                positiveText={Strings.end_session}
                positiveProps={{red: true, textProps: {h5: true}}}
                negativeText={Strings.keep_parking}
                negativeProps={{textProps: {h5: true}}}
                onPositivePress={onConfirmStopParking}/>

        <Dialog
            isVisible={showEVChargerDialog}
            label={Strings.charger_is_offline}
            labelProps={{ style: { color: Colours.BLACK, textAlign: "left" } }}
            title={Strings.charger_is_offline_description}
            titleProps={{ style: { textAlign: "left" }, h2: undefined }}
            positiveText={Strings.contact_support}
            positiveProps={{ outlined: true, textProps: { h5: true } }}
            negativeText={Strings.keep_parking}
            negativeProps={{ textProps: { h5: true } }}
            onNegativePress={() => setShowEVChargerDialog(false)}
            onPositivePress={() => {
                setShowEVChargerDialog(false);
                onProblemPress();
            }}
        />

        <Dialog style={[{top}, styles.voucherDialog]}
                ref={voucherDialogRef}
                icon={Icons.tick} iconProps={{white: true}}
                label={Strings.voucher_applied}/>
        {parkSession &&
            <EstimatedLeaveTimeModal
                ref={leaveTimeDialogRef}
                mutate={mutateParkSession}
                onNegativePress={() => leaveTimeDialogRef.current?.hide()}
                currentSession={parkSession}
            />}
    </ParkableBaseView>)
}

const getReduxProps = (state: IRootReducer) => {
    const currentSession = state.parking.currentSession;
    const park = currentSession ? state.parks.parks[currentSession?.park] : undefined;
    const mapPark = currentSession ? state.parks.mapParks[currentSession?.park] : undefined;
    let bay = null;
    //let organisation = null;
    let voucher:Voucher|null = null;
    if (!!park) {
        if (!!currentSession?.bay && !!state.parks.bays[park.id]) {
            bay = state.parks.bays[park.id][currentSession.bay];
        }
        voucher = ((state.user || {}).casualVoucher || {})[park?.id]
        // if (park.organisation) {
        //     organisation = ((state.organisations || {}).organisations || {})[park.organisation]
        // }
    }

    const currentVehicle = _.first(state.user.vehicles?.filter(v => v.id === state.user.currentVehicleId)) || null;

    return {
        park,
        mapPark,
        bay,
        //organisation,
        ocpiSessions: !!currentSession ?state.ev.ocpiSessions[currentSession?.id] : undefined,
        userId: state.data.userId,
        userCards: state.user.cards,
        currentCardId: state.user.currentCardId,
        userVehicles: state.user.vehicles,
        territories: state.territories?.territories || {},
        isPublicParker: !!park ? (state.parks.publicParker[park?.id]) : undefined,
        currentSession,
        api: state.data.api,
        dateformat: state.settings.dateformat,
        employeeSubscriptions: state.subscriptions.employeeSubscriptions,
        voucher,
        currentVehicle
    };
};

const actions = {
    getPark,
    getIsPublicParker,
    stopParking,
    getBay,
    getEvSessions,
    retrieveLargestDiscountVoucher,
    getCurrentParkingSession,
    getSession,
    changeSessionBay,
    getUserBays
};

export default connect(getReduxProps, actions)(ActiveSessionView);

export const ActiveSessionRoute = createRoute({
    path: Routes.ActiveSession,
    params: { type: ActiveSessionViewParams },
});

const styles = StyleSheet.create({
    mapLinkTableRow : {
        borderBottomWidth: 1,
        borderBottomColor: Colours.GREY_BORDER,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 9,
    },
    imageScrollView: {
        marginTop: 9,
        marginBottom: 9,
        height: 120,
        width: '100%'
    },
    standardIcon: {
        fontFamily: Platform.OS === "ios" ? "parkable-icon-library" : "parkable-icon-library",
        fontSize: 30
    },
    reservation: {
        backgroundColor: Colours.ORANGE
    },
    voucherDialog: {
        position:'absolute',
        width:'100%'
    },
    modalContainer:{
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
    },
    sharingPoolRowContainer: {
        marginBottom: 9,
    },
    sharingPoolRowText: {
        maxWidth: 120,
        alignContent: "flex-start",
    },
});
