import TableRow from "react/parkable-components/tableRow/TableRow";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import React from 'react'
import {StyleSheet, View } from 'react-native';
import Strings from '../../constants/localization/localization';
import { useBayGroupsMemberInPark } from '../../api/bayGroup/bayGroup.api';
import { Routes } from "../../navigation/root/root.paths";
import {createRoute, NavigationProps, useNavigation} from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";

class PreferredBaysNavParams {
    orgId: number;
    parkId: number;
}

export default function PreferredBaysView(props: NavigationProps<Routes.PreferredBaysView>) {
    const navigation = useNavigation();
    const { orgId, parkId } = props.route.params;
    const { bayGroups, isLoading } = useBayGroupsMemberInPark(orgId, parkId);

    const parkHasUnmarkedBayGroups = bayGroups?.some(bg => bg.hasNoSignageBays);

    return (
    <ParkableBaseView>
        <View style={styles.container}>

            {(!bayGroups || isLoading) && <Spinner small />}

            {bayGroups &&
                <View style={styles.bayGroupContainer}>
                    <View style={styles.textContainer}>
                        <Text h1 bold>{Strings.preferred_bays.select_preferred_bays}</Text>
                        <Text>{Strings.preferred_bays.preferred_bays_intro}</Text>
                    </View>

                    {!parkHasUnmarkedBayGroups &&
                        <TableRow
                            condensed
                            chevron
                            onPress={() => navigation.push(Routes.PreferredBaysSelectionView, {
                                parkId,
                                bayGroupName: Strings.preferred_bays.all_bays,
                            })}>
                            {Strings.preferred_bays.all_bays}
                        </TableRow>
                    }
                    {bayGroups
                        // Tandem MVP: Don't show bay groups that have tandem bays - Faiz.
                        .filter((bg) => !bg.hasTandemBays)
                        .sort((a, b) => a.name < b.name ? -1 : 1 )
                        .map((bg) =>
                            <TableRow
                                condensed
                                chevron
                                key={bg.id}
                                onPress={() => navigation.push(Routes.PreferredBaysSelectionView, {
                                    parkId,
                                    bayGroupId: bg.id,
                                    bayGroupName: bg.name,
                                    hasNoSignageBays: bg.hasNoSignageBays ?? undefined,
                                })}
                            >
                                {bg.name}
                            </TableRow>
                        )}
                </View>
            }
        </View>
    </ParkableBaseView>)
}

export const PreferredBaysRoute = createRoute({
    path: Routes.PreferredBaysView,
    params: {type: PreferredBaysNavParams},
});

const styles = StyleSheet.create({
    container: {
        marginBottom: 30,
    },
    bayGroupContainer: {
        flex: 1,
        marginBottom: 45,
    },
    textContainer: {
        paddingBottom: 18,
    }
})
