import * as Act from '../actions/subscriptions'
import {ParkSession} from "../../model/ParkSession";
import {EmployeeSubscription} from "../../model/EmployeeSubscription";
import { USER_LOGGED_OUT } from '../actions/user';

type EmployeeSubscriptionMap = {[subId:number]: EmployeeSubscription};

const init = {
    employeeSubscription: null as EmployeeSubscription | null,
    employeeSubscriptions: {} as EmployeeSubscriptionMap,
    employeeSubscriptionSessions: {} as {[employeeSubscriptionId: number]: ParkSession},
};

type ISubscriptionsReducer = typeof init;

export default function dataReducer(state = init, action: any): ISubscriptionsReducer {
    switch (action.type) {
        case Act.SET_EMPLOYEE_SUBSCRIPTION_SESSION: {
            return {
                ...state,
                employeeSubscriptionSessions: {
                    ...state.employeeSubscriptionSessions,
                    [action.employeeSubscriptionId]: action.session,
                }
            };
        }
        case Act.SET_EMPLOYEE_SUBSCRIPTIONS: {
            const employeeSubscriptions = {} as EmployeeSubscriptionMap;
            action.employeeSubscriptions.forEach((empSub: EmployeeSubscription) => {
                employeeSubscriptions[empSub.id] = empSub;
            });
            // console.log("SET_EMPLOYEE_SUBSCRIPTIONS");
            return {
                ...state,
                employeeSubscriptions,
            };
        }
        case Act.SET_EMPLOYEE_SUBSCRIPTION: {
            state.employeeSubscriptions[action.employeeSubscription.id] = action.employeeSubscription
            return {
                ...state,
                employeeSubscription: action.employeeSubscription,
                employeeSubscriptions: state.employeeSubscriptions
            };
        }

        case USER_LOGGED_OUT: {
            return {
                ...init
            }
        }
        default:
            return state;
    }
}
