import React, { useCallback, useEffect, useRef, useState } from "react";
import { Linking, Platform, StyleSheet, View } from "react-native";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import { IconName } from "react/parkable-components/icon/Icons";
import { IconProps } from "react/parkable-components/icon/Icon";
import { TextProps } from "react/parkable-components/text/Text";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Button from "react/parkable-components/button/Button";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../constants/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import { EmployeeSubscription } from "../../model/EmployeeSubscription";
import { Park } from "../../model/Park";
import { Term } from "../../model/Term";
import { Bay } from "../../model/Bay";
import { getBay, getBayGroupsInParkIds, getPark, ParkDTOWithTerritory } from "../../redux/actions/parks";
import { getFavouriteParks } from "../../redux/actions/userOptions";
import { connect } from "react-redux";
import { IRootReducer } from "../../redux/reducers/main";
import { cancelEmployeeSubscription, getEmployeeSubscription } from "../../redux/actions/subscriptions";
import { getOrganisation } from "../../redux/actions/organisations";
import localizeCurrency from "../../constants/localization/localizeCurrency";
import { showAlert, showConfirmation } from "../../alerts";
import { retrieveLargestDiscountVoucher } from "../../redux/actions/vouchers";
import { ActivityType } from "../../model/Voucher";
import { Territory } from "../../model/Territory";
import { useNavigation } from "../../navigation/constants";
import useSafeArea from "../../hooks/useSafeArea";
import { StartSubscriptionSummaryViewParams } from "../subscriptions/StartSubscriptionSummaryView";
import { MAX_WIDTH_WEB } from "react/root/container/container";

function  ModalMessageCard(props: Props & typeof actions & ReturnType<typeof reduxProps>) {
    const navigation = useNavigation();
    const {
        label,
        title,
        iconName,
        iconProps,
        showMessage,
        onModalHide,
        titleProps,
        labelProps,
        modalType,
        signage,
        payloadData,
        getPark,
        getBay,
        getBayGroupsInParkIds,
        getEmployeeSubscription,
        getOrganisation,
        getFavouriteParks,
        cancelEmployeeSubscription,
        retrieveLargestDiscountVoucher
    } = props;

    const { top } = useSafeArea();

    const dialogRef = useRef<DialogRef | null>(null);

    const [loading, setLoading] = useState(false);
    const [park, setPark] = useState<ParkDTOWithTerritory | null>(null);
    const [bay, setBay] = useState<Bay|null>(null);
    const [employeeSubscription, setEmployeeSubscription] = useState<EmployeeSubscription|null>(null);
    const [localTitle, setLocalTitle] = useState<string|null>(null);
    const [localSignage, setLocalSignage] = useState<string|null>(null);
    const [parkFetched, setParkFetched] = useState<boolean>(false);
    const [bayFetched, setBayFetched] = useState<boolean>(false);
    const [orgFetched, setOrgFetched] = useState<boolean>(false);
    const [subFetched, setSubFetched] = useState<boolean>(false);
    const [bgsFetched, setBgsFetched] = useState<boolean>(false);
    const [voucherFetched, setVoucherFetched] = useState<boolean>(false);

    useEffect(() => {
        if (showMessage) {
            dialogRef.current?.show();
        } else {
            setLocalSignage(null);
            setLocalTitle(null);
            setParkFetched(false);
            setOrgFetched(false);
            setBayFetched(false);
            setSubFetched(false);
            setBgsFetched(false);
            dialogRef.current?.hide();
        }
    }, [showMessage]);

    //prepare for subscription invitation data
    useEffect(() => {
        if (modalType === ModalType.SubscriptionInvitation) {
            const employeeSubscriptionId = payloadData;
            let sub:EmployeeSubscription = props.employeeSubscriptions[employeeSubscriptionId];
            let b:Bay|null = null;
            if(!!sub) {
                setEmployeeSubscription(sub);

                const p = props.parks[sub.park];
                if(!!p) {
                    setPark(p);
                } else if(!parkFetched) {
                    setParkFetched(true);
                    getPark(sub.park);
                }

                if(!bgsFetched) {
                    setBgsFetched(true);
                    getBayGroupsInParkIds(sub.organisation, sub.park);
                }

                b = (props.bays[sub.park] || {})[sub.bay];
                if(!!b) {
                    setBay(b);
                    setLocalSignage(b.signage);
                } else if(!bayFetched) {
                    setBayFetched(true);
                    if (!!sub.bay) {
                        getBay(sub.park, sub.bay);
                    }
                }

                const o = props.organisations[sub.organisation];
                if(!!o) {
                    setLocalTitle(Strings.org_assign_you_bay(o?.name));
                } else if(!orgFetched && !!sub?.organisation) {
                    setOrgFetched(true);
                    getOrganisation(sub.organisation);
                }
                if(!voucherFetched) {
                    setVoucherFetched(true);
                    retrieveLargestDiscountVoucher(sub.park, ActivityType.LongTerm, employeeSubscriptionId);
                }
            } else if(!subFetched) {
                getEmployeeSubscription(employeeSubscriptionId);
                setSubFetched(true);
            }
            setLoading(!park || !employeeSubscription || !b)
        }
    }, [modalType, payloadData, park, employeeSubscription, localSignage, localTitle, props.employeeSubscriptions, props.parks, props.bays, props.organisations]);

    //reload favs on invitations
    useEffect(() => {
        if(modalType === ModalType.MemberInvitation || modalType === ModalType.SubscriptionInvitation) {
            getFavouriteParks();
        }
    }, [modalType]);

    const onAcceptSubscription = () => {
        if (modalType === ModalType.SubscriptionInvitation && !!employeeSubscription) {
            const isInvitation = employeeSubscription?.status === "Invited";
            if (!isInvitation) {
                dialogRef.current?.hide();
                return;
            }
            setLoading(true);
            const employeeSubscriptionId = employeeSubscription.id;
            const term = employeeSubscription?.term;
            const baysAvailable = !!bay ? [bay] : [];
            const routeParams: StartSubscriptionSummaryViewParams = {
                parkId: park!.id,
                bayId: bay?.id,
                term,
                baysAvailable,
                isInvitationConfirmation: true,
                employeeSubscriptionId
            };
            console.log("Route Params", routeParams);
            setLoading(false);
            dialogRef.current?.hide();
            navigation.push(Routes.StartSubscriptionSummaryView, {...routeParams})
        }
    };

    const onDeclineSubscription = useCallback(() => {
        if (modalType === ModalType.SubscriptionInvitation && !!employeeSubscription) {
            setLoading(true);
            (async (employeeSubscription) => {
                try {
                    await cancelEmployeeSubscription(employeeSubscription.id, false);
                    showConfirmation(Strings.invitation_cancelled);
                    setLoading(false);
                    dialogRef.current?.hide();
                } catch (err) {
                    setLoading(false);
                    dialogRef.current?.hide();
                    if ((err as any)?.message) {
                        showAlert((err as any).message, Strings.error);
                    }
                }
            })(employeeSubscription)
        }
    }, [modalType, employeeSubscription]);

    const onViewCarpark = useCallback(() => {
        if (modalType === ModalType.SubscriptionInvitation && !!park) {
            dialogRef.current?.hide();
            navigation.push(Routes.ParkDetailView, {parkId: park?.id});
        }
    }, [dialogRef, modalType, park]);

    return  <Dialog style={[styles.container, {top}]} ref={dialogRef} iconFABProps={!!modalType ? {style: {backgroundColor: Colours.PINK_DARK}} : undefined}
                        icon={iconName} iconProps={iconProps} label={label || undefined} labelProps={labelProps}
                        title={localTitle || title || undefined} titleProps={titleProps} onModalHide={onModalHide}>
                <View style={styles.innerContainer}>
                    {!!(localSignage || signage) && <Text center bold style={styles.bayNumberMessage}>{localSignage || signage}</Text>}
                    {modalType === ModalType.SubscriptionInvitation && <SubscriptionInvitationContent employeeSubscription={employeeSubscription}
                                                                                                    loading={loading}
                                                                                                    park={park}
                                                                                                    territory={park?.territory}
                                                                                                    onAcceptSubscription={onAcceptSubscription}
                                                                                                    onDeclineSubscription={onDeclineSubscription}
                                                                                                    onViewCarpark={onViewCarpark}/>}
                </View>
            </Dialog>
}

const SubscriptionInvitationContent = (props: {
    employeeSubscription: EmployeeSubscription|null,
    loading: boolean,
    park: Pick<Park, 'address'> | null,
    territory: Territory | null | undefined,
    onAcceptSubscription: () => void,
    onDeclineSubscription: () => void,
    onViewCarpark: () => void
}) => {
    const dialogRef = useRef<DialogRef|null>(null);
    const {employeeSubscription, park, territory, loading, onAcceptSubscription, onDeclineSubscription, onViewCarpark} = props;
    const subscriptionPrice = localizeCurrency(employeeSubscription?.pricePerTerm || 0, territory?.currencyCode);
    const isWeeklyTerm = employeeSubscription?.term === Term.Week;
    const isInvitation = employeeSubscription?.status === "Invited";

    const onDeclineSubscriptionPress = useCallback(() => {
        dialogRef.current?.show();
    }, []);

    const onDecline = useCallback(() => {
        onDeclineSubscription();
        dialogRef.current?.hide();
    }, []);

    const showTnC = () => {
        (async () => {
            const URL = "https://www.parkable.com/terms-of-service";
            if (await Linking.canOpenURL(URL)) {
                Linking.openURL(URL);
            }
        })();
    };
    return  loading ? <View><Spinner large /></View> :
                <View>
                <TableRow condensed iconLeft={"key"} label={Strings.subscription_details}>
                    {`${subscriptionPrice} ${isWeeklyTerm ? Strings.per_week : Strings.per_month}`}
                </TableRow>
                <TableRow iconLeft={'pinlocation2filled'} iconLeftProps={{color: Colours.GREEN}} label={Strings.location} chevron onPress={onViewCarpark}>
                    <Text small numberOfLines={2}>{park?.address}</Text>
                </TableRow>
                <Button center style={{width: '100%', marginBottom: 10}} onPress={onAcceptSubscription} disabled={loading}>{isInvitation ? Strings.view_details : Strings.ok}</Button>
                <View style={{flexDirection: 'row', alignContent: 'space-between'}}>
                    <Button style={{flex: 1}} textProps={{h5: true}} border plain onPress={showTnC}>{Strings.terms_and_conditions}</Button>
                    <View style={{width: 10}}/>
                    {isInvitation ?
                        <Button style={{flex: 1}} textProps={{h5: true}} border plain onPress={onDeclineSubscriptionPress} disabled={loading}>{Strings.no_thanks}</Button> :
                        <Button style={{flex: 1}} textProps={{h5: true}} border plain onPress={onViewCarpark} disabled={loading}>{Strings.view_car_park}</Button>}
                </View>
                <Dialog title={Strings.are_you_sure_cancel_subscription}
                        ref={dialogRef}
                        positiveText={Strings.yes}
                        negativeText={Strings.no}
                        onPositivePress={onDecline}/>
            </View>
};

const actions = {
    getPark, getBay, getBayGroupsInParkIds, getEmployeeSubscription, getOrganisation, getFavouriteParks, cancelEmployeeSubscription, retrieveLargestDiscountVoucher
};

const reduxProps = (state: IRootReducer) => {
    const employeeSubscriptions = state.subscriptions.employeeSubscriptions || {};
    const organisations = state.organisations.organisations || {};
    const parks = state.parks.parks || {};
    const bays = state.parks.bays || {};
    const bayGroups = state.parks.bayGroups || {};
    return {
        parks,
        bays,
        employeeSubscriptions,
        organisations,
        bayGroups
    }
};

type Props = {
    showMessage?: boolean,
    label?:string | null;
    title?:string | null;
    message?:string | null;
    iconName:IconName;
    messageProps?: Omit<TextProps, "name">;
    iconProps?: Omit<IconProps, "name">;
    onModalHide?: () => void;
    titleProps?: TextProps;
    labelProps?: TextProps;
    signage?: string | null;
    modalType?: ModalType | null;
    payloadData: any;
}

export enum ModalType {
    MemberInvitation= 'MemberInvitation',
    SubscriptionInvitation= 'SubscriptionInvitation'
}

const styles = StyleSheet.create({
    container: {
        position:'absolute',
        width:'100%',
        ...Platform.select({
            web: {
                maxWidth: MAX_WIDTH_WEB,
                alignSelf: "center",
            }
        }),
    },
    innerContainer:{
        alignItems: 'center',
    },
    bayNumberMessage:{
        fontSize: 90,
        lineHeight: 90,
        fontWeight: "900",
    }
});

export default connect(reduxProps, actions)(ModalMessageCard) as React.FunctionComponent<Props>;
