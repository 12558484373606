import React, { useState, useCallback } from 'react';
import moment, { Moment } from "moment";
import { connect } from 'react-redux';
import { IRootReducer } from "../../redux/reducers/main";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import { StyleSheet, View, Linking } from "react-native";
import Strings from "../../constants/localization/localization";
import { Term } from "../../model/Term";
import Icon from "react/parkable-components/icon/Icon";
import SupportFooterView from "../common/SupportFooterView";
import localizeCurrency from "../../constants/localization/localizeCurrency";
import EditSubscriptionStartDateView from "./EditSubscriptionStartDateView";
import { Routes } from "react/navigation/root/root.paths";
import { Bay } from "../../model/Bay";
import { useBayGroup } from "../../api/bayGroup/bayGroup.api";
import { getLongTermPrices } from "../../constants/price.util";
import { usePark } from "../../api/park";
import { useTerritory } from "../../api/territory/territory.api";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import { useBay } from "../../api/bay/bay.api";
import { StartSubscriptionSummaryViewParams } from "./StartSubscriptionSummaryView";
import { ScrollView } from 'react-native-gesture-handler';

const SubscriptionDetailsView = (props: ReduxProps & Props) => {
    const { parkId, term, navigation, bayId, feature, baysAvailable } = props;

    const [startDate, setStartDate] = useState<Moment | undefined>(undefined);
    const [showEditStartDate, setShowEditStartDate] = useState(false);

    const { park } = usePark(parkId);
    const { territory } = useTerritory(park?.territory);
    const { bay } = useBay(parkId, bayId);
    const { bayGroup } = useBayGroup(park?.organisation, bay?.group);

    const onEditStartDate = useCallback(() => {
        setShowEditStartDate(true)
    }, []);

    const onSelectNewDate = useCallback((date: Moment) => {
        if (date) {
            setStartDate(date)
        }
    }, []);

    const onCancellationTermsPress = useCallback(() => {
        (async () => {
            const URL = "https://www.parkable.co.nz/cancellation-policy-subscriptions/";
            if (await Linking.canOpenURL(URL)) {
                Linking.openURL(URL);
            }
        })();
    }, []);

    const onReviewPress = useCallback(() => {
        const params: StartSubscriptionSummaryViewParams = {
            parkId,
            term,
            startDate,
            bayId,
            feature,
            baysAvailable
        }
        navigation.navigate(Routes.StartSubscriptionSummaryView, params);
    }, [park, term, startDate, bay]);

    const isWeeklyTerm = term === Term.Week;

    const { pricePerWeek, pricePerMonth } = getLongTermPrices(park, bayGroup);

    const price = isWeeklyTerm ? pricePerWeek : pricePerMonth;
    const canStartInFuture = !!park?.subscriptionMaxDaysInAdvance && park.subscriptionMaxDaysInAdvance > 0;

    return (
        <ParkableBaseView scrollable={false}>

            <ScrollView showsVerticalScrollIndicator={false}>

                <View style={styles.pricingTitle}>
                    <View style={styles.subscriptionIconContainer}>
                        <Icon name={"key"} />
                    </View>
                    <Text h2 style={styles.labelPricingTitle}>
                        {isWeeklyTerm ? Strings.weekly_subscription : Strings.monthly_subscription}
                    </Text>
                </View>
                <Text small style={{ paddingVertical: 18 }}>
                    {isWeeklyTerm
                        ? Strings.weekly_subscription_payment_details(
                                (startDate || moment()).format("dddd")
                            )
                        : Strings.monthly_subscription_payment_details(
                                (startDate || moment()).format("Do")
                            )}
                </Text>
                <TableRow
                    textProps={{ small: true }}
                    iconLeft={"dollaroutlined"}
                    labelProps={{ style: { color: Colours.BLACK } }}
                    labelBottom={`${localizeCurrency(price!, territory?.currencyCode)} ${
                        isWeeklyTerm ? Strings.perWeek : Strings.perMonth
                    }`}
                >
                    <Text small grey>{Strings.price}</Text>
                </TableRow>
                <TableRow
                    textProps={{ small: true }}
                    iconLeft={"calendar"}
                    labelProps={{ style: { color: Colours.BLACK } }}
                    labelBottom={
                        !startDate
                            ? moment().format("dddd, MMMM Do")
                            : startDate?.format("dddd, MMMM Do")
                    }
                >
                    <Text small grey>{Strings.date_of_first_payment}</Text>
                </TableRow>
                {!showEditStartDate && canStartInFuture && (
                    <View style={styles.editDateBox}>
                        <View style={styles.linkEditDate}>
                            <Text onPress={onEditStartDate} small style={styles.editStartDateLink}>
                                {Strings.edit_start_date}
                            </Text>
                            <Text small grey>
                                {Strings.up_to_X_days(park?.subscriptionMaxDaysInAdvance || 0)}
                            </Text>
                        </View>
                    </View>
                )}
                {showEditStartDate && park && canStartInFuture && (
                    <EditSubscriptionStartDateView park={park} onSelectNewDate={onSelectNewDate} />
                )}
                <View style={styles.detailsContainer}>
                    {!!(bayGroup?.termsOfLease || bay?.termsOfLease) && (
                        <View>
                            <Text bold h4 style={styles.labelEpicTitle}>
                                {Strings.subscription_terms}
                            </Text>
                            <Text small>{bayGroup?.termsOfLease || bay?.termsOfLease}</Text>
                        </View>
                    )}

                    <Text bold h4 style={styles.labelEpicTitle}>
                        {Strings.cancellation}
                    </Text>
                    <View>
                        <Text small>
                            {Strings.cancellationTermsExplanation}
                            <Text small onPress={onCancellationTermsPress} style={styles.cancellationTermsLink} p>
                                {Strings.seeCancellationPolicyToLearnMore}
                            </Text>
                        </Text>
                    </View>
                </View>

                <SupportFooterView />

            </ScrollView>

            <View style={styles.outerFooter}>
                <View style={styles.confirmButton}>
                    <Button iconRight={"arrowlightright"} onPress={onReviewPress}>
                        {Strings.review_details}
                    </Button>
                </View>
            </View>

        </ParkableBaseView>
    );

};

export class SubscriptionDetailsViewPrams {
    parkId: number;
    term: Term;
    bayId?: number;
    feature: string;
    baysAvailable: Bay[];
}

type Props = NavigationProps<Routes.SubscriptionDetailsView>

const getReduxProps = (state: IRootReducer, props: Props) => {
    return {
        parkId: props.route.params?.parkId,
        term: props.route.params?.term,
        bayId: props.route.params?.bayId,
        feature: props.route.params?.feature,
        baysAvailable: props.route.params?.baysAvailable
    }
};

type ReduxProps = ReturnType<typeof getReduxProps>;

export default connect(getReduxProps)(SubscriptionDetailsView) as any as React.FC<Props>;

export const SubscriptionDetailsRoute = createRoute({
    path: Routes.SubscriptionDetailsView,
    params: { type: SubscriptionDetailsViewPrams }
});

const styles = StyleSheet.create({
    pricingTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    subscriptionIconContainer: {
        backgroundColor: Colours.ORANGE,
        borderRadius: 50,
    },
    labelPricingTitle: {
        marginLeft: 10,
        marginBottom: 0
    },
    editDateBox: {
        marginTop: 18
    },
    linkEditDate: {
        flex: 1,
        flexDirection: "row",
    },
    editStartDateLink: {
        color: Colours.BLUE,
        paddingRight: 9
    },
    detailsContainer: {
        paddingBottom: 45,
    },
    labelEpicTitle: {
        paddingTop: 45,
        marginBottom: 12
    },
    cancellationTermsLink: {
        color: Colours.BLUE,
    },
    outerFooter: {
        paddingTop: 18,
    },
    confirmButton: {
        paddingBottom: 27,
    },
});
