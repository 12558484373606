import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet, View } from "react-native";
import Text from "react/parkable-components/text/Text";
import Select from "react/parkable-components/select/Select";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "../../constants/localization/localization";
import ToggleSwitch from "../common/ToggleSwitch";
import { ParkAvailabilityNotification } from "../../model/ParkAvailabilityNotification";
import moment from "moment/moment";
import { getClosesAtHour } from "../../constants/availability/getTodayAvailability";
import { showAlert } from "../../alerts";
import { ParkDTO } from "../../model/ParkDTO";
import { useSelector } from "../../redux/redux";
import { range } from "lodash";
import { PickerSelectProps } from "react-native-picker-select";
import { __WEB__ } from "react/parkable-components/constants";

type Props = {
    park: ParkDTO,
    getParkAvailabilityNotificationForPark: (parkId: number) => ParkAvailabilityNotification
    createUpdateParkAvailabilityNotification: (id: number, hours: number, isCreating: boolean) => any,
    deleteParkAvailabilityNotification: (parkId: number, id: number) => any,
    baysAvailable: number | undefined
}

function NoBaysAvailableComponent(props: Props) {

    const {
        park,
        baysAvailable,
        getParkAvailabilityNotificationForPark,
        createUpdateParkAvailabilityNotification,
        deleteParkAvailabilityNotification
    } = props;

    const closesAtHour = getClosesAtHour(park.availability);

    const options = useMemo(() => {
        if (closesAtHour === undefined) {
            return [];
        }
        return range(moment().hour() + 1, closesAtHour + 1)
            .map(hour => ({
                value: hour,
                label: hourToLabel(hour)
            }))
    }, [closesAtHour]);

    const defaultIndex = Math.min(options.length - 1, 6);

    const [selectedHour, setSelectedHour] = useState(options[defaultIndex]?.value);

    const [removing, setRemoving] = useState(false);

    const { availabilityNotifications } = useSelector(state => state.parks);

    const parkNotification = useMemo(() => {
        const parkNotification = availabilityNotifications[park.id];
        if (parkNotification && options.some(o => moment(parkNotification.notifyUntil).hour() === o.value)) {
            return parkNotification;
        }
    }, [availabilityNotifications, park.id, options]);

    const notifyUntilHour = parkNotification
        ? moment(parkNotification.notifyUntil).hour()
        : selectedHour;

    useEffect(() => {
        if(notifyUntilHour !== selectedHour) {
            setSelectedHour(notifyUntilHour);
        }
    }, [notifyUntilHour])

    useEffect(()=>{console.log({notifyUntilHour, selectedHour})},[notifyUntilHour, selectedHour])

    useEffect(() => {
        getData().catch(console.error);
    }, [park.id]);

    if (closesAtHour === undefined) {
        return null;
    }

    async function getData() {
        try {
            await getParkAvailabilityNotificationForPark(park.id);
        } catch (err) {
            showAlert(Strings.error_persists, Strings.error);
        }
        setRemoving(false);
    }

    function hourToLabel(hour: number) {
        if (hour === 12) {
            return hour + ":00 pm";
        } else if (hour > 12) {
            return hour - 12 + ":00 pm";
        } else {
            return hour + ":00 am";
        }
    }

    async function createUpdate(hour: number, isCreating: boolean) {
        try {
            const initialNotifMinutes = 60 - moment().minutes() + (60 * (hour - moment().hours() - 1));
            await createUpdateParkAvailabilityNotification(park.id, initialNotifMinutes, isCreating);
        } catch (err) {
            showAlert(Strings.error_persists, Strings.error);
        }
    }

    const updateParkNotificationEnabled = async (on: boolean) => {
        if (on) {
            await createUpdate(selectedHour, true);
        } else {
            await removeNotification();
        }
    };

    const removeNotification = async () => {
        if (removing) {
            return;
        }
        if (parkNotification) {
            try {
                setRemoving(true);
                await deleteParkAvailabilityNotification(park.id, parkNotification.id);
            } catch (err) {
                showAlert(Strings.error_persists, Strings.error);
            }
            setRemoving(false);
        }
    };

    const onNotifyTimeChange = async () => {
        console.log("onNotifyTimeChange");
        if (selectedHour === null || isNaN(selectedHour) || selectedHour === notifyUntilHour) {
            return;
        }
        await createUpdate(selectedHour, false);
    };

    if (baysAvailable !== undefined && baysAvailable > 0) {
        if (!parkNotification) {
            return null;
        }

        return (<View style={styles.container}>
            <Text h5 white style={{ lineHeight: 24 }}>{Strings.you_have_an_active_bay_notification}</Text>
            <Button center small textProps={{ style: { color: Colours.WHITE, textAlign: "center" } }} border={true}
                    plain onPress={removeNotification}>{Strings.remove}</Button>
        </View>);
    }

    if (options.length === 0) {
        //the park is not open between now and the end of the day so hide the whole thing
        return null;
    }

    const pickerStyle = {
        inputIOS: styles.pickerText,
        inputAndroid: styles.pickerText,
        inputWeb: styles.pickerTextWeb,
        viewContainer: styles.pickerViewContainer,
    }

    const placeholder: PickerSelectProps["placeholder"] = {
        label: "Select option",
        value: null,
        color: __WEB__ ? Colours.WHITE : Colours.GREY_60,
    };

    return (<View style={styles.container}>
        <Text h2 white style={{ marginBottom: 4 }}>{Strings.no_bays_available}</Text>
        <View
            style={{
                borderColor: Colours.WHITE,
                borderBottomWidth: 1,
                height: 0,
                width: "100%",
                marginBottom: 9
            }} />
        <Text>
            <Text h5 white style={{ lineHeight: 24 }}>{Strings.noBaysAvailableText}</Text>
            <Text h5 white bold style={{ lineHeight: 24 }}>{Strings.please_do_not_park_here}</Text>
            <Text h5 white style={{ lineHeight: 24 }}>{Strings.even_if_you_see_a_bay}</Text>
        </Text>
        <View style={{ flex: 1, flexDirection: "row", alignContent: "center", marginTop: 9, marginBottom: 0 }}>
            <ToggleSwitch circleColor={Colours.WHITE} animationSpeed={150}
                          trackOnStyle={{ borderColor: Colours.WHITE, borderWidth: 1 }}
                          trackOffStyle={{ borderColor: Colours.WHITE, borderWidth: 1 }}
                          onColor={Colours.GREEN} isOn={parkNotification !== undefined}
                          onToggle={updateParkNotificationEnabled} />
            <Text style={{ marginLeft: 9, height: "100%", textAlignVertical: "center", flex: 1 }} small
                  white>{Strings.notify_when_bay_available}</Text>
        </View>
        {parkNotification &&
        <View style={{ width: "100%", alignContent: "center", marginTop: 9 }}>
            <Text style={styles.notificationText} small white>{Strings.stop_notifying_me_at}</Text>

            <Select
                pickerStyle={pickerStyle}
                items={options}
                value={selectedHour}
                onValueChange={setSelectedHour}
                onClose={onNotifyTimeChange}
                placeholder={placeholder}
            />
        </View>}
    </View>);
}

export default NoBaysAvailableComponent;

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colours.PINK_DARK,
        width: "100%",
        padding: 18,
        borderColor: Colours.PINK_DARK,
        borderRadius: 3,
        borderWidth: 1,
        marginTop: 27,
        borderStyle: "solid",
    },
    notificationText: {
        marginRight: 27,
        marginBottom: 7,
    },
    pickerText: {
        color: Colours.WHITE,
        backgroundColor: Colours.TRANSPARENT,
        borderColor: Colours.TRANSPARENT,
    },
    pickerTextWeb: {
        color: Colours.WHITE,
        backgroundColor: Colours.PINK_DARK,
        borderColor: Colours.TRANSPARENT,
    },
    pickerViewContainer: {
        flex: 1,
        justifyContent: "center",
    },
});
