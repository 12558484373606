import React, { useEffect, useRef, useState } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../../constants/localization/localization";
import FirstTimeSetup from "../../common/FirstTimeSetup";
import { Nully } from "react/constants/nully";
import { Routes } from "react/navigation/root/root.paths";
import { createRoute, NavigationProps, useNavigation } from "react/navigation/constants";
import ParkableBaseView from "../../common/ParkableBaseView";
import { deleteUserVehicle, updateDefaultUserVehicle, useUserVehicles } from "react/api/vehicle/vehicle.api";
import { getCurrentUser, useCurrentUser } from "react/api/user/user.api";
import { Vehicle } from "./vehicle/Vehicle";
import { VehicleDTO } from "react/api/vehicle/dto/vehicleDTO";
import { useAlertSnackbar } from "react/root/alert-snackbar/alert-snackbar";
import { useCurrentParkingSession } from "react/api/parking";
import { updateSubscriptionVehicles } from "react/api/employeeSubscription/employeeSubscription.api";
import { EmployeeSubscription } from "react/model/EmployeeSubscription";
import { usePark } from "react/api/park/park.api";
import { updateParkSessionVehicle } from "react/api/parkSession/parkSession.api";
import { useAppDispatch, useSelector } from "react/redux/redux";
import { setCurrentParkingSession } from "react/redux/actions/session";
import { setCurrentVehicleId, setUser } from "react/redux/actions/user";
import { setEmployeeSubscription } from "react/redux/actions/subscriptions";
import { IRootReducer } from "react/redux/reducers/main";
import { clearSelectedVehicles, vehiclesSelected } from "react/redux/actions/vehicles";

class VehiclesViewParams {
    parkId?: number;
    subscription?: EmployeeSubscription;
    selectSubscriptionVehicles?: boolean;
    onBackPress?: (vehicleId: Nully<number>) => Promise<void>;
    destination?: {
        route: Routes,
        params: {
            employeeSubscriptionId: number,
            parkId: number,
            isInvitationConfirmation: boolean,
        },
    };
}

const VehiclesView = (props: NavigationProps<Routes.VehiclesView>) => {
    const navigation = useNavigation();
    const { showSnackBar } = useAlertSnackbar();
    const dispatch = useAppDispatch();

    const {
        parkId, subscription, selectSubscriptionVehicles, destination, onBackPress,
    } = props.route.params;

    const { park } = usePark(parkId);
    const maxVehiclesPerSubscription = park?.maxVehiclesPerSubscription;
    const { user, mutate: mutateUser } = useCurrentUser();
    const { parkSession, mutate: mutateParkSession } = useCurrentParkingSession();
    const { vehicles, isLoading: isVehiclesLoading, mutate: mutateVehicles } = useUserVehicles();
    const { selectedVehicleIds } = useSelector((state: IRootReducer) => state.vehicles);
    const filteredVehicles = vehicles?.filter((v) => !v.deleted);
    const defaultVehicleId = user?.vehicleId ?? vehicles?.[0]?.id;
    const [updating, setUpdating] = useState(false);
    const [showRemoveButton, setShowRemoveButton] = useState(false);
    const [vehicleToDelete, setVehicleToDelete] = useState<VehicleDTO>();
    const dialogRef = useRef<DialogRef | null>(null);

    // TODO: remove all dispatch methods once redux is removed from activeSessionView and confirmStartParkingView
    useEffect(() => {
        if (parkSession) {
            dispatch(setCurrentParkingSession(parkSession));
        }
    }, [parkSession]);

    useEffect(() => {
        if (!user || !vehicles || !selectSubscriptionVehicles) {
            return;
        }
        if (subscription) {
            dispatch(vehiclesSelected(subscription.vehicles));
        } else if (!selectedVehicleIds) {
            dispatch(vehiclesSelected(defaultVehicleId ? [defaultVehicleId] : []));
        }
        // need to convert selectedVehicleIds to boolean to avoid this hook being called every time the array changes
    }, [user, vehicles, selectSubscriptionVehicles, subscription, !selectedVehicleIds, defaultVehicleId]);

    const updateSubscriptionVehiclesByParkLimit = (vehicleId: number) => {
        if (maxVehiclesPerSubscription === 1) {
            // if the limit is 1, replace the current selection
            dispatch(vehiclesSelected([vehicleId]));
        } else if (selectedVehicleIds?.includes(vehicleId)) {
            // if the vehicle is already selected, remove it
            if (selectedVehicleIds.length > 1) {
                dispatch(vehiclesSelected(selectedVehicleIds?.filter((id) => id !== vehicleId) ?? []));
            }
        } else if (maxVehiclesPerSubscription && (selectedVehicleIds?.length ?? 0) >= maxVehiclesPerSubscription) {
            // if the limit has been reached, show error
            showSnackBar({ text: Strings.vehicle_limit_reached });
        } else {
            // else, add the vehicle
            dispatch(vehiclesSelected([...selectedVehicleIds ?? [], vehicleId]));
        }
    };

    const handleSelectVehicle = async (vehicle: VehicleDTO) => {
        if (!!maxVehiclesPerSubscription && selectSubscriptionVehicles) {
            updateSubscriptionVehiclesByParkLimit(vehicle.id);
            return;
        }
        if (user) {
            try {
                setUpdating(true);
                await updateDefaultUserVehicle(user?.id, vehicle.id);
                await mutateUser();
                if (parkSession) {
                    await updateParkSessionVehicle(parkSession.id, vehicle.id);
                }
            } catch (e) {
                showSnackBar({
                    text: Strings.error,
                    hideDismiss: true,
                    style: { backgroundColor: Colours.RED }
                });
            } finally {
                await mutateVehicles();
                mutateParkSession();
                dispatch(setCurrentVehicleId(vehicle.id));
                setUpdating(false);
                const { user: updatedUser } = await getCurrentUser();
                dispatch(setUser(updatedUser));
            }
        }
    };

    const onSavePress = async () => {
        if (!subscription || !selectedVehicleIds) {
            return;
        }
        try {
            setUpdating(true);
            const {employeeSubscription: updatedSubscription} = await updateSubscriptionVehicles(subscription.id, selectedVehicleIds);
            await mutateUser();
            await mutateVehicles();
            dispatch(clearSelectedVehicles());

            // TODO: remove this dispatch method once redux is removed from subscription view
            dispatch(setEmployeeSubscription(updatedSubscription));

            navigation.pop();
        } catch (e) {
            showSnackBar({
                text: Strings.error,
                hideDismiss: true,
                style: { backgroundColor: Colours.RED }
            });
        } finally {
            setUpdating(false);
        }
    };

    const goAddVehicle = () => {
        navigation.push(Routes.AddNewVehicleView, { destination });
    };

    const removeVehicle = (vehicle: VehicleDTO) => {
        setVehicleToDelete(vehicle);
        dialogRef.current?.show();
    };

    const handleDeleteVehicle = async () => {
        if (!vehicleToDelete) return;
        try {
            setUpdating(true);
            await deleteUserVehicle(vehicleToDelete.id);
        } catch (e) {
            showSnackBar({
                text: Strings.error,
                hideDismiss: true,
                style: { backgroundColor: Colours.RED }
            });
        } finally {
            setUpdating(false);
            mutateUser();
            mutateVehicles();
            mutateParkSession();
        }
    };

    const getHelperText = () => {
        if (showRemoveButton) {
            return Strings.tap_x_remove_vehicle;
        }
        if (selectSubscriptionVehicles && maxVehiclesPerSubscription) {
            return Strings.getSubscriptionVehiclesHelperText(maxVehiclesPerSubscription);
        }
        return Strings.your_vehicles;
    };

    const onCancel = () => {
        dispatch(clearSelectedVehicles());
        if (navigation.canGoBack()) {
            navigation.pop();
        } else {
            navigation.reset({ routes: [{ name: Routes.ParkableMapView }] });
        }
    };

    const getFooterButtons = () => {
        if (showRemoveButton) {
            return (
                <View style={styles.footerButtonsContainer}>
                    <Button
                        center
                        form
                        textProps={{ h3: true }}
                        style={styles.footerButton}
                        onPress={() => setShowRemoveButton(false)}>
                        {Strings.done}
                    </Button>
                </View>);
        }
        if (maxVehiclesPerSubscription && selectSubscriptionVehicles && subscription) {
            return (
                <View style={styles.footerButtonsContainer}>
                    <Button
                        border
                        center
                        plain
                        textProps={{ h5: true }}
                        style={styles.footerButton}
                        onPress={() => onCancel()}
                    >
                        {Strings.cancel}
                    </Button>
                    <Button
                        center
                        textProps={{ h5: true }}
                        style={styles.footerButton}
                        onPress={() => onSavePress()}
                    >
                        {Strings.save}
                    </Button>
                </View>
            );
        }
        return <View style={styles.footerButtonsContainer}>
            <Button
                border
                center
                plain
                textProps={{ h5: true }}
                style={styles.footerButton}
                onPress={goAddVehicle}
            >
                {Strings.add_new}
            </Button>
            {(filteredVehicles?.length ?? 0) > 1 && <Button
                border
                center
                plain
                textProps={{ h5: true }}
                style={styles.footerButton}
                onPress={() => setShowRemoveButton(true)}
            >
                {Strings.edit_vehicle}
            </Button>}
        </View>;
    };

    const renderContent = () => {
        if (isVehiclesLoading) {
            return <Spinner />;
        }
        if ((filteredVehicles?.length ?? 0) > 0) {
            return (
                <View style={styles.base}>
                    <Text h1>{Strings.select_vehicle}</Text>
                    <Text strapline bold style={styles.helperText}>{getHelperText()}</Text>
                    <FlatList
                        numColumns={2}
                        data={filteredVehicles}
                        style={styles.flatList}
                        showsVerticalScrollIndicator={false}
                        renderItem={({ item: vehicle }) => {
                            const isSelected = selectSubscriptionVehicles
                                ? selectedVehicleIds?.includes(vehicle.id) ?? false
                                : defaultVehicleId === vehicle.id;
                            return <Vehicle
                                vehicle={vehicle}
                                isSelected={isSelected}
                                selectVehicle={handleSelectVehicle}
                                removeVehicle={removeVehicle}
                                showRemoveButton={showRemoveButton}
                                disabled={showRemoveButton || (!selectSubscriptionVehicles && isSelected)}
                            />;
                        }} />
                    {getFooterButtons()}
                </View>
            );
        }
        return <FirstTimeSetup />;
    };

    return (
        <ParkableBaseView
            scrollable={false}
            onBackPress={() => onBackPress?.(defaultVehicleId)}
            loading={updating}
        >
            {renderContent()}

            <Dialog
                ref={dialogRef}
                label={Strings.delete_vehicle}
                labelProps={{ style: { color: Colours.BLACK, textAlign: "left" } }}
                title={Strings.delete_vehicle_question}
                titleProps={{ style: { textAlign: "left" }, h2: undefined }}
                positiveText={Strings.delete_vehicle}
                positiveProps={{ red: true, textProps: { h5: true } }}
                negativeText={Strings.cancel}
                negativeProps={{ textProps: { h5: true } }}
                onPositivePress={handleDeleteVehicle}
            />
        </ParkableBaseView>
    );
};

export const VehiclesRoute = createRoute({
    path: Routes.VehiclesView,
    params: { type: VehiclesViewParams }
});

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    helperText: {
        marginTop: 7,
    },
    flatList: {
        flex: 1,
        paddingTop: 17,
    },
    footerButtonsContainer: {
        flexDirection: "row",
        paddingTop: 28,
    },
    footerButton: {
        flex: 1,
        margin: 6,
    }
});

export default VehiclesView;
