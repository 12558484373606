import {
	PrechatEntitiy,
	startChat
} from "react-native-salesforce-chat-sdk";
import {UserDTO} from "../../api/user/dto/user.dto";
import {ParkSessionDTO} from "../../model/ParkSessionDTO";
import {EmployeeSubscription} from "../../model/EmployeeSubscription";
import {VehicleDTO} from "../../api/vehicle/dto/vehicleDTO";

const chatConfig = {
	liveAgentPod: 'd.la2-c1-ukb.salesforceliveagent.com',
	buttonId: '5735g00000053Zq',
	deploymentId: '5725g0000004zbC',
	orgId: '00D5g00000EHpUu',
}

const chatConfigurationFields = {
	recordTypeId_person: '0125g000000WmIEAA0', // PersonAccount
	recordTypeId_case: '0125g000000WmN8AAK', // Case Question
	recordType: 'Parker', // PersonAccount
	origin: 'App',
	ownerId: '00G5g000000fqW5EAI',
	isClosed: 'false',
}

type FormData = {
	initialValue?: string;
	autocapitalizationType?: number;
	autocorrectionType?: number;
	isRequired?: boolean;
	maxLength?: number;
	keyboardType?: number;
	label: string;
	fieldType: 'Object' | 'TextInput';
};

type FormWebData = {
	label: string,
	value: string,
	displayToAgent: boolean
}

const chatConfigWeb = {
	buttonId: '5735g00000053Zq',
	fallback: true
}

export const onStartChatWeb = async (user: UserDTO,
                                     vehicles: VehicleDTO[],
                                     organisationId?: number,
                                     bayGroupId?: number,
                                     parkSession?: ParkSessionDTO,
                                     subscription?: EmployeeSubscription) => {
	const userFields = getUserFields(user, vehicles, true) as FormWebData[];
	const configurationFields = getConfigurationFields(true) as FormWebData[];
	const entitySessionFields = getEntitySessionFields(true, organisationId, bayGroupId, parkSession, subscription) as FormWebData[];
	const caseEntityData = getCaseEntityData(!!parkSession || !!subscription);
	const accountEntityData = getAccountEntityData();
	
	// @ts-ignore
	window?.embedded_svc?.liveAgentAPI.startChat({
		directToButtonRouting: chatConfigWeb,
		extraPrechatInfo: [
			caseEntityData,
			accountEntityData
		],
		extraPrechatFormDetails: [
			...userFields,
			...configurationFields,
			...entitySessionFields,
		]
	});
}

export const onStartChat = async (user: UserDTO,
                                  vehicles: VehicleDTO[],
                                  organisationId?: number,
                                  bayGroupId?: number,
                                  parkSession?: ParkSessionDTO,
                                  subscription?: EmployeeSubscription) => {
	const userFields = getUserFields(user, vehicles, false) as FormData[];
	const configurationFields = getConfigurationFields(false)  as FormData[];
	const entitySessionFields = getEntitySessionFields(false, organisationId, bayGroupId, parkSession, subscription) as FormData[];
	const caseEntityData = getCaseEntityData(!!parkSession || !!subscription);
	const accountEntityData = getAccountEntityData();
	await startChat({
		chatConfig,
		displayConfig: {
			defaultToMinimized: false,
			allowMinimization: false,
			allowURLPreview: false,
			showPreChat: false,
			visitName: user.firstName??'',
		},
		backgroundConfig: {
			allowBackgroundExecution: true,
			allowBackgroundNotifications: true,
		},
		preChatDatas: [
			...userFields,
			...configurationFields,
			...entitySessionFields,
		],
		prechatEntitiesData: [
			caseEntityData,
			accountEntityData
		],
	});
};

const getUserFields = (user: UserDTO, vehicles: VehicleDTO[], isOnWebPage: boolean) => {
	return [
		getFieldValueUtil(user.id.toString(), 'CHAT_INT_ParkableParkerId__c', 50, isOnWebPage),
		getFieldValueUtil(user.id.toString(), 'Parkable_ID__c', 50, isOnWebPage),
		getFieldValueUtil(user.email, 'PersonEmail', 50, isOnWebPage),
		getFieldValueUtil(user.phone??'', 'Phone', 50, isOnWebPage),
		getFieldValueUtil(user.firstName??'', 'FirstName', 50, isOnWebPage),
		getFieldValueUtil(user.lastName??'', 'LastName', 50, isOnWebPage),
		getFieldValueUtil(`(${user.organisations?.map(o => o.organisation).join(",")??''})`, 'Organisations__c', 255, isOnWebPage),
		getFieldValueUtil(`(${vehicles.map(v => v.registration).join(",")})`, 'Vehicles__c', 255, isOnWebPage),
	]
}

const getConfigurationFields = (isOnWebPage: boolean) => {
	return [
		getFieldValueUtil(chatConfigurationFields.recordTypeId_person, 'RecordTypeId_person', 50, isOnWebPage),
		getFieldValueUtil(chatConfigurationFields.recordType, 'Type', 50, isOnWebPage),
		getFieldValueUtil(chatConfigurationFields.origin, 'Origin', 50, isOnWebPage),
		getFieldValueUtil(chatConfigurationFields.ownerId, 'OwnerId', 50, isOnWebPage),
		getFieldValueUtil(chatConfigurationFields.isClosed, 'Is_closed__c', 50, isOnWebPage),
		getFieldValueUtil(chatConfigurationFields.recordTypeId_case, 'RecordTypeId_case', 50, isOnWebPage),
	]
}

const getEntitySessionFields = (isOnWebPage: boolean,
                                organisationId?: number,
                                bayGroupId?: number,
                                parkSession?: ParkSessionDTO,
                                subscription?: EmployeeSubscription) => {
	if(!parkSession && !subscription){
		return [];
	}
	
	const sessionType = !!parkSession ? 'Casual' : 'Subscription';
	const entityId = (parkSession?.id??subscription?.id)!;
	const parkId = (parkSession?.park??subscription?.park)!;
	const bayId = (parkSession?.bay??subscription?.bay)!;
	
	return [
		getFieldValueUtil(sessionType, 'CHAT_INT_Session_type__c', 50, isOnWebPage),
		getFieldValueUtil(entityId.toString(), 'CHAT_INT_ParkableEntityId__c', 50, isOnWebPage),
		getFieldValueUtil(organisationId!.toString(), 'CHAT_INT_ParkableOrgId__c', 50, isOnWebPage),
		getFieldValueUtil(parkId.toString(), 'CHAT_INT_ParkableParkId__c', 50, isOnWebPage),
		getFieldValueUtil(bayGroupId!.toString(), 'CHAT_INT_ParkableGroupId__c', 50, isOnWebPage),
		getFieldValueUtil(bayId.toString(), 'CHAT_INT_ParkableBayId__c', 50, isOnWebPage),
	]
}

const getFieldValueUtil = (value: string, label: string, maxLength: number, isOnWebPage: boolean) => (isOnWebPage ?
	{
		label,
		value,
		displayToAgent: false
	} :
	{
		initialValue: value,
		label: label,
		autocapitalizationType: 0, autocorrectionType: 0, isRequired: true,	keyboardType: 0, maxLength: maxLength, fieldType: 'Object',
	}
);

const getCaseEntityData = (hasSession: boolean) => {
	const sessionFields = !hasSession ? [] : [
		{
			doCreate: true,
			doFind: true,
			fieldName: 'CHAT_INT_Session_type__c',
			isExactMatch: true,
			label: 'CHAT_INT_Session_type__c',
		},
		{
			doCreate: true,
			doFind: true,
			fieldName: 'CHAT_INT_ParkableEntityId__c',
			isExactMatch: true,
			label: 'CHAT_INT_ParkableEntityId__c',
		},
		{
			doCreate: true,
			doFind: true,
			fieldName: 'CHAT_INT_ParkableOrgId__c',
			isExactMatch: true,
			label: 'CHAT_INT_ParkableOrgId__c',
		},
		{
			doCreate: true,
			doFind: true,
			fieldName: 'CHAT_INT_ParkableParkId__c',
			isExactMatch: true,
			label: 'CHAT_INT_ParkableParkId__c',
		},
		{
			doCreate: true,
			doFind: true,
			fieldName: 'CHAT_INT_ParkableGroupId__c',
			isExactMatch: true,
			label: 'CHAT_INT_ParkableGroupId__c',
		},
		{
			doCreate: true,
			doFind: true,
			fieldName: 'CHAT_INT_ParkableBayId__c',
			isExactMatch: true,
			label: 'CHAT_INT_ParkableBayId__c',
		}
	]
	
	return {
		entityFieldMaps: [
			{
				doCreate: false,
				doFind: true,
				fieldName: 'Is_closed__c',
				isExactMatch: true,
				label: 'Is_closed__c',
			},
			{
				doCreate: true,
				doFind: true,
				fieldName: 'RecordTypeId',
				isExactMatch: true,
				label: 'RecordTypeId_case',
			},
			{
				doCreate: true,
				doFind: true,
				fieldName: 'Origin',
				isExactMatch: true,
				label: 'Origin',
			},
			{
				doCreate: true,
				doFind: true,
				fieldName: 'OwnerId',
				isExactMatch: true,
				label: 'OwnerId',
			},
			{
				doCreate: true,
				doFind: true,
				fieldName: 'CHAT_INT_ParkableParkerId__c',
				isExactMatch: true,
				label: 'CHAT_INT_ParkableParkerId__c',
			},
			...sessionFields
		],
		entityName: 'Case',
		linkToEntityField: 'CaseId',
		linkToEntityName: 'Case',
		saveToTranscript: 'CaseId',
	} as PrechatEntitiy;
}

const getAccountEntityData = () => {
	return {
		entityFieldMaps: [
			{
				doCreate: true, doFind: true, isExactMatch: true,
				fieldName: 'RecordTypeId', label: 'RecordTypeId_person',
			},
			{
				doCreate: true, doFind: true, isExactMatch: true,
				fieldName: 'PersonEmail', label: 'PersonEmail',
			},
			{
				doCreate: true, doFind: false, isExactMatch: false,
				fieldName: 'Phone', label: 'Phone',
			},
			{
				doCreate: true, doFind: false, isExactMatch: false,
				fieldName: 'FirstName', label: 'FirstName',
			},
			{
				doCreate: true, doFind: false, isExactMatch: false,
				fieldName: 'LastName', label: 'LastName',
			},
			{
				doCreate: true, doFind: false, isExactMatch: false,
				fieldName: 'Organisations__c', label: 'Organisations__c',
			},
			{
				doCreate: true, doFind: false, isExactMatch: false,
				fieldName: 'Type', label: 'Type',
			},
			{
				doCreate: true, doFind: false, isExactMatch: false,
				fieldName: 'Vehicles__c', label: 'Vehicles__c',
			},
			{
				doCreate: true, doFind: true, isExactMatch: true,
				fieldName: 'Parkable_ID__c', label: 'Parkable_ID__c',
			},
		],
		entityName: 'Account',
		linkToEntityField: 'AccountId',
		linkToEntityName: 'Case',
		saveToTranscript: 'AccountId',
	} as PrechatEntitiy;
}
