import React, { useMemo } from "react";
import moment, {Moment} from "moment";
import Select from "react/parkable-components/select/Select";
import Colours from "react/parkable-components/styles/Colours";
import {StyleSheet, View} from "react-native";
import Strings from "../../constants/localization/localization";
import {isAvailableOnDay} from "../../constants/availability/isAvailableOnDay";
import {ParkDTO} from "../../model/ParkDTO";

type EditSubscriptionStartDateViewProps = {
    park: ParkDTO;
    onSelectNewDate: (date: Moment) => void;
};

export default function EditSubscriptionStartDateView (props: EditSubscriptionStartDateViewProps) {

    const {park, onSelectNewDate} = props;

    const dateFormat = 'dddd, MMMM Do';
    const today = moment()
    const todayObject = {label: today.format(dateFormat), value: today.toDate().getTime()}
    const data = [...[todayObject]]

    const items = useMemo(() =>{
        let startDate = today.add(1, 'day');

        for(let i=1; i <= (park.subscriptionMaxDaysInAdvance || 0); i++){

            let dateString = startDate.format(dateFormat);
            // @ts-ignore
            if(!isAvailableOnDay(park.availability, startDate.format('ddd'))){
                dateString += " (" + Strings.park_closed + ")";
            }

            const epoch = startDate.toDate().getTime();
            data.push({label: dateString, value: epoch});
            startDate = startDate.add(1, 'day');
        }

        return data;
    }, [park]);

    const onValueChange = (epoch: string) => {
        onSelectNewDate(moment(parseInt(epoch)));
    }

    return (
        <View style={styles.container}>
            <Select
                label={Strings.Select_Start_Date}
                items={items}
                onValueChange={onValueChange}
            />
        </View>
    );

};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor:Colours.WHITE,
        borderRadius: 5,
        paddingTop: 9
    },
});
