import { EmployeeSubscription } from "../model/EmployeeSubscription";
import Constants from "./constants";

export type ActiveSubscriptions = Array<EmployeeSubscription>;

export function getActiveSubscriptions(employeeSubscriptionMap: {[subId:number]: EmployeeSubscription}): ActiveSubscriptions {
    let activeSubscriptions: ActiveSubscriptions = [];
    for (let key in employeeSubscriptionMap) {
        const employeeSubscriptions = employeeSubscriptionMap[key];
        if(Constants.Active_Subscription_Status_Array.includes(employeeSubscriptions.status)) {
            activeSubscriptions.push(employeeSubscriptions)
        }
    }
    return activeSubscriptions;
}

export function getSubscriptionInPark(subscriptions: EmployeeSubscription[], parkId: number): EmployeeSubscription|undefined {
    return subscriptions
            .find(s => !Constants.Ended_Subscription_Status_Array.includes(s.status) && s.park === parkId);
}