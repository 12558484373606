/**
 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/09/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React, {FC} from "react";
import {StyleSheet, View} from "react-native";
import moment from "moment";
import SiteAvailabilityTileDay from "./SiteAvailabilityTileDay";
import SiteAvailabilityTileDayEmpty from "./SiteAvailabilityTileDayEmpty";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import DayOfWeekHeader from "./DayOfWeekHeader";
import getDayAvailability from "../../constants/availability/getDayAvailability";
import Dashes from "../common/Dashes";
import {Availability} from "../../model/Availability";
import { useUnshareBayContext } from "../parkingViews/Subscriptions/context";

interface SiteAvailabilityTileMonthProps {
    month: number,
    year: number,
    selectedDays: undefined | string[], //formatted 'YYYY-MM-DD'
    onDayTilePress: (key: string, day: moment.Moment) => void,
    parkAvailability: Availability,
    bookedDays: string[]
}

const SiteAvailabilityTileMonth: FC<SiteAvailabilityTileMonthProps> = (props) => {


    const today = moment();
    const yesterday = today.subtract(1, 'days');

    const month = props.month;
    const year = props.year;

    const thisMonth = moment({month: month, year: year});

    const daysInMonth = thisMonth.daysInMonth();
    const startDayOfWeek = thisMonth.day();
    const formattedMonthYear = thisMonth.clone().format('MMMM YYYY');

    // shouldComponentUpdate(props, state) {
    //     const selectedDaysLength = Object.keys(props.selectedDaysObject).length;
    //     if (selectedDaysLength !== selectedDaysLength) {
    //         selectedDaysLength = selectedDaysLength;
    //         return true;
    //     }
    //
    //     return false;
    // }

    const renderDayTile = (day: number, month: number, year: number) => {

        const key = day + '_' + month + '_' + year;
        const dayHere = moment({day: day, month: month, year: year});
        const dayGone = !(dayHere.isAfter(yesterday));

        const context = useUnshareBayContext()

        const currentDay = dayHere.clone().format("YYYY-MM-DD")
        const isSelectedDay = !!props.selectedDays && props.selectedDays.includes(currentDay)
        const isBookedDay = !!props.bookedDays && props.bookedDays.includes(currentDay);
        const isActivesession = () => {
            if(context?.activeSession) {
                return moment(context.activeSession.startedAt).format("YYYY-MM-DD") === currentDay
            } return false
        }

        const dayAvailability = getDayAvailability(dayHere.day(), props.parkAvailability, dayHere.day());

        return (
            <SiteAvailabilityTileDay key={key}
                                     dayKey={key}
                                     day={day}
                                     dayGone={dayGone}
                                     dayHere={dayHere}
                                     available={dayAvailability.available}
                                     isSelectedDay={isSelectedDay}
                                     onDayTilePress={props.onDayTilePress}
                                     isBookedDay={isBookedDay}
                                     isActivesession={isActivesession}
                                     />
        );
    }


    const renderEmptyDayTile = (day: number) => {
        return (
            <SiteAvailabilityTileDayEmpty key={day}/>
        );
    }

    let dayTiles: any[] = [];
    for (let i = 0; i < startDayOfWeek; i++) {
        dayTiles.push(renderEmptyDayTile(i));
    }

    for (let i = 0; i < daysInMonth; i++) {
        dayTiles.push(renderDayTile((i) + 1, thisMonth.month(), year));
    }


    let newDayTiles = [];
    for (let i = -1; i < dayTiles.length; i += 7) {
        newDayTiles.push(
            <View style={{flexDirection: 'row'}} key={i}>
                {[1,2,3,4,5,6,7].map(j => !!dayTiles[i + j] ? dayTiles[i + j] : <View key={j} style={{flex: 1}}/>)}
            </View>
        );
    }


    return (
        <View key={year + '_' + month} style={{paddingHorizontal: 0, paddingVertical: 15, width: '100%', maxWidth: '100%'}}>
            <Text strapline bold grey style={styles.monthTileText}>{formattedMonthYear}</Text>
            <DayOfWeekHeader parkAvailability={props.parkAvailability}/>
            {newDayTiles}
            <View style={{paddingTop:18}}>
                <Dashes width={"100%"} height={1} colour={Colours.GREY_BORDER} backgroundColour={Colours.WHITE}/>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    monthTileText: {
        paddingBottom: 18,
        paddingLeft:12,
    },
});

export default SiteAvailabilityTileMonth;
