import {del, get, post, put, Token} from './rest'
import { del as httpDel } from "./http/rest";
import {Platform} from "react-native";
import {RetrieveUserRolesResponse, UserDTO, UserDTOResponse} from "./user/dto/user.dto";
import {appVersionString} from "../version";
import {browserName, browserVersion} from "react-device-detect";

export function platform(){
    if(Platform.OS === "web") {
        return browserName + " v" + browserVersion
    }
    return Platform.OS + " v" + Platform.Version;
}

export type LoadUserResult = {
    user: UserDTO
}

export type User2Response = {
    user: UserDTO
}

const V1 = "v1";
const V2 = "v2";
//const V3 = "v3";
const HOSTS_API = "/hosts";

export const getInvoiceToken = (url: string, token: Token) => get(token, `${url}v1/users/invoiceToken`);

export const updateUserVehicleAPI = (url: string, token: Token, userId: number, vehicleId: number) =>
    updateUserAPI(url, token, userId, null, null, null, null, null, vehicleId, null, null);

export const updateUserAPI = (url: string, token: Token, userId: number,
                              email: string | null, firstName: string | null,
                              lastName: string | null, phone: string | null,
                              countryCode: string | null, vehicleId: number | null,
                              dateFormat: string | null, companyDetails: string | null) => {
    return put(token, `${url}v2/users/${userId}`, {
        email: email ? email.trim().toLowerCase() : undefined,
        firstName,
        lastName,
        phone,
        countryCode,
        vehicleId,
        dateFormat,
        companyDetails
    }) as Promise<User2Response>;
}

export function loadParkableUser(token: Token, api: string, pushToken?: string|null) : Promise<LoadUserResult> {
    const url = api + "v4/users";

    const data = {
        messagingToken: pushToken,
        source: "react-native",
        platform: platform(),
        version: appVersionString(),
    };

    return put(token, url, data) as Promise<LoadUserResult>;
}

export const getUserCardsAPI = (url: string, token: Token) => get(token, `${url}v3/stripe`);

export const addUserCardAPI = (url: string, token: Token, stripeToken: string) =>
    post(token, `${url}v2/stripe`, {
        token: stripeToken,
    });

export const addUserActionAPI = (url: string, token: Token, action: any, comments?: any) =>
    post(token, `${url}v2/useraction`, {
        action,
        comments
    });

export const setDefaultCardAPI = (url: string, token: Token, stripeToken: string) => put(token, `${url}v2/stripe/${stripeToken}`);

export const deleteUserCardAPI = (url: string, token: Token, stripeToken: string) => del(token, `${url}v1/stripe/${stripeToken}`);

export const getUserVehiclesAPI = (url: string, token: Token) => get(token, `${url}v1/vehicles`);

export const addUserVehicleAPI = (url: string, token: Token, ownerId: number, registration: string, feature: any) => post(token, `${url}v1/vehicles`, {ownerId, registration, feature});

export const deleteVehicleAPI = (url: string, token: Token, vehicleId: number) => del(token, `${url}v1/vehicles/${vehicleId}`);

export const deleteInstallationIdAPI = (url: string, token: Token, installationId: number) => del(token, `${url}v1/installations/${installationId}`);

export const getUserHostDataAPI = (url: string, token: Token) => get(token, `${url}v1/hosts/user`);

export const createHostDataAPI = (url: string, token: Token, data: any) => post(token, `${url}${V1}${HOSTS_API}`, data);

export const updateHostDataAPI = (url: string, token: Token, hostId: number, data: any) => put(token, `${url}${V1}${HOSTS_API}/${hostId}`, data);

export const getSubscriptionCancellationNotes = (url: string, token: Token) => get(token, `${url}v1/users/subscriptionCancellationNotes`);

export const retrieveUserRoles  = (url: string, token: Token) => get(token, `${url}v4/users/roles`) as Promise<RetrieveUserRolesResponse>;

export const deleteUserAccountAPI  = (userId: number | undefined) => httpDel( `v4/users/${userId}`);

export const getUserWithTempToken = (tempToken: string) => get({firebaseToken: tempToken, parkableToken: null}, `v4/users/withTempToken`) as Promise<UserDTOResponse>;

export const sendMagicLink = (url: string, emailAddress: string, callbackUrl?: string) : Promise<void> => {
    return put(undefined, `${url}users/magicLink`, {emailAddress, callbackUrl});
}
