import React from "react";
import Strings from '../../constants/localization/localization';
import Colours from "react/parkable-components/styles/Colours";
import { TableRowProps } from "react/parkable-components/tableRow/TableRow";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Text from "react/parkable-components/text/Text";
import { Routes } from "react/navigation/root/root.paths";
import {View, StyleSheet, TextProps, ViewStyle} from 'react-native';
import {useNavigation} from "../../navigation/constants";


export default function SubscriptionCreateVehiclePrompt() {
    const navigation = useNavigation();
    const handlePress = () => navigation.push(Routes.AddNewVehicleView, {});

    const BangIcon = () => <View style={styles.icon}>
        <Text {...staticProps.iconText}>{Strings.exclamation_mark}</Text>
    </View>

    return <TableRow
      {...staticProps.tableRow}
      contentLeft={<BangIcon/>}
      onButtonPress={handlePress}
      buttonText={Strings.enter_now}
      buttonProps={{
          red: true,
          textProps: { style: { textAlign: "center", color: Colours.WHITE, fontSize: 15 } },
      }}
    >
        {Strings.enter_vehicle_details}
    </TableRow>
}

const staticProps = {
    tableRow: {
        buttonText: Strings.enter_now,
        textProps: {
            bold: true
        },
        hideBorder: true,
        backgroundColor: Colours.RED_LIGHT,
        pl: 9,
        pr: 9,
        buttonProps: {
            textProps: {
                small:true
            },
            style:{
                width: "auto",
                backgroundColor: Colours.RED,
            }
        }
    } as TableRowProps,
    iconText: {
        bold: true,
        style: {
            color: Colours.WHITE
        }
    } as TextProps
}

const styles = StyleSheet.create({
    icon: {
        width: 36,
        height: 36,
        alignItems: "center",
        borderRadius: 1000,
        justifyContent: "center",
        backgroundColor: Colours.RED,
    } as ViewStyle
})
