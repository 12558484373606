import React, {useState} from 'react';
import {StyleSheet, Text as TextComponent, View, Platform} from 'react-native';
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import Button from "react/parkable-components/button/Button";
import Strings from "../../constants/localization/localization";
import {LinearGradient} from "expo-linear-gradient";

type AllProps = {
    title?: string,
    text: string|null|undefined,
    style?: any,
    showHeading: boolean,
    showCollapsed: boolean,
    descriptionTextProps?: any,
    summaryView?:boolean,
}

export default function ParkInstructions(props:AllProps) {
    const { title, text, style, showHeading, descriptionTextProps, showCollapsed = false, summaryView } = props;
    const [collapsed, setCollapsed] = useState<boolean>(showCollapsed);
    const onCollapse = () => setCollapsed(!collapsed);
    if(!text){
        return <></>;
    }
    return (<View style={{...(style||{})}}>
            {showHeading && <Text {...(descriptionTextProps||{})} >{title}</Text>}
            <TextComponent
                numberOfLines={collapsed ? 6: 0}
                style={styles.textComponent}>{text}
                </TextComponent>
            {collapsed && summaryView && <LinearGradient colors={['rgba(255,255,255,0)', Colours.WHITE]} style={styles.linearGradient}/>}
            {collapsed && <Button style={{height:18, marginLeft:-18}} textProps={{h5:true}} plain onPress={onCollapse}>{Strings.more}</Button>}
        </View>
    );
};

const styles = StyleSheet.create({
    textComponent: {
        fontFamily: "GTEestiDisplay-Regular",
        color: Colours.BLACK,
        fontSize: 15,
        lineHeight: 18,
        zIndex: 1,
    },
    linearGradient: {
        zIndex: 100,
        height: 27,
        left: 0,
        right: 0,
        bottom: 18, // button hights
        position: "absolute",
        backgroundColor: Colours.TRANSPARENT,
    },
});
