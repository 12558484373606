import React, {useCallback, useRef} from "react";
import {Routes} from "../../navigation/root/root.paths";
import {FlatList, StyleSheet, TouchableOpacity, View} from "react-native";
import {createRoute, NavigationProps, useNavigation} from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import Icons from "react/parkable-components/icon/Icons";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "../../constants/localization/localization";
import {onStartChat, onStartChatWeb} from "../../components/support/constants";
import {useSupportCases} from "../../api/support/support.api";
import {SupportCase} from "../../api/support/dto/SupportCase.dto";
import {useCurrentUser} from "../../api/user/user.api";
import {useUserVehicles} from "../../api/vehicle/vehicle.api";
import moment from "moment";
import * as Device from "expo-device";
import {Platform} from "react-native";

class SupportViewParams {}

const SupportView = (props: NavigationProps<Routes.SupportView>) => {
    const { } = props.route.params ?? {};
    const flatListRef = useRef(null);
    const { cases, isLoading } = useSupportCases();
    const {user} = useCurrentUser();
    const { vehicles } = useUserVehicles();
    const messageDateFormat = "D/M/YY";
    const navigation = useNavigation();

    const renderMessageRow = ({ item: supportCase }: { item: SupportCase }) => {
        return <TouchableOpacity key={supportCase.id} onPress={() => {
            navigation.push(Routes.CaseDetailsView, {
                caseId: supportCase.id,
            });
        }}>
            <TableRow
                label={Strings.case_number(supportCase.caseNumber)}
                chevronText={moment(supportCase.createdDate).format(messageDateFormat)}
                textProps={{ numberOfLines: 1, ellipsizeMode: "tail" }}
                chevron
            >
                {supportCase.subject}
            </TableRow>
        </TouchableOpacity>
    }

    const onSupportPress = useCallback(() => {
        if(user && vehicles) {
            if (!Device.isDevice || Platform.OS === "web") {
                void onStartChatWeb(user, vehicles, undefined, undefined, undefined, undefined);
            } else {
                void onStartChat(user, vehicles, undefined, undefined, undefined, undefined);
            }
        }
    }, [user, vehicles])

    return (
        <ParkableBaseView scrollable={false} toolbarIcon={Icons.addIcon} onIconClick={onSupportPress}>
            <View style={styles.container}>
                <View>
                    <Text h1 bold>{Strings.support}</Text>
                </View>
                <View>
                    {(isLoading) ? <Spinner /> :
                        ((cases?.length??0) <= 0
                            ? <Text>{Strings.no_messages_found}</Text>
                            : <FlatList
                                ref={flatListRef}
                                data={cases}
                                renderItem={renderMessageRow}
                                keyExtractor={(item, index) => `${item?.id}_${index}`}
                            />)}
                </View>
            </View>
        </ParkableBaseView>
    );
};

export default SupportView;

export const SupportRoute = createRoute({
    path: Routes.SupportView,
    params: {
        type: SupportViewParams
    }
})

const styles = StyleSheet.create({
    footer: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        bottom: 200
    },
    container: {
        backgroundColor: Colours.WHITE,
    },
    row: {
        flexDirection: 'row',
        marginTop: 10,
        marginBottom: 10
    }
});
