import {RootStackParamList} from "./root.routes";
import * as React from "react";
import {Routes} from "./root.paths";
import LandingView from "../../components/landing/LandingView";
import ParkDetailView from "../../components/parkDetails/ParkDetailView_new";
import LoginOrSignupView from "../../components/landing/LoginOrSignupView";
import ParkableMapViewRoot from "../../components/map/parkableMapView/ParkableMapView_root";
import ChangeServerView from "../../components/landing/ChangeServerView";
import FindParkView from "../../components/search/FindParkView";
import SupportView from "../../components/support/SupportView";
import CaseDetailsView from "../../components/support/CaseDetailsView";
import ForgotPasswordView from "../../components/landing/ForgotPasswordView";
import ConfirmStartParkingView from "../../components/parkDetails/confirmStartParking/ConfirmStartParkingView";
import ActiveSessionView from "../../components/parkingViews/ActiveSessionView_new";
import AccountView from "../../components/accountView/AccountView";
import PreferredBaysView from "../../components/preferredBays/PreferredBaysView";
import SelectParkView from "../../components/preferredBays/SelectParkView";
import PricingView from "../../components/pricing/PricingView";
import MapSettingsView from "../../components/accountView/userDetails/MapSettingsView";
import MyDetailsView from "../../components/accountView/userDetails/MyDetailsView";
import SessionHistoryView from "../../components/parkingViews/parking-history/SessionHistoryView";
import {BookingsAndRequestsView} from "../../components/parkingViews/bookings-and-requests/bookings-and-requests.view";
import ListSubscriptionView from "../../components/parkingViews/Subscriptions/ListSubscriptionView";
import AddNewVehicleView from "../../components/accountView/userDetails/AddNewVehicleView_new";
import VehiclesView from "../../components/accountView/userDetails/VehiclesView_new";
import CreateAccountView from "../../components/landing/CreateAccountView";
import SamlLoginView from "../../components/landing/SamlLoginView";
import MessagesView from "../../components/messages/MessagesView";
import VouchersView from "../../components/vouchers/VouchersView";
import SessionSummary from "../../components/parkDetails/sessionSummary/SessionSummary";
import NotificationSettingsView from "../../components/accountView/userDetails/NotificationSettingsView";
import { RfidCardsView } from "../../components/accountView/userDetails/rfid-cards-view/RfidCardsView";
import CreditCards from "../../components/accountView/userDetails/CreditCards";
import SelectBay from "../../components/common/SelectBay";
import ParkAvailabilityView from "../../components/parkDetails/ParkAvailabilityView";
import VoucherDetail from "../../components/common/voucher/VoucherDetail";
import AddVoucherCode from "../../components/common/voucher/AddVoucherCode";
import SessionHistoryDetailView from "../../components/parkingViews/parking-history/SessionHistoryDetailView";
import SelectSubscriptionPlanView from "../../components/subscriptions/SelectSubscriptionPlanView";
import ParkTomorrowView from "../../components/parkRequest/ParkTomorrowView";
import ConfirmStartReservationView from "../../components/parkDetails/confirmStartReservation/ConfirmStartReservationView";
import StartSubscriptionSummaryView from "../../components/subscriptions/StartSubscriptionSummaryView";
import AcceptTsAndCsView from "../../components/landing/AcceptTsAndCsView";
import PrivacySettingsView from "../../components/accountView/userDetails/PrivacySettingsView";
import ChangePasswordView from "../../components/accountView/userDetails/ChangePasswordView";
import AddNewCard from "../../components/accountView/userDetails/AddNewCard";
import ProblemView from "../../components/problem/ProblemView";
import SubscriptionCancelView from "../../components/parkingViews/Subscriptions/SubscriptionCancelView";
import {FutureBookingView} from "../../components/future-booking/future-booking.view";
import {HowItWorks_UpcomingWeeksView} from "../../components/future-booking/how-it-works/upcoming-weeks.view";
import {HowItWorks_ThisWeekView} from "../../components/future-booking/how-it-works/this-week.view";
import SubscriptionDetailsView from "../../components/subscriptions/SubscriptionDetailsView";
import PreferredBaysSelectionView from "../../components/preferredBays/PreferredBaysSelectionView";
import VerifyEmailView from "../../components/landing/VerifyEmailView";
import SingleSubscriptionView from "../../components/parkingViews/Subscriptions/SingleSubscriptionView";
import StartChargingByLinkView from "../../components/parkDetails/StartChargingByLinkView";
import SubscriptionInvoicesView from "../../components/subscriptions/subscriptionListItems/SubscriptionInvoicesView";
import { ConfirmedBookingView } from "../../components/future-booking/confirmedBooking/confirmedBookingView";
import { SubscriptionSharingCreditView } from "../../components/subscriptions/SubscriptionSharingCreditView";
import ChatDetailsView from "../../components/tandemChat/ChatDetailsView";
import CampusScreenView from "../../components/parkDetails/CampusScreenView";
import ConnectEVNozzle from "../../components/ev/ConnectEVNozzle";
import TandemChatView from "../../components/tandemChat/TandemChatView";
import ConfirmStartTandemParkingView from "../../components/tandemParking/ConfirmStartTandemParkingView";
import MagicLinkView from "../../components/landing/MagicLinkView";
import { MyRewardsView } from "../../components/rewards/myRewardsView";
import RetryPayment from "../../components/parkingViews/RetryPayment";
import QrScannerRoot from "../../components/common/qrscanner/QrScannerRoot";
import ScanSensorQrCodeRoot from "react/components/admin/ScanSensorQrCodeRoot";
import AssignSensorQrCodeRoot from "react/components/admin/AssignSensorQrCodeRoot";
import {HowItWorks_Rewards} from "../../components/rewards/how-it-works.view";
import AssignSensorBayList from "../../components/admin/AssignSensorBayList";
import AssignQRForBay from "../../components/admin/AssignQRForBay";
import AdminLoginAsUserView from "../../components/admin/AdminLoginAsUserView";
import WarningOpenSessionView from "../../components/parkingViews/WarningOpenSessionView";
import AutoEndSessionView from "../../components/parkingViews/AutoEndSessionView";
import UpdateCheckView from "../../components/landing/UpdateCheckView";
import TeamsLandingPage from "../../components/teams/TeamsLandingPage";
import TeamsAuthStart from "../../components/teams/TeamsAuthStart";
import TeamsAuthEnd from "../../components/teams/TeamsAuthEnd";
import SharingPoolView from "react/components/parkDetails/sharing-pool/SharingPoolView";

export type RouteComponents = {
	[Property in keyof RootStackParamList]: React.ComponentType<any>;
}

const routeComponents : RouteComponents = {
	[Routes.LandingView]: LandingView,
	[Routes.ParkDetailView]: ParkDetailView,
	[Routes.CampusScreenView]: CampusScreenView,
	[Routes.ConnectEVNozzle]: ConnectEVNozzle,
	[Routes.LoginOrSignUpView]: LoginOrSignupView,
	[Routes.SubscriptionDetailsView]: SubscriptionDetailsView,
	[Routes.VerifyEmailView]: VerifyEmailView,
	[Routes.MagicLinkView]: MagicLinkView,
	[Routes.ParkableMapView]: ParkableMapViewRoot,
	[Routes.ChangeServerView]: ChangeServerView,
	[Routes.UpdateCheckView]: UpdateCheckView,
	[Routes.FindParkView]: FindParkView,
	[Routes.SupportView]: SupportView,
	[Routes.CaseDetailsView]: CaseDetailsView,
	[Routes.MyDetailsView]: MyDetailsView,
	[Routes.CreateAccountView]: CreateAccountView,
	[Routes.ConfirmedBookingView]: ConfirmedBookingView,
	[Routes.StartSubscriptionSummaryView]: StartSubscriptionSummaryView,
	[Routes.ConfirmStartParkingView]: ConfirmStartParkingView,
	[Routes.PreferredBaysView]: PreferredBaysView,
	[Routes.PreferredBaysSelectionView]: PreferredBaysSelectionView,
	[Routes.SelectParkView]: SelectParkView,
	[Routes.PricingView]: PricingView,
	[Routes.ActiveSession]: ActiveSessionView,
	[Routes.ChangePasswordView]: ChangePasswordView,
	[Routes.ForgotPasswordView]: ForgotPasswordView,
	[Routes.AccountView]: AccountView,
	[Routes.SamlLoginView]: SamlLoginView,
	[Routes.SingleSubscriptionView]: SingleSubscriptionView,
	[Routes.MapSettingsView]: MapSettingsView,
	[Routes.SubscriptionCancelView]: SubscriptionCancelView,
	[Routes.AcceptTsAndCsView]: AcceptTsAndCsView,
	[Routes.SessionHistoryView]: SessionHistoryView,
	[Routes.UserBookingsAndRequestsView]: BookingsAndRequestsView,
	[Routes.SubscriptionListView]: ListSubscriptionView,
	[Routes.SubscriptionInvoicesView]: SubscriptionInvoicesView,
	[Routes.SubscriptionSharingCreditView]: SubscriptionSharingCreditView,
	[Routes.ConfirmStartTandemParkingView]: ConfirmStartTandemParkingView,
	[Routes.AddNewVehicleView]: AddNewVehicleView,
	[Routes.VehiclesView]: VehiclesView,
	[Routes.MessagesView]: MessagesView,
	[Routes.SelectSubscriptionPlanView]: SelectSubscriptionPlanView,
	[Routes.ParkTomorrowView]: ParkTomorrowView,
	[Routes.ConfirmStartReservationView]: ConfirmStartReservationView,
	[Routes.VouchersView]: VouchersView,
	[Routes.ChatDetailsView]: ChatDetailsView,
	[Routes.TandemChatView]: TandemChatView,
	[Routes.SessionSummary]: SessionSummary,
	[Routes.FutureBookingView]: FutureBookingView,
	[Routes.Problem]: ProblemView,
	[Routes.QrScannerView]: QrScannerRoot,
	[Routes.HowItWorks_ThisWeekView]: HowItWorks_ThisWeekView,
	[Routes.HowItWorks_UpcomingWeeksView]: HowItWorks_UpcomingWeeksView,
    [Routes.NotificationSettingsView]: NotificationSettingsView,
	[Routes.RfidCardsView]: RfidCardsView,
	[Routes.AssignQRForBay]: AssignQRForBay,
	[Routes.AssignSensorBayList]: AssignSensorBayList,
	[Routes.AssignSensorQrCodeView]: AssignSensorQrCodeRoot,
	[Routes.ScanSensorQrCodeView]: ScanSensorQrCodeRoot,
    [Routes.CreditCardView]: CreditCards,
    [Routes.AddNewCard]: AddNewCard,
	[Routes.RetryPaymentRequest]: RetryPayment,
	[Routes.SelectBay]: SelectBay,
	[Routes.ParkAvailabilityView]: ParkAvailabilityView,
	[Routes.SharingPoolView]: SharingPoolView,
	[Routes.VoucherDetail]: VoucherDetail,
	[Routes.AddVoucherCode]: AddVoucherCode,
	[Routes.StartChargingByLinkView]: StartChargingByLinkView,
    [Routes.PrivacySettingsView]: PrivacySettingsView,
	[Routes.SessionHistoryDetailView]: SessionHistoryDetailView,
	[Routes.WarningOpenSessionView]: WarningOpenSessionView,
	[Routes.AutoEndSessionView]: AutoEndSessionView,
    [Routes.MyRewardsView]: MyRewardsView,
	[Routes.HowItWorks_Rewards]: HowItWorks_Rewards,
	[Routes.AdminLoginAsUserView]: AdminLoginAsUserView,
	[Routes.TeamsLandingPage]: TeamsLandingPage,
	[Routes.TeamsAuthStart]: TeamsAuthStart,
	[Routes.TeamsAuthEnd]: TeamsAuthEnd,
}

export default routeComponents;
