import Strings from "../../../constants/localization/localization";
import {EmployeeSubscription} from "../../../model/EmployeeSubscription";
import { ParkingRequest, ParkingRequestStatus } from "../../../model/ParkingRequest";
import Colours from "react/parkable-components/styles/Colours";
import {FailedPaymentInfo} from "../../../model/FailedPaymentInfo";
import {ParkSessionDTOLocal} from "../../../model/ParkSessionDTO";
import {ParkAvailabilityNotification} from "../../../model/ParkAvailabilityNotification";
import { logEvent } from "react/analytics";
import moment from 'moment';
import { Nully } from "../../../constants/nully";
import { ParkDTO } from "../../../model/ParkDTO";
import {Platform} from "react-native";

export interface MultiData {
    id: number,
    text: string
}

export interface INotification {
    key: number | string,
    eventName?: string,
    actionText: string,
    mainText: string,
    color: Colours,
    action: (id?:number) => void,
    mainTextMultiple?: string,
    data?: MultiData[]
}

export type NotificationBuilderProps = {
    currentSession: Nully<ParkSessionDTOLocal>,
    previousPark: ParkDTO | undefined;
    parkingRequest: Nully<ParkingRequest>,
    employeeSubscriptions: EmployeeSubscription[],
    showCurrentSession: () => void,
    showParkingRequestView: (pr: ParkingRequest) => void,
    showEmployeeSubscription: (id: number) => void,
    showSubscriptionListView: () => void,
    errorGetGPSLocation: boolean,
    setErrorGetGPSLocation: (value: boolean) => void,
    getCurrentPosition: () => void,
    showEmployeeSubscriptionInvitation: (employeeSubscription: EmployeeSubscription) => void,
    isSessionGoingUnavailable: boolean,
    isSessionWentUnavailable: boolean,
    showEndedSession: () => void,
    sessionBayChangedTo: string | undefined,
    removeSessionBayChangeNotification: () => void,
    failedPayments: FailedPaymentInfo[],
    showRetryPaymentRequest: (sessionId: number) => void,
    availabilityNotifications: {[parkId:number]: ParkAvailabilityNotification},
    showParkDetailView: (parkId: any) => void,
    showOpenGate: boolean,
    showOpenGateModal: () => void,
    showTandemChatBanner: boolean,
    navigationToTandemChat: () => void,
}

export function buildNotifications(props: NotificationBuilderProps): INotification[] {

    const {
        currentSession,
        previousPark,
        parkingRequest,
        employeeSubscriptions,
        showCurrentSession,
        showParkingRequestView,
        showEmployeeSubscription,
        showSubscriptionListView,
        errorGetGPSLocation,
        setErrorGetGPSLocation,
        getCurrentPosition,
        showEmployeeSubscriptionInvitation,
        isSessionGoingUnavailable,
        isSessionWentUnavailable,
        showEndedSession,
        sessionBayChangedTo,
        removeSessionBayChangeNotification,
        failedPayments,
        showRetryPaymentRequest,
        availabilityNotifications,
        showParkDetailView,
        showOpenGate,
        showOpenGateModal,
        showTandemChatBanner,
        navigationToTandemChat,
    } = props

    const notifications: INotification[] = [];

    if(!!currentSession && !currentSession.endedAt && !currentSession.endedOnDevice){
        const mainText = isSessionGoingUnavailable ? Strings.park_going_unavailable :
                (currentSession.startedAt === null ? Strings.you_have_an_active_reservation: Strings.you_have_an_active_session);
        notifications.push({
            key: currentSession.id,
            actionText: Strings.view,
            mainText: mainText,
            color: isSessionGoingUnavailable ? Colours.RED : Colours.ORANGE,
            action: showCurrentSession
        });
    }

    if(showOpenGate) {
        const shortAddress = (/([^,]+){1}(,[^,]+)?/gi.exec(previousPark?.address || '') || "car park")[0];
        notifications.push({
            key: "showOpenGate",
            actionText: Strings.view,
            mainText: Strings.open_the_gate_at + shortAddress,
            color: Colours.ORANGE,
            action: showOpenGateModal,
        });
    }

    if (showTandemChatBanner) {
        notifications.push({
            key: "showTandemChat",
            actionText: Strings.view,
            mainText: Strings.tandem_parking.view_tandem_chat,
            color: Colours.ORANGE,
            action: navigationToTandemChat,
        });
    }

    if(!!failedPayments && failedPayments.length > 0){
        //TODO Currently only support failed session payments and 1 failed payment at a time.  Need to support multiple failed
        // payments and display a list view when more than one, also support failed sub/infringement/etc payments
        if(!!failedPayments.length) {
            if(failedPayments[0].paymentIntent){
                notifications.push({
                    key: failedPayments[0].paymentIntent,
                    actionText: Strings.view,
                    mainText: Strings.payment_authentication_required,
                    color: Colours.RED,
                    //@ts-ignore
                    action: () => showRetryPaymentRequest(failedPayments[0].sessionId)
                });
            }else{
                notifications.push({
                    key: failedPayments[0].stripeChargeId!,
                    actionText: Strings.view,
                    mainText: Strings.your_last_payment_failed_please_retry,
                    color: Colours.RED,
                    //@ts-ignore
                    action: () => showRetryPaymentRequest(failedPayments[0].sessionId)
                });
            }
        }
    }

    if(!!sessionBayChangedTo && !!currentSession) {
        notifications.push({
            key: currentSession.id,
            actionText: Strings.dismiss,
            mainText: Strings.your_bay_has_been_changed_to(sessionBayChangedTo),
            color: Colours.ORANGE,
            action: removeSessionBayChangeNotification
        });
    }

    if(isSessionWentUnavailable) {
        notifications.push({
            key: `session-went-unavailable`,
            actionText: Strings.view,
            mainText: Strings.session_went_unavailable,
            color: Colours.RED,
            action: showEndedSession
        })
    }

    if (parkingRequest && ParkingRequestStatus.Confirmed === parkingRequest.status) {
        notifications.push({
            key: parkingRequest.id,
            actionText: Strings.view,
            mainText: Strings.you_have_a_booking_tomorrow,
            color: Colours.ORANGE,
            action: () => showParkingRequestView(parkingRequest),
        });
    }

    let activeEmployeeSubscriptionCount = 0;
    let activeEmployeeSubscriptionId: number | null = null;
    Object.values(employeeSubscriptions || {})
    .filter(subscription => subscription.status !== 'Deleted')
    .forEach((subscription) => {
        if (subscription.status === 'Pending' || ((subscription.status === 'HostEnding' || subscription.status === 'LeaserEnding') && subscription.previousStatus === 'Pending')){
            notifications.push({
                key: subscription.id,
                actionText: Strings.view,
                mainText: Strings.you_have_pending_subscription,
                color: Colours.PINK,
                action: () => showEmployeeSubscription(subscription.id)
            });
        } else if(subscription.status === 'Invited') {
            notifications.push({
                key: subscription.id,
                actionText: Strings.view,
                mainText: Strings.you_have_pending_subscription,
                color: Colours.PINK,
                action: () => showEmployeeSubscriptionInvitation(subscription)
            });
        } else {
            //active sub
            activeEmployeeSubscriptionId = subscription.id;
            ++activeEmployeeSubscriptionCount;
        }
    });

    if (activeEmployeeSubscriptionCount > 1) {
        notifications.push({
            key: `subscriptions${activeEmployeeSubscriptionCount}`,
            actionText: Strings.view,
            mainText: Strings.you_have_active_subscriptions,
            color: Colours.ORANGE,
            action: showSubscriptionListView
        });
    } else if(!!activeEmployeeSubscriptionId) {
        notifications.push({
            key: activeEmployeeSubscriptionId,
            actionText: Strings.view,
            mainText: Strings.you_have_active_subscription,
            color: Colours.PINK,
            action: () => showEmployeeSubscription(activeEmployeeSubscriptionId!)
        });
    }

    if(errorGetGPSLocation && Platform.OS !== "web"){
        notifications.push({
            key: 'gpsError',
            actionText: Strings.retry,
            mainText: Strings.cannot_find_location,
            color: Colours.GREY_50,
            action: () => {
                setErrorGetGPSLocation(false);
                getCurrentPosition();
            }
        });
    }

    const pans = [] as MultiData[];
    for (let parkId in availabilityNotifications) {
        if (availabilityNotifications.hasOwnProperty(parkId)) {
            pans.push({text: availabilityNotifications[parkId].address, id: parseInt(parkId)});
        }
    }

    if(pans.length > 0){
        notifications.push({
            key: 'availabilityNotification',
            actionText: Strings.view,
            mainText: Strings.you_have_an_active_bay_notification,
            mainTextMultiple: Strings.you_have_an_active_bay_notifications,
            data: pans,
            color: Colours.GREY_50,
            action: (id) => {
                if(!id) return;//ts warning

                try {
                    const params = {
                        parkId: id
                    }
                    logEvent(undefined, "baynotification_mapbanner", params, "baynotification_mapbanner", params)
                } catch (e) {
                    console.log("error on sending analytics", e);
                }

                showParkDetailView(id);
            }
        });
    }

    return notifications;
}
