import React from 'react';
import { View } from 'react-native';
import AccountVehiclesCardItem from "./cardItems/AccountVehiclesCardItem";
import AccountCreditCardsCardItem from "./cardItems/AccountCreditCardsCardItem";
import { EmployeeSubscription } from "react/model/EmployeeSubscription";

type UserDetailsCardProps = {
    hideVehicle?: boolean;
    hideCreditCard?: boolean;
    parkId?: number;
    subscription?: EmployeeSubscription;
    selectSubscriptionVehicles?: boolean;
}

export default function UserDetailsCard(props: UserDetailsCardProps) {

    const {
        hideVehicle, hideCreditCard, parkId, subscription, selectSubscriptionVehicles,
    } = props;

    return (
        <View>
            {!hideVehicle && <AccountVehiclesCardItem
                parkId={parkId}
                subscription={subscription}
                selectSubscriptionVehicles={selectSubscriptionVehicles}
            />}
            {!hideCreditCard && <AccountCreditCardsCardItem />}

        </View>
    )
}
