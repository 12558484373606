import React, { useState } from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import List from "../common/List";
import { connect } from "react-redux";
import Strings from "../../constants/localization/localization";
import TextInput from "../common/TextInput";
import { assignSensorToQRCode, retrieveSensorsByEUI } from "../../redux/actions/sensors";
import { Constants } from "../../constants/constants";
import Colors from "../../constants/colors";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import Input from "react/parkable-components/input/Input";
import Text from "react/parkable-components/text/Text";
import { IRootReducer } from "../../redux/reducers/main";
import { Sensor } from "../../model/Sensor";
import { createRoute, NavigationProps, useNavigation } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import { Camera } from "expo-camera";
import { BarCodeEvent, BarCodeScanner } from "expo-barcode-scanner";
import ParkableBaseView from "../common/ParkableBaseView";

const arrowRight = require('../../resources/blueArrowRight.png');
const tick = require("../../resources/tickGreenCircle.png");

const getReduxProps = (state: IRootReducer) => {
    return {
        sensors: state.sensors.sensors,
        currentUserId: state.data.userId,
    }
}

const actions = {
    retrieveSensorsByEUI,
    assignSensorToQRCode,
}

export type AssignSensorProps =
    typeof actions
    & ReturnType<typeof getReduxProps>
    & NavigationProps<Routes.AssignSensorQrCodeView>;

const AssignSensorQrCodeView = (props: AssignSensorProps) => {

    const [scanned, setScanned] = useState(false);
    const [scannedCode, setScannedCode] = useState<string | undefined>(undefined);
    const [selectedSensor, setSelectedSensor] = useState<Sensor | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    const navigation = useNavigation();

    const onEUIChange = (text: string) => {
        props.retrieveSensorsByEUI(text);
    }

    const onSensorSelect = (sensor: Sensor) => {
        setSelectedSensor(sensor);
    }

    const onBackPress = () => {
        if (selectedSensor !== undefined) {
            setSelectedSensor(undefined);
        } else {
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            });
        }
    }

    const onReadCode = (code: BarCodeEvent) => {
        setScanned(true);
        setScannedCode(code.data);
    }

    const onSaveCode = () => {
        // @ts-ignore
        selectedSensor && scannedCode
            ? props.assignSensorToQRCode(selectedSensor?.eui, scannedCode)
            : setError(Strings.unrecognised_code);
        setScanned(false);
        setSelectedSensor(undefined);
        setScannedCode(undefined);
    }

    const renderSensorRow = ({item: sensor}: { item: Sensor }) => {
        return <TouchableOpacity style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            borderBottomWidth: 1,
            paddingHorizontal: 15,
            borderColor: Colors.ParkableXtraLightGrey,
        }} onPress={() => onSensorSelect(sensor)}>
            {sensor.reference === null
                ? <View style={{height: 40, width: 20, margin: 10}}></View>
                : <View style={{justifyContent: 'flex-start'}}>
                    <Image source={tick} style={{height: 40, width: 20, margin: 10, resizeMode: 'contain'}}/>
                </View>}
            <Text grey style={styles.listViewItemText}>{sensor.eui}</Text>
            <View style={{justifyContent: 'center'}}>
                <Image source={arrowRight} style={{height: 40, width: 40, margin: 10}}/>
            </View>
        </TouchableOpacity>
    }

    return (<ParkableBaseView
                scrollable={false}
                onBackPress={onBackPress}>
            <View style={styles.base}>
                <Text h2 bold center style={styles.header}>{Strings.assign_sensor_qr}</Text>
            {!selectedSensor
                ? <View style={styles.base}>
                    <View style={styles.base}>
                        <Text grey>{Strings.enter_dev_eui}</Text>
                        <TextInput style={styles.simpleViewStyle} onChangeText={onEUIChange}
                                   autoCorrect={false}
                                   placeholder="Device EUI"
                                   maxLength={16}
                                   autoCapitalize={"characters"}
                                   selectionColor={Colours.BLUE}
                                   placeholderTextColor={Colours.GREY_10}/>
                    </View>
                    <View style={{flex: 1}}>
                        <List renderRow={renderSensorRow} data={props.sensors}/>
                    </View>
                </View>
                : <View>
                    <Text p grey center>{Strings.please_scan_code}</Text>
                    <View style={styles.qrScannerContainer}>
                        <Camera
                            onBarCodeScanned={scanned ? undefined : (e) => onReadCode(e)}
                            barCodeScannerSettings={{
                                barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                            }}
                            style={styles.qrScanner}
                            flashMode={(Camera.Constants.FlashMode as any).auto}
                        />
                    </View>
                    <Text style={styles.header}>{Strings.enter_code}</Text>
                    <Input
                        autoCorrect={false}
                        autoCapitalize={"characters"}
                        maxLength={7}
                        onChangeText={(v) => {
                            setScannedCode(v.trim().toUpperCase());
                            setScanned(true);
                        }}
                    />
                </View>}
            {!!scanned && !!scannedCode && <View style={{
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: 25
            }}>
                <Text grey>{"QR Code: " + scannedCode}</Text>
                {scanned ? <Button center style={styles.buttonStyle} onPress={onSaveCode}>Save</Button> : null}
            </View>}
                {error && <Text style={styles.error}>{error}</Text>}
            </View>
            </ParkableBaseView>)
}

export default connect(getReduxProps, actions)(AssignSensorQrCodeView);

export const AssignSensorQrCodeRoute = createRoute({
    path: Routes.AssignSensorQrCodeView,
});

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    error: {
        alignSelf: "center",
        marginBottom: 9,
        marginHorizontal: 36,
        color: Colours.RED
    },
    buttonStyle: {
        width: 250,
        height: 40
    },
    containerStyle: {
        flex: 1,
        borderColor: '#000'
    },
    header: {
        paddingVertical: 14,
    },
    simpleViewStyle: {
        marginTop: 10,
        height: 60,
    },
    listViewItemText: {
        fontFamily: Constants.FONT_BOLD,
        fontSize: 26,
        paddingVertical: 5,
        paddingHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    QRtop: {
        color: 'red',
        fontSize: 28,
        fontWeight: "600"
    },
    qrScanner: {
        alignSelf: "center",
        borderRadius: 9,
        paddingHorizontal: 36,
        flex: 1,
        height: 270,

    },
    qrScannerContainer: {
        flexDirection: "row",
    }
});
