import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import { Feature } from "react/model/Bay";
import Text from "react/parkable-components/text/Text";
import Icon from "react/parkable-components/icon/Icon";
import Colours from "react/parkable-components/styles/Colours";
import { VehicleDTO } from "react/api/vehicle/dto/vehicleDTO";

const Cross = require("react/resources/cross-on-red.png");

type VehicleProps = {
    vehicle: VehicleDTO;
    selectVehicle: (vehicle: VehicleDTO) => void;
    removeVehicle: (vehicle: VehicleDTO) => void;
    isSelected?: boolean;
    showRemoveButton?: boolean;
    disabled?: boolean;
};

export const Vehicle = (props: VehicleProps) => {

    const {
        vehicle,
        selectVehicle,
        removeVehicle,
        isSelected,
        showRemoveButton,
        disabled,
    } = props;

    return (
        <View style={styles.vehicles}>

            <TouchableOpacity
                style={[styles.vehicleItem, isSelected ? styles.selectedVehicle : undefined]}
                disabled={disabled}
                activeOpacity={0.65}
                onPress={() => selectVehicle(vehicle)}
            >
                {vehicle.feature === Feature.MOTORBIKE &&
                    <Icon name={"motorbike"} white={isSelected} style={styles.motorbikeIcon} />}
                <Text white={isSelected} bold style={styles.registration}>
                    {vehicle.registration}
                </Text>
            </TouchableOpacity>

            {!isSelected && showRemoveButton &&
                <TouchableOpacity
                    style={styles.crossIconContainer}
                    activeOpacity={0.65}
                    onPress={() => removeVehicle(vehicle)}
                >
                    <Image style={styles.crossIcon} source={Cross} />
                </TouchableOpacity>
            }
        </View>
    );
};

const styles = StyleSheet.create({
    vehicles: {
        width: "50%",
        paddingVertical: 7,
        paddingHorizontal: 11
    },
    crossIconContainer: {
        position: "absolute",
        top: -4,
        right: 0,
        zIndex: 500
    },
    crossIcon: {
        height: 25,
        width: 25
    },
    motorbikeIcon: {
        width: 27,
        height: 27,
        marginRight: 6
    },
    registration: {
        textAlign: "center"
    },
    vehicleItem: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingVertical: 10,
        borderRadius: 3,
        borderWidth: 1,
        height: 54,
        borderColor: Colours.GREY_BORDER,
        backgroundColor: Colours.WHITE
    },
    selectedVehicle: {
        borderColor: Colours.GREEN,
        backgroundColor: Colours.GREEN
    }
});
