import { EmployeeSubscription } from "../../../model/EmployeeSubscription";
import { Bay } from "../../../model/Bay";
import { createRoute, NavigationProps } from "../../../navigation/constants";
import { Routes } from "../../../navigation/root/root.paths";
import React from "react";
import { Platform } from "react-native";
import ParkableMapView from "./ParkableMapView";
import ParkableMapViewWeb from "./web/ParkableMapViewWeb";

type Props = NavigationProps<Routes.ParkableMapView>

export default function MapViewRoot(props: Props) {
    return Platform.OS === "web"
        ? <ParkableMapViewWeb {...props}/>
        : <ParkableMapView {...props}/>;
}

export class MapScreenParams {
    latitude?: number;
    longitude?: number;
    latitudeDelta?: number;
    longitudeDelta?: number;
    zoom?: number;
    animatedToSearchLocation?: boolean;
    employeeSubscription?: EmployeeSubscription;
    bay?: Bay;
    showSubscription?: boolean;
    bySearch?: boolean;
}

export const MapRoute = createRoute({
    path: Routes.ParkableMapView,
    params: {
        type: MapScreenParams,
        deserialize: (d) => ({
            latitude: d.Number(),
            longitude: d.Number(),
            latitudeDelta: d.Number(),
            longitudeDelta: d.Number(),
            zoom: d.Number(),
            animatedToSearchLocation: d.Boolean(),
            employeeSubscription: d.Json<EmployeeSubscription>(),
            bay: d.Json<Bay>(),
            showSubscription: d.Boolean(),
            bySearch: d.Boolean(),
        })
    }
})
