import React from 'react';
import {View} from 'react-native';
import Strings from "../../constants/localization/localization";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import {getTimeElapsed} from "./SessionElapsedFunctions";
import {OcpiSession} from "../../model/ocpi/OcpiSession";
import {ParkSessionDTO} from "../../model/ParkSessionDTO";
import {useCalculateEstimatedAmount} from "react/api/parkingPrice/parkingPrice.api";
import localizeCurrency from "react/constants/localization/localizeCurrency";
import {Routes} from "react/navigation/root/root.paths";
import {useNavigation} from "react/navigation/constants";

type Props = {
    session: ParkSessionDTO | null;
    park: { id: number; organisation: number | null; reservationMinutes: number | null } | null;
    isReservation: boolean;
    currencyCode?: string;
    ocpiSessions?: Array<OcpiSession>;
    parkId?: number;
};

export default function SessionElapsed(props: Props) {
    const {session, currencyCode, park, parkId} = props;
    const { cost } = useCalculateEstimatedAmount({
        organisationId: park?.organisation,
        parkId,
        sessionId: session?.id,
    });
    const navigation = useNavigation();

    if (!session || !park || cost === undefined) {
        return null;
    }
    const isFreeSession = cost === 0 && !!session.freeSession;

    const elapsedText = getTimeElapsed(session, park);
    const currentCharge = isFreeSession ? Strings.free_session :
        localizeCurrency(cost, currencyCode ?? undefined, false, true)

    const onCasualPricePress = () =>{
        navigation.push(Routes.PricingView, {
            parkId: park.id,
            parkingPrice: session.parkingPrice!,
            startTime: session.startedAt
        });
    }

    return (
        <View style={{width: '100%',}}>
            <TableRow iconLeft={"stopwatchfilled"} iconLeftProps={{color: Colours.BLUE,}}
                      label={Strings.time_elapsed} >{elapsedText}</TableRow>
            <TableRow iconLeft={"dollarfilled"}
                      iconLeftProps={{color: Colours.GREEN,}}
                      onPress={onCasualPricePress}
                      chevron
                      chevronText={Strings.pricing}
                      label={Strings.running_total} >
                {currentCharge}
            </TableRow>
        </View>
    );
}
