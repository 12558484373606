import React, { useCallback, useEffect } from "react";
import Text from "react/parkable-components/text/Text";
import {AppState, AppStateStatus, View} from "react-native";
import {containerStyles} from "./styles";
import {ScrollView} from "react-native-gesture-handler";
import BookingRow from "./booking-row/booking-row";
import {useParkingRequestsForUser} from "../../../api/parking-request/parking-request.api";
import {IRootReducer} from "../../../redux/reducers/main";
import {connect} from "react-redux";
import {filterActiveRequests} from "../../../constants/parking-request.constants";
import Strings from "../../../constants/localization/localization";
import _ from 'lodash';
import * as Push from "../../../navigation/pushNotifications/constants";
import {addNotificationListener} from "../../../navigation/pushNotifications/notificationListener";
import {createRoute} from "../../../navigation/constants";
import {Routes} from "../../../navigation/root/root.paths";
import ParkableBaseView from "../../common/ParkableBaseView";

type BookingAndRequestsViewProps = ReturnType<typeof getReduxProps>;

const getReduxProps = (state: IRootReducer) => {
    const currentUser = state.user.user;
    return {
        currentUser,
    };
};

const BookingsAndRequestsView_ = (props: BookingAndRequestsViewProps) => {
    const {parkingRequests, mutate} = useParkingRequestsForUser(props.currentUser?.id);

    const activeRequests =
        _.orderBy(filterActiveRequests(parkingRequests??[]), 'date');

    const onNotificationReceived = useCallback((code: string) => {
        if (code === Push.ParkingRequestUpdated || code === Push.OpenBookingsView) {
            void mutate();
        }
        return false;
    }, []);

    useEffect(() => {
        const notifListener = addNotificationListener(onNotificationReceived, 'BookingsAndRequestsView');
        return () => { notifListener.remove()};
    }, []);

    const onAppStateChange = (nextAppState: AppStateStatus) => {
        if (nextAppState === 'active') {
            void mutate();
        }
    }

    useEffect(() => {
        const subscription = AppState.addEventListener('change', onAppStateChange);
        return () => subscription.remove();
    }, []);

    return (
        <ParkableBaseView scrollable={false}>
            <Text h1 bold>{Strings.my_bookings_and_requests}</Text>
            <View>
                <ScrollView style={containerStyles.main}>
                    {activeRequests?.map((request, i) => (<BookingRow key={i} request={request}/>))}
                </ScrollView>
                {activeRequests.length === 0 &&
                    <Text>{Strings.no_bookings_or_requests}</Text>}
            </View>
        </ParkableBaseView>
    );
};

export const BookingsAndRequestsView = connect(getReduxProps)(
    BookingsAndRequestsView_
);

export const UserBookingsAndRequestsRoute = createRoute({
    path: Routes.UserBookingsAndRequestsView,
});
