import React from 'react';
import {StyleSheet, View, Image, Platform} from "react-native";
import Button from "react/parkable-components/button/Button";
import Text from "react/parkable-components/text/Text";
import Strings from '../../constants/localization/localization'
import { useNavigation } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import {PADDING} from "../../root/root.constants";

const Raddzz = require('../../resources/raddzz.png');

export default function FirstTimeSetup() {

    const navigation = useNavigation();

    const goAddVehicle = () => {
        navigation.push(Routes.AddNewVehicleView, {});
    }

    return (
        <View style={styles.base}>
            <View style={styles.base}>
                <Text h1 style={{marginTop: 10}}>{Strings.first_time_setup}</Text>
                <Text>{Strings.it_will_save_next_time}</Text>
                <View style={styles.imageContainer}>
                    <Image style={styles.backgroundImage} source={Raddzz} resizeMode={"contain"}/>
                </View>
            </View>
            <Button style={styles.bottomButtons} iconRight={"arrowboldright"}
                    onPress={goAddVehicle}>{Strings.add_vehicle}</Button>
        </View>
    )
}

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    imageContainer: {
        flexDirection: "row",
    },
    backgroundImage: {
        marginRight: -PADDING,
        marginTop: 100,
        flex: 1,
        aspectRatio: 375 / 287,
        height: 150,
        ...Platform.select({
            web: {
                height: 400,
            }
        })
    },
    bottomButtons: {
        justifyContent: 'center',
        flexDirection: 'row',
    },
});
