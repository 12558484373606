import {lighten} from "polished";
import {StyleSheet} from "react-native";
import {theme} from "../../../constants/theme";
import {PADDING} from "../../../root/root.constants";

export const containerStyles = StyleSheet.create({
    container: {
        backgroundColor: theme.palette.WHITE,
        flex: 1,
        overflow: "scroll",
    },
});

export const contentStyles = StyleSheet.create({
    heading: {
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    mainText: {
        marginBottom: theme.spacing(2),
        fontSize: 16,
        lineHeight: 18,
    },
    textOrange: {
        color: theme.palette.ORANGE,
    },
    graphicContainer: {
        width: "50%",
        justifyContent: "center",
        marginTop: PADDING,
    },
    image: {
        maxWidth: 300,
        maxHeight: 300,
    },
});

export const alertStyles = StyleSheet.create({
    container: {
        marginTop: theme.spacing(2),
        backgroundColor: theme.palette.GREY_10,
        borderRadius: 4,
        paddingHorizontal: theme.spacing(4),
        paddingVertical: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    tableContainer: {
        borderRadius: 4,
        marginTop: theme.spacing(2),
        paddingVertical: theme.spacing(1),
        //paddingHorizontal: theme.spacing(1),
    },
    tableGroup: {
        flex: 1,
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1),
        borderRadius: 4,
        backgroundColor: theme.palette.WHITE
    },
    tableGroupGreen: {
        backgroundColor: lighten(0.5, theme.palette.GREEN),
    },
    tableGroupRed: {
        backgroundColor: lighten(0.5, theme.palette.RED),
    },
    tableRow: {
        flexDirection: "row",
        alignItems: "flex-start",
        width: "90%",
        marginBottom: theme.spacing(1),
    },
    rowIcon: {
        marginRight: theme.spacing(1),
        width: 20,
        height: 20,
    },
});
