import React from "react";
import {Dimensions, Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import {useMakeBayAvailableContext} from "../employeeSubscription/context";
import {Availability, getDayAvailability} from "../../model/Availability";
import {getDayOfWeek} from "../../constants/Util";
import {Colours, Text} from "../../parkable-components";

const SCREEN_WIDTH = Dimensions.get("window").width

type SelectableDayOfWeekProps = {
    parkAvailability: Availability,
};

const SelectableDayOfWeek = (props: SelectableDayOfWeekProps) => {

    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    const context = useMakeBayAvailableContext();

    const onDayOfWeekPress = (dayOfWeekIndex: number) => {

        if(!context || !context.selectedDaysOfWeek) {
            return;
        }

        let newSelectedDays;
        const index = context.selectedDaysOfWeek.indexOf(dayOfWeekIndex);

        if (index > -1) {
            // Remove the day if it's already selected
            newSelectedDays = context.selectedDaysOfWeek.filter(index => index !== dayOfWeekIndex);
        } else {
            // Add the day if it's not selected
            newSelectedDays = [...context.selectedDaysOfWeek, dayOfWeekIndex];
        }

        context.setSelectedDaysOfWeek(newSelectedDays);

    }


    const renderDayOfWeekTitleTile = (dayOfWeek: string, index: number) => {
        const isSelectedDayOfWeek = !!context?.selectedDaysOfWeek && context.selectedDaysOfWeek.indexOf(index) > -1;
        const dayAvailability = getDayAvailability(getDayOfWeek(index).toLowerCase(), props.parkAvailability)
        const isParkClosed = !dayAvailability.available;
        return (<TouchableOpacity disabled={isParkClosed} style={[styles.dayOfWeekContainer, {
            backgroundColor: isSelectedDayOfWeek ? Colours.GREEN :  "#EDF1F3",
            borderColor: isSelectedDayOfWeek ? Colours.GREEN : "#EDF1F3",
        }]} onPress={() => onDayOfWeekPress(index)}>
            <Text h4 bold grey style={[styles.dayTilesText, isParkClosed ? {opacity: 0.25} : {opacity: 0.99}]}>{dayOfWeek}</Text>
        </TouchableOpacity>)};

    const dayOfWeekTitleTiles = daysOfWeek.map((dayOfWeek, i) => renderDayOfWeekTitleTile(dayOfWeek, i));

    return (
        <View style={styles.dayOfWeekHeaderContainer}>
            {dayOfWeekTitleTiles}
        </View>
    );
};

const styles = StyleSheet.create({
    dayOfWeekHeaderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    dayOfWeekContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        ...Platform.select({
            native: {
                height: (SCREEN_WIDTH - 50) / 10,
                width: (SCREEN_WIDTH - 50) / 10,
            },
            web: {
                flex: 1,
                maxWidth: "90%",
                aspectRatio: 1,
            }
        }),
        borderRadius: 3,
        margin: 6,
        backgroundColor: "#EDF1F3"
    },
    dayTilesText: {
        backgroundColor: 'transparent',
        marginTop: 6,
    },
});

export default SelectableDayOfWeek;
