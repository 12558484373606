import { useActionSheet } from '@expo/react-native-action-sheet';
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Strings from "../../constants/localization/localization";
import { ILocation } from "../../constants/Util";
import { ParkDTO } from "../../model/ParkDTO";
import { getDriveTime as getDriveTimeAction } from "../../redux/actions/google";
import { IRootReducer } from "../../redux/reducers/main";
import {useDirectionsPressHandler} from "../common/maps/Map.utils";

type Props = {
    endLatitude: number | undefined,
    endLongitude: number | undefined,
    park: Omit<ParkDTO, "territory"> | null,
    showDriveTime: boolean,
    showLocationLabel?: boolean,
}

function DriveTimeTableRow(props: Props & ReduxProps & actionsType) {

    const { gpsLocation, endLatitude, endLongitude, getDriveTime, park, showDriveTime, showLocationLabel: showLocationLabel } = props;

    const startLatitude = gpsLocation?.latitude;
    const startLongitude = gpsLocation?.longitude;

    const [driveTimeString, setDriveTimeString] = useState<string | null>(Strings.calculating_drive_time);
    const { showActionSheetWithOptions } = useActionSheet();
    const handleDirectionsPress = useDirectionsPressHandler(park);

    useEffect(() => {
        (async () => {
            if (!!showDriveTime && !!startLatitude && !!startLongitude && !!endLatitude && !!endLongitude) {
                try {
                    const driveTimeData: { rows: Array<{ elements: Array<{ duration_in_traffic: { text: string } }> }> } =
                        await getDriveTime(startLatitude, startLongitude, endLatitude, endLongitude, 'now');
                    let driveTimeString = driveTimeData?.rows[0].elements[0]?.duration_in_traffic?.text;
                    if (!!driveTimeString) {
                        driveTimeString = `${driveTimeString} ${Strings.away}`;
                        setDriveTimeString(driveTimeString)
                    }
                } catch (error) {
                    setDriveTimeString(park?.address ?? Strings.time_unavailable);
                }
            } else {
                setDriveTimeString(park?.address ?? Strings.time_unavailable);
            }
        })()
    }, [showDriveTime, startLatitude, startLongitude, endLatitude, endLongitude]);

    return (
        <TableRow
            condensed={!showLocationLabel}
            style={showLocationLabel ? {} : { alignContent: "flex-start" }}
            label={showLocationLabel ? Strings.location : undefined}
            contentRight={undefined}
            iconLeft={showLocationLabel ? "pinlocation2filled" : "parkingfilled"}
            iconLeftProps={showLocationLabel ? { color: Colours.GREEN } : {}}
            textProps={{ small: true, numberOfLines: 2 }}
            buttonProps={{
                plain: true, textProps: { style: { width: 80, textAlign: "right", color: Colours.BLUE, fontSize: 15, fontFamily: "GTEestiDisplay-Regular", margin: 0, padding: 0, alignItems: "flex-end" } },
            }}
            buttonText={Strings.directions} onButtonPress={handleDirectionsPress}
        >
            {driveTimeString}
        </TableRow>
    );
}

type ReduxProps = {
    gpsLocation: ILocation | null
}

type actionsType = {
    getDriveTime: Function
}

const actions = {
    getDriveTime: getDriveTimeAction,
}

export default (connect(
    (state: IRootReducer): ReduxProps => {
        return ({
            gpsLocation: state.geoLocation.gpsLocation
        })
    }, actions
)(DriveTimeTableRow) as React.FunctionComponent<Props>);
