import {Dimensions} from "react-native";
import {Term} from "../model/Term";
import {Region} from "react-native-maps";
import { EmployeeSubscriptionStatus } from "../model/EmployeeSubscription";

export const EV_ENABLED_PREFIXES = ["+44"];

export const MIN_PASSWORD_LENGTH_FOR_SIGN_UP = 8;
export const MIN_PASSWORD_LENGTH_FOR_LOG_IN = 6;
export const MIN_DAYS_TO_WAIT_TO_ASK_FOR_REVIEW = 180; // 6 months

export const defaultLocation = (): Region => {
    const region = {
        longitude: 0,
        latitude: 0,
        latitudeDelta: 150,
        longitudeDelta: 150
    }

    return region;
};

export const FONT_BOLD = 'SourceSansPro-Bold';
export const FONT_SEMI_BOLD = 'SourceSansPro-SemiBold';
export const FONT_REGULAR = 'SourceSansPro-Regular';

export const AppStateChangeThrottleMillis = 30000;

export const Constants = {
    screenWidth: Dimensions.get('window').width,
    screenHeight: Dimensions.get('window').height,

    FONT_BOLD: 'SourceSansPro-Bold',
    FONT_REGULAR: 'SourceSansPro-Regular',

    MonthlyTerm: "Month",
    WeeklyTerm: "Week",

    Subscription_Status_Active: "Active",
    Subscription_Status_Processing: "Processing",
    Subscription_Status_HostEnding: "HostEnding",
    Subscription_Status_LeaserEnding: "LeaserEnding",
    Subscription_Status_Ended: "Ended",
    Subscription_Status_PastDue: "PastDue",
    Subscription_Status_Unpaid: "UnPaid",
    Subscription_Status_Invited: "Invited",
    Subscription_Status_Pending: 'Pending',
    Subscription_Status_Deleted: 'Deleted',
    Ended_Subscription_Status_Array: [EmployeeSubscriptionStatus.Unpaid, EmployeeSubscriptionStatus.Ended, EmployeeSubscriptionStatus.Deleted],
    Active_Subscription_Status_Array: ['Active', 'HostEnding', 'LeaserEnding', 'PastDue'],
    Pastdue_Subscription_Status_Array: ['Processing', 'PastDue'],

    MinimumAgeStripe: 13,
    classicCountryCodes: ['NZ', 'AU'],

    COUNTRY_CODE_AUSTRALIA: "AU",
    COUNTRY_CODE_NZ: "NZ",
    COUNTRY_CODE_UK: "UK",

    holdBayTimeLimit: 5*60,

    bluetoothRangeToExpireSeconds: 5*60,

    minimumPointsToRedeemed: 10
};

export default Constants;

export const termToMoment: {[term:string]: 'months' | 'weeks'} = {
    [Term.Month]: 'months',
    [Term.Week]: 'weeks',
};

export const {screenWidth, screenHeight} = Constants;

export const Roles = {
    SENSOR_ADMIN : "SENSOR_ADMIN",
    ADMINISTRATOR : "ADMINISTRATOR",
};

export const DefaultSettings = {
    DefaultDateFormat: 'DD MMM YYYY',
};

export const ErrorCodes = {
    PaymentFailed: 1001
};
