import {Availability, AvailabilityDTO} from "../../model/Availability";
import {TouchableOpacity, View} from "react-native";
import React, {useEffect, useState} from "react";
import Text from "react/parkable-components/text/Text";
import Icon from "react/parkable-components/icon/Icon";
import convertFromAvailabilityString, {DayAvailabilityRules} from "../parkingViews/history/session-details/operatingHours/convertFromAvailabilityString";
import Strings from "../../constants/localization/localization";
import Colours from "react/parkable-components/styles/Colours";
import formatTime from "../parkingViews/history/session-details/operatingHours/formatTime";

type Props = {
    availability:AvailabilityDTO|undefined;
}


export default function OpeningHoursComponent(props:Props){

    const availability = props.availability;

    const [loading, setLoading] = useState(true);

    const [expanded, setExpanded] = useState(false);
    const [formattedDays, setFormattedDays] = useState({} as Array<{name:string, rules:DayAvailabilityRules}>);


    function parseAvailability(avail: DayAvailabilityRules, name: string) {
        if (!avail.available) {
            return <Text key={name} small style={{flex:1}}>{Strings.park_closed}</Text>;
        }

        if (avail.openAllDay) {
            return <Text key={name} small style={{flex:1}}>{Strings.open_24_hours}</Text>;
        }

        return <View key={name} style={{flex:1, justifyContent: 'center'}}>
            {avail.availabilityRules.map((availabilityItem, i) =>
                <Text key={name+i} small>{`${formatTime(availabilityItem.openTime)} - ${formatTime(availabilityItem.closeTime)}`}</Text>
            )}
        </View>;
    }

    useEffect(() => {
        if(!!availability){
            const dayAvailabilities = [];
            dayAvailabilities.push({name: Strings.sun, rules: convertFromAvailabilityString(availability.sundayAvailability, availability)});
            dayAvailabilities.push({name: Strings.mon, rules: convertFromAvailabilityString(availability.mondayAvailability, availability)});
            dayAvailabilities.push({name: Strings.tue, rules: convertFromAvailabilityString(availability.tuesdayAvailability, availability)});
            dayAvailabilities.push({name: Strings.wed, rules: convertFromAvailabilityString(availability.wednesdayAvailability, availability)});
            dayAvailabilities.push({name: Strings.thu, rules: convertFromAvailabilityString(availability.thursdayAvailability, availability)});
            dayAvailabilities.push({name: Strings.fri, rules: convertFromAvailabilityString(availability.fridayAvailability, availability)});
            dayAvailabilities.push({name: Strings.sat, rules: convertFromAvailabilityString(availability.saturdayAvailability, availability)});

            setFormattedDays(dayAvailabilities);
            setLoading(false);
        }
    }, [availability]);


    // console.log("Availabilty: ", props.availability);

    if(loading){
        return (
            <View style={{height: 45, backgroundColor: Colours.GREY_BORDER, flexDirection: "row", justifyContent:"space-between", alignItems: "center", paddingHorizontal:9}} >
                <Text style={{paddingHorizontal:9}}>{Strings.loading}</Text>
            </View>
        )
    }

    if(!expanded || loading) {
        return (<TouchableOpacity activeOpacity={1} onPress={() => setExpanded(!expanded)}>
                <View style={{height: 36, backgroundColor: Colours.GREY_BORDER, flexDirection: "row", justifyContent:"space-between", alignItems: "center", paddingHorizontal:9, borderRadius:3,}} >
                    <Text small style={{paddingHorizontal:9, width: 72}}>{loading ? Strings.loading : formattedDays[new Date().getDay()].name}</Text>
                    {loading ? null : parseAvailability(formattedDays[new Date().getDay()].rules, formattedDays[new Date().getDay()].name)}
                    <Icon small  name={"cheverondown"} />
                </View>
        </TouchableOpacity>)
    }
    else{
        return (<TouchableOpacity activeOpacity={1} onPress={() => setExpanded(!expanded)}>
            {formattedDays.map((d,i) => <View key={d.name} style={{height: 36, backgroundColor: i%2===0 ? Colours.GREY_BORDER: Colours.WHITE, flexDirection: "row", justifyContent:"center", alignItems: "center", paddingHorizontal:9, borderRadius:3,}} >
                <Text small style={{paddingHorizontal:9, width: 72}}>{d.name}</Text>
                {parseAvailability(d.rules, d.name)}
                {i === 0 ? <Icon small name={"cheveronup"} /> : null}
            </View>)}
        </TouchableOpacity>)
    }

}
