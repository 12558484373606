export const DefaultNotification = "DefaultNotification";
export const HostParkingStarted = "HostParkingStarted";
export const HostParkingEnded = "HostParkingEnded";
export const HostReservationStarted = "HostReservationStarted";
export const ParkingStarted = "ParkingStarted";
export const ParkingEnded = "ParkingEnded";
export const ReservationTimedOut = "ReservationTimedOut";
export const ReservationCancelled = "ReservationCancelled";
export const CurrentParkIsGoingUnavailable = "CurrentParkIsGoingUnavailable";
export const SessionEndedInUnavailablePark = "SessionEndedInUnavailablePark";
export const MessageReceived = "MessageReceived";
export const AdminMessageReceived = "AdminMessageReceived";
export const EmployeeSubscriptionCancelled = "EmployeeSubscriptionCancelled";
export const EmployeeSubscriptionCreated = "EmployeeSubscriptionCreated";
export const EmployeeSubscriptionUpdated = "EmployeeSubscriptionUpdated";
export const BayMadeAvailableInGroup = "BayMadeAvailableInGroup";
export const OrganisationGroupInvite = "OrganisationGroupInvite";
export const WarningSessionRunning = "WarningSessionRunning";
export const AutoEndSession = "AutoEndSession";
export const EmployeeSubscriptionWentUnavailable = "EmployeeSubscriptionWentUnavailable";
export const UnpaidEmployeeSubscription = "UnpaidEmployeeSubscription";
export const EmployeeSubscriptionPastDue = "EmployeeSubscriptionPastDue";
export const LeaseEnded = "LeaseEnded";
export const LeaseWentUnavailable = "LeaseWentUnavailable";
export const LeasePastDue = "LeasePastDue";
export const UnpaidLease = "UnpaidLease";
export const UnpaidLeaseEnded = "UnpaidLeaseEnded";
export const LeaseCreated = "LeaseCreated";
export const ParkingRequestUpdated = "ParkingRequestUpdated";
export const EmployeeSubscriptionInvited = "EmployeeSubscriptionInvited";
export const MemberInvited = "MemberInvited";
export const SessionBayChanged = "SessionBayChanged";
export const EvSessionStarted = "EvSessionStarted";
export const EvSessionStopped = "EvSessionStopped";
export const EvSessionFailedToStart = "EvSessionFailedToStart";
export const EvSessionChargerIdle = "EvSessionChargerIdle";
export const ParkAvailabilityNotificaiton = "ParkAvailabilityNotificaiton"; //spelling mistake on api
export const PaymentActionRequired = "PaymentActionRequired";
export const AddPaymentAndVehicleReminder = "AddPaymentAndVehicleReminder";
export const OpenBookingsView = "OpenBookingsView";
export const TandemMessageReceived = "TandemMessageReceived";

export enum StateType {
    Closed = 'Closed',
    Background = 'Background',
    Opened = 'Opened',
}