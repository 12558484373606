import React from "react";
import { StyleSheet, View } from "react-native";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import Icon from "react/parkable-components/icon/Icon";
import Strings from "../../../constants/localization/localization";
import { BayGroupType } from "react/model/BayGroupType";

type AllProps = {
    baySignage: string | null | undefined,
    organisationName?: string,
    hideOrganisationName?: boolean,
    parkDisplayName?: string,
    isPfB: boolean,
    isEnded:boolean,
    address? : string,
    groupType?: BayGroupType
}

export function SubscriptionHeader(props: AllProps) {
    const {
        baySignage, hideOrganisationName, organisationName, parkDisplayName, isPfB, isEnded, address, groupType
    } = props;

    const getHeaderText = () => {
        if (isPfB && !hideOrganisationName && organisationName && parkDisplayName) {
            return organisationName + ", " + parkDisplayName;
        }
        if (isPfB && parkDisplayName) {
            return parkDisplayName;
        }
        return address ?? Strings.loading;
    }

    const iconStyle = {...styles.iconStyle, backgroundColor: isEnded ? Colours.GREY_20 :
            (isPfB && groupType === "PublicSubscription" ? Colours.PINK : Colours.ORANGE)};

    return (<View>
                <View style={styles.view}>
                    <View style={iconStyle}>
                        <Icon name={"key"} color={Colours.WHITE} />
                    </View>
                    <View style={styles.textView}>
                        {!!baySignage && <Text  style={{marginBottom: 0}} h2>{Strings.bay + " " + baySignage}</Text>}
                        <Text small numberOfLines={1} grey={true} >{getHeaderText()}</Text>
                    </View>
                </View>
            </View>)
}

const styles = StyleSheet.create({
    view: {
        height: 92,
        width: '100%',
        flexDirection: "row",
        alignItems: "center",
    },
    iconStyle: {
        height: 63,
        width: 63,

        borderRadius: 31.5,
        justifyContent: "center",
        alignItems: "center"

    },
    textView: {
        marginLeft: 18,
        flex:1
    },
});
