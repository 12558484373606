import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import React, {ReactNode} from "react";
import {StyleSheet, TouchableOpacity, View} from "react-native";
import {theme} from "../../../constants/theme";
import {PADDING} from "../../../root/root.constants";

export type ConfirmItem = {
    onConfirm: () => any;
    text: string;
    disabled?: boolean,
}

type ConfirmationDialogProps = {
    title?: string;
    children?: ReactNode;
    onCancel: () => any;
    confirmProps: ConfirmItem[];
    plainButtons: boolean,
};

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {

    return (
        <View style={styles.container}>

            <TouchableOpacity
                activeOpacity={0.4}
                onPress={() => props.onCancel()}
                style={styles.overlay}/>

            <View style={styles.spacer}/>

            <View style={styles.subContainer}>
                <View style={styles.content}>

                    {props.title && <Text h2 style={styles.title}>{props.title}</Text>}

                    {props.children && (<>s
                        {typeof props.children === "string"
                            ? <Text style={styles.details}>{props.children}</Text>
                            : props.children}
                    </>)}

                    {props.confirmProps.map((confirm, i) => (
                        <TouchableOpacity
                            onPress={confirm.onConfirm}
                            activeOpacity={0.8}
                            key={i}
                            disabled={confirm.disabled}>
                            <Text grey={confirm.disabled}
                                  style={props.plainButtons ? styles.plainButton : styles.button}>
                                {confirm.text}
                            </Text>
                        </TouchableOpacity>
                    ))}
                </View>
            </View>
        </View>
    );
};

export default ConfirmationDialog;

const shadow = {
    shadowColor: Colours.GREY_20,
    shadowOpacity: 0.8,
    shadowRadius: 2,
};

export const styles = StyleSheet.create({
    title: {
        fontWeight: "bold",
    },
    details: {
        fontSize: 14,
        lineHeight: 16
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: 0,
        color: theme.palette.BLUE,
        textDecorationLine: "underline",
    },
    plainButton: {
        marginTop: theme.spacing(2),
        marginBottom: 0,
        alignSelf: 'center'
    },
    container: {
        position: "absolute",
        alignItems: "center",
        flex: 1,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        marginBottom: -50,
        zIndex: 100,
    },
    subContainer: {
        marginBottom: 80,
        zIndex: 101,
        marginTop: "10%",
        width: "80%"
    },
    overlay: {
        position: "absolute",
        backgroundColor: Colours.BLACK,
        opacity: 0.4,
        height: "100%",
        width: "100%",
    },
    content: {
        ...shadow,
        backgroundColor: Colours.WHITE,
        borderColor: Colours.GREY_10,
        borderRadius: 3,
        borderWidth: 1,
        zIndex: 9,
        paddingHorizontal: PADDING,
        paddingVertical: 11,
    },
    spacer: {
        flex: 1,
    },
});
