import {get, post, put, Token} from "./rest";


import {AuthResponse} from "./data";

export function sendEmailVerificationLink(token: Token, api: string, userId: number, continueUrl?: string) {
    const url = `${api}v4/users/${userId}/verify-email`;
    const data = {
        continueUrl
    }
    return post(token, url, data);
}

export function cancelEmailChange(token: Token, api: string, userId: number) {
    const url = `${api}v4/users/${userId}/cancel-email-change`;
    return put(token, url);
}

export function sendPasswordResetLink(token: Token, api: string, email?: string) {
    const url = api + "users/reset-password";
    const data = {
        email
    }
    return post(token, url, data);
}

export function getCustomToken(api: string, token: string) {
    const url = api + "v4/users/auth/customtoken";

    const dummy = {
        firebaseToken: undefined,
        parkableToken: undefined
    };

    return put(dummy, url, {data: token}) as Promise<AuthResponse>;
}

export function getAdminCustomToken(token: Token, api: string, email: string) {
    const url = api + "v4/users/auth/customtoken";
    return get(token, url, { email }) as Promise<AuthResponse>;
}

export function getAuthToken(firebaseKey: string, refreshToken: string) {
    return post(
        // @ts-ignore
        {},
        `https://securetoken.googleapis.com/v1/token?key=${firebaseKey}`,
        {
            grant_type: "refresh_token",
            refresh_token: refreshToken
        });
}
