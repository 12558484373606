import { IconProps } from "react/parkable-components/icon/Icon";
import {StyleSheet} from "react-native";
import {theme} from "../../../../constants/theme";
import {
    ActiveParkingRequestStatus,
    ParkingRequestStatus,
} from "../../../../model/ParkingRequest";

export const getIcon = (type: ActiveParkingRequestStatus): IconProps => {
    switch (type) {
        case ParkingRequestStatus.Confirmed:
            return {color: theme.palette.GREEN, name: "tick"};
        case ParkingRequestStatus.Accepted:
            return {color: theme.palette.BLUE, name: "calendar"};
        default:
            return {color: theme.palette.GREY_50, name: "stopwatch"};
    }
};

export const textStyles = StyleSheet.create({
    date: {
        ...theme.typography.subtitle,
    },
});
