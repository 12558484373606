import TableRow from "react/parkable-components/tableRow/TableRow";
import React, { FC } from "react"
import Strings from "../../../constants/localization/localization";
import { Nully } from "../../../constants/nully";
import { Routes } from "react/navigation/root/root.paths";
import {useNavigation} from "../../../navigation/constants";


export type VehicleRowProps = {
	registration?: string | null;
	isLoading?: boolean;
	onChange?: (vehicleId: Nully<number>) => any
}

const _VehicleRow = (props: VehicleRowProps) => {

	const navigation = useNavigation();

	const handleChangePress = () => {
		navigation.push(Routes.VehiclesView, {
			onBackPress: props.onChange
		})
	}

	const isChangeable = props.onChange != null && !props.isLoading

	return <TableRow iconLeft={"car"}
		condensed
		label={Strings.vehicle}
		textProps={{ rego: true }}
		chevronText={isChangeable ? Strings.change : undefined}
		chevron={isChangeable}
		onPress={isChangeable ? handleChangePress : undefined}
	>
		{props.isLoading ? Strings.loading : props.registration}
	</TableRow>
}
export const VehicleRow = _VehicleRow as FC<VehicleRowProps>
