import React, { Ref, useState } from "react";
import { Platform, StyleSheet } from "react-native";
import RNPickerSelect, { PickerSelectProps, PickerStyle } from "react-native-picker-select";
import useForwardRef from "../hooks/useForwardRef";
import InputWrapper, { InputWrapperProps } from "../inputWrapper/InputWrapper";
import Colours from "../styles/Colours";
import { __WEB__ } from "../constants";

const styles = StyleSheet.create({
    selectStyle: {
        height: 25,
        backgroundColor: "pink",
        display: "flex",
        justifyContent: "center",
    },
    inputWeb: {
        height: 25,
        padding: 0,
        fontFamily: "GTEestiDisplay-Regular",
        color: Colours.BLACK,
        lineHeight: 16,
        borderWidth: 0,
    },
    inputIOS: {
        height: 25,
        padding: 0,
        fontFamily: "GTEestiDisplay-Regular",
        color: Colours.BLACK,
        lineHeight: 16,
    },
    inputAndroid: {
        height: 25,
        padding: 0,
        fontFamily: "GTEestiDisplay-Regular",
        color: Colours.BLACK,
        lineHeight: 16,
    },
    iconContainer: {
        display: "none",
    },
    viewContainer: {
        flex: 1,
        justifyContent: "center",
    },
    placeholder: {
        color: Colours.GREY_60,
    },
});

type OwnProps = {
    placeholder?: string | PickerSelectProps['placeholder'];
    pickerStyle?: PickerStyle
}

export type SelectProps = OwnProps
    & Omit<PickerSelectProps, "style" | "placeholder">
    & Omit<InputWrapperProps, "focused" | "value" | "placeholder">;

function Select(props: SelectProps, ref: Ref<RNPickerSelect>) {
    const { placeholder: _placeholder, value, ...otherProps } = props;

    const pickerRef = useForwardRef<RNPickerSelect>(ref);

    const [focused, setFocused] = useState(false);

    const onContainerPress = () => {
        if (!!pickerRef && !!pickerRef.current) {
            if (Platform.OS === "android") {
                // @ts-ignore
                pickerRef.current.focus();
            } else {
                pickerRef.current.togglePicker(false);
            }
        }
    };

    const onOpen = () => {
        setFocused(true);
        props?.onOpen?.();
    };

    const onValueChange = () => {
        setFocused(false);
    };

    const onClose = () => {
        setFocused(false);
        props?.onClose?.();
    };

    const placeholder =
        (!_placeholder || typeof _placeholder === "string")
            ? {
                  label: _placeholder ?? "Select option",
                  value: null,
                  color: Colours.GREY_60,
              }
            : _placeholder;

    return (
        <InputWrapper
            {...otherProps}
            focused={focused}
            iconRight={__WEB__ ? undefined : "cheverondown"}
            iconRightProps={__WEB__ ? undefined : { small: true, onPress: onContainerPress }}
            onPress={onContainerPress}
        >
            <RNPickerSelect
                {...props}
                ref={Platform.OS === "ios" ? pickerRef : null}
                // @ts-ignore
                pickerProps={{ ref: Platform.OS === "android" ? pickerRef : null }}
                placeholder={placeholder}
                style={{
                    viewContainer: styles.viewContainer,
                    placeholder: styles.placeholder,
                    inputWeb: styles.inputWeb,
                    inputIOS: styles.inputIOS,
                    inputAndroid: styles.inputAndroid,
                    iconContainer: styles.iconContainer,
                    ...props.pickerStyle,
                }}
                useNativeAndroidPickerStyle={false}
                onOpen={onOpen}
                onDonePress={onValueChange}
                onClose={onClose}
            />
        </InputWrapper>
    );
}

export default React.forwardRef(Select);
