import React from "react";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Strings from "../../../constants/localization/localization";
import strings from "react/constants/localization/localization";
import { StyleSheet, View } from "react-native";
import { Routes } from "react/navigation/root/root.paths";
import { useNavigation } from "react/navigation/constants";
import Colours from "react/parkable-components/styles/Colours";

type SharingPoolRowProps = {
    showChangeBayText?: boolean;
};

export const SharingPoolRow = (props: SharingPoolRowProps) => {
    const navigation = useNavigation();
    const { showChangeBayText } = props;

    const showSharingPoolView = () => {
        navigation.push(Routes.SharingPoolView, {});
    };

    return (
        <View style={styles.rowContainer}>
            <TableRow
                condensed
                chevron
                iconLeft={"star"}
                chevronText={Strings.details}
                onPress={showSharingPoolView}
                backgroundColor={Colours.SALMON}
            >
                {showChangeBayText ? strings.all_these_spaces_from_sharing_pool : strings.sharing_pool_row}
            </TableRow>
        </View>
    );
};

const styles = StyleSheet.create({
    rowContainer: {
        marginBottom: 9,
    }
});
