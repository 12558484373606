import React, {useCallback, useEffect, useState} from 'react';
import {AppState, AppStateStatus, StyleSheet, View} from 'react-native';
import {connect} from "react-redux";
import { Routes } from "react/navigation/root/root.paths";
import {getCurrentParkingSession} from "../../../redux/actions/parking";
import {getEmployeeSubscriptionsByUser} from "../../../redux/actions/subscriptions";
import {IRootReducer} from "../../../redux/reducers/main";
import {INotification, NotificationBuilderProps, buildNotifications} from "./BuildNotifications";
import NotificationWidget from "./NotificationWidget";
import * as Push from "../../../navigation/pushNotifications/constants";
import {addNotificationListener} from "../../../navigation/pushNotifications/notificationListener";
import {getCurrentPosition, setErrorGetGPSLocation} from "../../../redux/actions/geoLocation";
import { EmployeeSubscription } from '../../../model/EmployeeSubscription';
import { handleMessageModal } from '../../../redux/actions/user';
import { ModalType } from '../../common/ModalMessageCard';
import { useMapSafeArea } from "../../../hooks/useSafeArea";
import {getParkAvailabilityNotifications} from "../../../redux/actions/parks";
import NotificationWidgetDropdown from "./NotificationWidgetDropdown";
import {ParkingRequest} from "../../../model/ParkingRequest";
import {
    useNextConfirmedParkingRequestForCurrentUser,
} from "../../../api/parking-request/parking-request.api";
import useShowOpenGateCallback from "../../../constants/accessControlConstants";
import { useParkSessions } from '../../../api/parkSession/parkSession.api';
import { usePark } from '../../../api/park';
import { useShowTandemChatBanner } from '../../../constants/tandemChatConstants';
import {useNavigation} from "../../../navigation/constants";
import {useAlertSnackbar} from "../../../root/alert-snackbar/alert-snackbar";
import moment from 'moment';
import {useFailedPayment, usePaymentsRequiringAuthentication} from "react/api/stripe/stripe.api";

type Props = ReturnType<typeof getReduxProps> & typeof actions;

function NotificationView(props: Props) {
    const { top } = useMapSafeArea();
    const navigation = useNavigation();
    const {dismissSnackBar} = useAlertSnackbar();

    const {
        currentSession,
        employeeSubscriptions,
        errorGetGPSLocation,
        setErrorGetGPSLocation,
        getCurrentPosition,
        handleMessageModal,
        getCurrentParkingSession,
        getEmployeeSubscriptionsByUser,
        availabilityNotifications,
        getParkAvailabilityNotifications,
        user
    } = props;

    const userId = user?.id;

    const { parkingRequest, mutate: mutateParkingRequest } = useNextConfirmedParkingRequestForCurrentUser(moment().add({ day: 1 }));

    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [autoEndedSessionId, setAutoEndedSessionId] = useState<string>();
    const [isSessionGoingUnavailable, setSessionGoingUnavailable] = useState<boolean>(false);
    const [isSessionWentUnavailable, setSessionWentUnavailable] = useState<boolean>(false);
    const [sessionBayChangedTo, setSessionBayChangedTo] = useState<string>();
    const [showOpenGate, setShowOpenGate] = useState(false);
    const {parkSessions} = useParkSessions(1);
    const previousSession = parkSessions?.length ? parkSessions[0] : undefined;
    const previousPark = usePark(previousSession?.park as number).park;
    const { failedPayments: paymentsRequiringAuthentication } = usePaymentsRequiringAuthentication();
    const { data: failedPayment } = useFailedPayment();
    
    const {
        showBanner: showTandemChatBanner,
        sessionId,
        tandemPodId,
    } = useShowTandemChatBanner(currentSession, previousSession);

    useShowOpenGateCallback(setShowOpenGate);

    const showOpenGateModal = () => {
        navigation.navigate(Routes.SessionSummary);
    }

    const navigationToTandemChat = () => {
        navigation.navigate(Routes.TandemChatView, {
            sessionId: sessionId ?? undefined,
            tandemPodId: tandemPodId ?? undefined,
        });
    };

    const getParkingActivity = () => {
        if(user) {
            getCurrentParkingSession();
            getEmployeeSubscriptionsByUser();
        }
    }

    const handleAppStateChange = (appState: AppStateStatus) => {
        console.log("appState = ", appState);
        if(appState === "active"){
            //gets set to undefined when removeEventListener is called, ie. on logout.
            void getParkingActivity();
            void mutateParkingRequest();
        }
    }

    useEffect(() => {
        void getParkingActivity();
    },[userId])

    const onNotificationReceived = useCallback((code: string, notif?: any) => {
        switch (code) {

            case Push.SessionEndedInUnavailablePark: {
                if (!!notif.sessionId) {
                    setAutoEndedSessionId(notif.sessionId);
                    setSessionWentUnavailable(true);
                }
                void getParkingActivity();
                return false
            }
            case Push.CurrentParkIsGoingUnavailable: {
                setSessionGoingUnavailable(true);
                void getParkingActivity();
                return false;
            }
            case Push.SessionBayChanged: {
                setSessionBayChangedTo(notif?.signage);
                void getParkingActivity();
                return false
            }
            case Push.ParkingStarted:
            case Push.ParkingEnded:
            case Push.ParkingRequestUpdated:
            case Push.ReservationTimedOut:
            case Push.ReservationCancelled:
            case Push.EmployeeSubscriptionCreated:
            case Push.EmployeeSubscriptionInvited:
            case Push.EmployeeSubscriptionUpdated:
            case Push.EmployeeSubscriptionCancelled:
            case Push.EmployeeSubscriptionPastDue:
            case Push.UnpaidEmployeeSubscription:
            case Push.EmployeeSubscriptionWentUnavailable:
            case Push.LeaseCreated:
            case Push.UnpaidLeaseEnded:
            case Push.UnpaidLease:
            case Push.LeaseWentUnavailable:
            case Push.LeaseEnded:
            case Push.PaymentActionRequired:
            case Push.AutoEndSession: {
                void getParkingActivity();
                return false;
            }

            default:
                return false;
        }
    }, [setAutoEndedSessionId, setSessionGoingUnavailable, setSessionWentUnavailable, getParkingActivity, setSessionBayChangedTo]);

    //GPS NOTIFICATION ERROR
    useEffect(() => {
        if(errorGetGPSLocation) {
            const interval = setInterval(() => {
                setErrorGetGPSLocation(false)
            }, 7000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [errorGetGPSLocation]);

    //Mount
    useEffect(() => {
        const subscription = AppState.addEventListener("change", handleAppStateChange);

        handleAppStateChange('active');

        const notificationListener = addNotificationListener(onNotificationReceived, "NotificationView");

        getParkAvailabilityNotifications();

        //Unmount
        return () => {
            subscription.remove();
            notificationListener.remove();
        }
    }, []);

    const navigate = navigation.navigate;

    const showCurrentSession = useCallback(() => {
        if(!currentSession?.startedAt){
            const params = {
                parkId: currentSession?.park,
                session: currentSession
            };

            return navigate(Routes.ConfirmStartReservationView, params)
        }else {
            return navigate(Routes.ActiveSession, {parkId: currentSession?.park})
        }
        dismissSnackBar();
    }, [navigate, currentSession]);
    const showParkingRequestView = useCallback((parkingRequest: ParkingRequest) => navigate(Routes.ConfirmedBookingView, {parkingRequestId: parkingRequest.id, backButtonToMapView: false}), [navigate]);
    const showEmployeeSubscription = useCallback((employeeSubscriptionId: number) => navigate(Routes.SingleSubscriptionView, {subscriptionId: employeeSubscriptionId}), [navigate]);
    const showSubscriptionListView = useCallback(() => navigate(Routes.SubscriptionListView), [navigate]);
    const showSubscriptionInvitation = useCallback((employeeSubscription: EmployeeSubscription) => {
        dismissSnackBar();
        handleMessageModal(true, ModalType.SubscriptionInvitation, employeeSubscription.id)
    },[]);
    const showEndedSession = useCallback(() => {
        dismissSnackBar();
        !!autoEndedSessionId && navigate(Routes.SessionSummary, {sessionId: autoEndedSessionId});
    }, [autoEndedSessionId]);

    const showRetryPaymentRequest = useCallback((sessionId: number) => navigate(Routes.RetryPaymentRequest, {sessionId}), [navigate]);

    const showParkDetailView = useCallback((parkId: number) => {
        console.log("showParkDetailView = useCallback() " + parkId) ;
        navigate(Routes.ParkDetailView, {parkId})
    }, [navigate]);

    useEffect(() => {

        const hasPaymentToAuthenticate = !!paymentsRequiringAuthentication?.length;
        const failedPayments = hasPaymentToAuthenticate ? paymentsRequiringAuthentication : (failedPayment ? [failedPayment] : []);
        const params: NotificationBuilderProps = {
            currentSession,
            previousPark,
            employeeSubscriptions: Object.values(employeeSubscriptions),
            parkingRequest,
            showCurrentSession,
            showParkingRequestView,
            showEmployeeSubscription,
            showSubscriptionListView,
            errorGetGPSLocation,
            setErrorGetGPSLocation,
            getCurrentPosition,
            showEmployeeSubscriptionInvitation: showSubscriptionInvitation,
            isSessionGoingUnavailable,
            isSessionWentUnavailable,
            showEndedSession,
            sessionBayChangedTo,
            failedPayments: failedPayments,
            showRetryPaymentRequest,
            removeSessionBayChangeNotification: () => setSessionBayChangedTo(undefined),
            availabilityNotifications,
            showParkDetailView,
            showOpenGate,
            showOpenGateModal,
            showTandemChatBanner,
            navigationToTandemChat,
        };
        

        const notifications = buildNotifications(params);
        setNotifications(notifications);
    }, [showOpenGate, previousPark, currentSession, parkingRequest, employeeSubscriptions,
        errorGetGPSLocation, setErrorGetGPSLocation, getCurrentPosition,
        showSubscriptionInvitation, showCurrentSession, showParkingRequestView, showEmployeeSubscription,
        showSubscriptionListView, showEndedSession, isSessionGoingUnavailable, isSessionWentUnavailable,
        sessionBayChangedTo, setSessionBayChangedTo, paymentsRequiringAuthentication, availabilityNotifications,
        showTandemChatBanner, failedPayment
    ]);

    if (notifications.length === 0) {
        return null;
    }

    return (
        <View style={[{top},styles.container]}>

            {notifications.map((notification: INotification, i: number) => {
                if(!notification.data){
                    return <NotificationWidget
                        key={i}
                        style={styles.notification}
                        notification={notification}/>
                }
                else{
                    return <NotificationWidgetDropdown
                        key={i}
                        style={styles.notification}
                        notification={notification}/>
                }
            })}
        </View>
    );
}

const getReduxProps = (state: IRootReducer) => {
    return {
        currentSession: state.parking.currentSession,
        employeeSubscriptions: state.subscriptions.employeeSubscriptions,
        errorGetGPSLocation: state.geoLocation.errorGetGPSLocation,
        availabilityNotifications: state.parks.availabilityNotifications,
        user: state.user.user
    };
};

const actions = {
    getCurrentParkingSession,
    getEmployeeSubscriptionsByUser,
    setErrorGetGPSLocation,
    getCurrentPosition,
    handleMessageModal,
    getParkAvailabilityNotifications
};

export default connect(getReduxProps, actions)(NotificationView as React.FunctionComponent) as React.FC;

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        width: "100%",
        paddingLeft: 72,
        paddingRight: 18,
        minHeight: 45
    },
    notification: {
        marginBottom: 3
    }
});
