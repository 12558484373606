import * as React from 'react';
import {StyleSheet, View, Keyboard} from "react-native";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Icons from "react/parkable-components/icon/Icons";
import Colours from "react/parkable-components/styles/Colours";
import {UserSearch} from "../../model/UserOptions";
import * as _ from 'lodash';
import {NavigationActions, NavigationInjectedProps, withNavigation} from "react-navigation";
import {connect} from "react-redux";
import { getActiveSubscriptions } from '../../constants/getActiveSubscriptions';
import { IRootReducer } from '../../redux/reducers/main';
import {ParkingType} from "../../model/Types";
import { Routes } from "../../navigation/root/root.paths";
import { useNavigation } from "../../navigation/constants";

type Props = {
    recentSearches: UserSearch[],
    push: Function,
    parkingType: ParkingType
};

function RecentSearches(props: Props & typeof actions & ReturnType<typeof getReduxProps>) {

    const navigation = useNavigation();

    const {
        recentSearches,
        push,
        setNavigationParams,
        activeSubscriptions,
        currentSession,
        parkingType
    } = props;

    if(!recentSearches){
        return null;
    }

    const list = _.sortBy(recentSearches, 'created').reverse();

    const onPressRecent = (recentSearch: UserSearch) => {
        Keyboard.dismiss();
        if (recentSearch.parkId) {
            const subInPark = activeSubscriptions.filter(s => s.park === recentSearch.parkId)[0];
            if(!!currentSession && !!currentSession.startedAt && currentSession.park === recentSearch.parkId) {
                push(Routes.ActiveSession);
            } else if(!!subInPark) {
                push(Routes.SingleSubscriptionView, {subscriptionId: subInPark.id})
            } else {
                push(Routes.ParkDetailView, {parkId: recentSearch.parkId, parkingType: parkingType});
            }
        } else {

            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                    params: {
                        animatedToSearchLocation: false,
                        bySearch: true,
                        ...recentSearch,
                    }}]
            })
        }
    };

    return <View>
        {list.slice(0,5).map(recentSearch =>
            <TableRow iconLeft={recentSearch.parkId ? Icons.pinparking : Icons.pinlocation1}
                      iconLeftProps={{iconStyle: recentSearch.parkId ? styles.parkIcon : {}}}
                      onPress={() => onPressRecent(recentSearch)}
                      chevron
                      key={recentSearch.text}>
                {recentSearch.text}
            </TableRow>)}
    </View>
}

const getReduxProps = (state:IRootReducer) => {
    const activeSubscriptions = getActiveSubscriptions(state.subscriptions.employeeSubscriptions);
    return {
        currentSession: state.parking.currentSession,
        activeSubscriptions,
    }
};

const actions = {
    setNavigationParams: NavigationActions.setParams,
};

export default connect(
    getReduxProps,
    actions
)(RecentSearches);

const styles = StyleSheet.create({
    parkIcon: {
        color: Colours.BLUE_TINT
    }
});
