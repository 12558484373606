import React, {useState} from 'react';
import {StyleSheet, View, Image, TouchableOpacity} from 'react-native';
import List from "../common/List";
import {connect} from "react-redux";
import Strings from "../../constants/localization/localization";
import TextInput from "../common/TextInput";
import {assignSensorToQRCode, retrieveSensorsByEUI} from "../../redux/actions/sensors";
import {Constants} from "../../constants/constants";
const arrowRight = require("../../resources/blueArrowRight.png");
const tick = require("../../resources/tickGreenCircle.png");
import Colors from "../../constants/colors";
import Colours from "react/parkable-components/styles/Colours";
import Button from "react/parkable-components/button/Button";
import Input from "react/parkable-components/input/Input";
import Text from "react/parkable-components/text/Text";
import {IRootReducer} from "../../redux/reducers/main";
import {Sensor} from "../../model/Sensor";
import {useNavigation} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import {AssignSensorProps} from "./AssignSensorQrCodeView";
import ParkableBaseView from "../common/ParkableBaseView";

const qrCodeLockup = require("react/resources/qrCodeLockup.png");

const getReduxProps = (state: IRootReducer) => {
    return {
        sensors: state.sensors.sensors,
        currentUserId: state.data.userId,
    }
}

const actions = {
    retrieveSensorsByEUI,
    assignSensorToQRCode,
}

const AssignSensorQrManualCodeView = (props: AssignSensorProps) => {

    const [scanned, setScanned] = useState(false);
    const [scannedCode, setScannedCode] = useState<string | undefined>(undefined);
    const [selectedSensor, setSelectedSensor] = useState<Sensor | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    const navigation = useNavigation();

    const onEUIChange = (text: string) => {
        props.retrieveSensorsByEUI(text);
    }

    const onSensorSelect = (sensor: Sensor) => {
        setSelectedSensor(sensor);
    }

    const onBackPress = () => {
        if (selectedSensor !== undefined) {
            setSelectedSensor(undefined);
        } else {
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            });
        }
    }

    const onSaveCode = () => {
        // @ts-ignore
        selectedSensor && scannedCode
            ? props.assignSensorToQRCode(selectedSensor?.eui, scannedCode)
            : setError(Strings.unrecognised_code);
        setScanned(false);
        setSelectedSensor(undefined);
        setScannedCode(undefined);
    }

    const renderSensorRow = ({item: sensor}: { item: Sensor }) => {
        return <TouchableOpacity style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            borderBottomWidth: 1,
            paddingHorizontal: 15,
            borderColor: Colors.ParkableXtraLightGrey,
        }} onPress={() => onSensorSelect(sensor)}>
            {sensor.reference === null
                ? <View style={{height: 40, width: 20, margin: 10}}/>
                : <View style={{justifyContent: 'flex-start'}}>
                    <Image source={tick} style={{height: 40, width: 20, margin: 10, resizeMode: 'contain'}}/>
                </View>}
            <Text grey style={styles.listViewItemText}>{sensor.eui}</Text>
            <View style={{justifyContent: 'center'}}>
                <Image source={arrowRight} style={{height: 40, width: 40, margin: 10}}/>
            </View>
        </TouchableOpacity>
    }

    return (<ParkableBaseView
            scrollable={false}
            onBackPress={onBackPress}>
            <View style={styles.base}>
                <Text h2 bold center style={styles.header}>{Strings.assign_sensor_qr}</Text>
            {selectedSensor
                ? <View style={{flex: 1}}>
                    <View style={{height: 100}}>
                        <Text grey>{Strings.enter_dev_eui}</Text>
                        <TextInput style={styles.simpleViewStyle} onChangeText={onEUIChange}
                                   autoCorrect={false}
                                   placeholder="Device EUI"
                                   maxLength={16}
                                   autoCapitalize={"characters"}
                                   selectionColor={Colours.BLUE}
                                   placeholderTextColor={Colours.GREY_10}/>
                    </View>
                    <View style={{flex: 1}}>
                        <List renderRow={renderSensorRow} data={props.sensors}/>
                    </View>
                </View>
                : <View style={styles.base}>
                    <View style={styles.imageContainer}>
                        <Image
                            style={styles.image}
                            source={qrCodeLockup}/>
                    </View>
                    <Text
                        style={{
                            marginTop: 36,
                            marginHorizontal: 0,
                            textAlign: "center",
                            color: Colours.BLACK
                        }}>
                        {props.route.params?.instructions ?? Strings.new_qr_code_location_desc}
                    </Text>

                    {error && (<Text style={styles.error}>{error}</Text>)}

                    <View>
                        <Input
                            style={{
                                marginRight: 9,
                                marginTop: 9,
                            }}
                            autoCorrect={false}
                            autoCapitalize={"characters"}
                            maxLength={7}
                            onChangeText={(v) => {
                                setScannedCode(v.trim().toUpperCase());
                                setScanned(true);
                            }}
                        />
                        <Button
                            disabled={scannedCode?.length !== 7}
                            center
                            style={{
                                marginTop: 9,
                            }}
                            textProps={{ h4: true }}
                            onPress={() => onSaveCode()}>
                            {Strings.submit}
                        </Button>
                    </View>
                </View>}
            </View>
    </ParkableBaseView>)
}

export default connect(getReduxProps, actions)(AssignSensorQrManualCodeView);


const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    error: {
        alignSelf: "center",
        marginTop: 9,
        marginBottom: 9,
        color: Colours.RED,
    },
    imageContainer: {
        flexDirection: "row",
    },
    image: {
        flex: 1,
        zIndex: -1,
        resizeMode: "contain",
        aspectRatio: 249 / 153,
        height: 200,
    },
    buttonStyle: {
        width: 250,
        height: 40
    },
    containerStyle: {
        flex: 1,
        borderColor: '#000'
    },
    header: {
        paddingVertical: 14,
    },
    simpleViewStyle: {
        marginTop: 10,
        height: 60,
    },
    listViewItemText: {
        fontFamily: Constants.FONT_BOLD,
        fontSize: 26,
        paddingVertical: 5,
        paddingHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
