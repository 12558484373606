import {useAccessGatesInPark} from "../api/accessGate/accessGate.api";
import {useEffect} from "react";
import moment from "moment";
import {useParkSessions} from "../api/parkSession/parkSession.api";

export default function useShowOpenGateCallback(
    setShowOpenGate: (showOpenGate: boolean) => void,
    parkId?: number,
) {
    const {parkSessions} = useParkSessions(1);
    const previousSession = parkSessions?.length ? parkSessions[0] : undefined;
    const {gates} = useAccessGatesInPark(previousSession?.park as number);

    useEffect(() => {
        if (parkId && (parkId !== previousSession?.park)) {
            return;
        }
        if (!gates?.length || !previousSession?.endedAt) {
            return;
        }
        const endedAt = moment(previousSession.endedAt);
        const now = moment();

        const milisec_diff = now.diff(endedAt);
        const fifteen_minutes_in_millisec = 15 * 60 * 1000;

        if (milisec_diff <= fifteen_minutes_in_millisec) {
            //within 15 minutes so display open gate button
            setShowOpenGate(true)

            const checkLastSessionAccessGateButtonPeriod = () => {

                const now = moment();
                const milisec_diff = now.diff(endedAt);

                if (milisec_diff > fifteen_minutes_in_millisec) {
                    //within 15 minutes so display open gate button
                    clearInterval(interval);
                    setShowOpenGate(false);
                }
            }

            //check every ten seconds
            const interval = setInterval(checkLastSessionAccessGateButtonPeriod, 10000);
            return () => clearInterval(interval);
        }
    }, [gates, previousSession]);
}
