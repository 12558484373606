import Colours from "react/parkable-components/styles/Colours";
import {Platform, StyleSheet} from "react-native";
import {theme} from "../../constants/theme";
import {PADDING} from "../../root/root.constants";


export const headingStyles = StyleSheet.create({
    container: {
        paddingHorizontal: theme.spacing(2),
        paddingVertical: theme.spacing(1),
    },
    text: {
        margin: 0,
        marginBottom: 0,
    },
});

export const contentStyles = StyleSheet.create({
    container: {
        paddingHorizontal: PADDING,
    },
    section: {
        marginTop: Platform.OS == "ios" ? theme.spacing(4) : theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
});

export const headerRowStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
    },
    helpIcon: {
        marginLeft: theme.spacing(1),
        marginTop: 7,
        width: 15,
        height: 15,
        opacity: 0.4
    }
});

export const legendStyles = StyleSheet.create({
    container: {
        flexDirection: "row",
        overflow: "hidden",
    },
    column: {
        flex: 1,
    },
    caption: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    captionText: {
        ...theme.typography.subtitle,
    },
});

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colours.WHITE,
    },
    dayRow: {
        flexDirection: "row",
        flex: 1,
        marginBottom: theme.spacing(1.5),
    },
    dayTileContainer: {
        flex:1,
        flexDirection: "row",
    },
    nextWeekText: {
        marginBottom: theme.spacing(2),
        //...theme.typography.subtitle,
    },
    moreWeeksButton: {
        backgroundColor: Colours.WHITE,
    }
});
