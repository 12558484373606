import React, {useCallback, useEffect, useState} from 'react';
import Card from "react/parkable-components/card/Card";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import Icons from "react/parkable-components/icon/Icons";
import {StyleSheet} from "react-native";
import Strings from '../../../constants/localization/localization';
import { Routes } from "react/navigation/root/root.paths";
import {useNavigation} from "../../../navigation/constants";

const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.WHITE,
        height: 63,
        paddingLeft: 9,
        marginTop: -45,
    },
    button: {
        height: "auto",
        flex: 1,
        justifyContent: 'space-between',
    }
});

type Props = {
    onSearchViewPressed: () => void
}

function SearchView({onSearchViewPressed}: Props) {

    const navigation = useNavigation();

    const [text, setText] = useState("");

    const onPress = useCallback(() => {
        onSearchViewPressed();
        navigation.push(Routes.FindParkView);
    }, [navigation]);

    useEffect(() => {
        //LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setText(Strings.where_are_you_going);
    }, []);

    return (
        <Card
            elevation={2}
            style={styles.base}>
            <Button
                style={styles.button}
                elevation={0}
                plain={true}
                onPress={onPress}
                mt={0}
                mb={0}
                iconRight={Icons.search}
                iconRightProps={{white: false}}
                textProps={{
                    h5:true,
                    bold:false,
                    style: {fontWeight:"100"}
                }}
                small
            >{text}</Button>
        </Card>
    );
}

export default SearchView;
