import ValueOf from "../types/ValueOf";

enum Icons {
    arrowboldbottomleft = "\u0061",
    arrowboldbottomright = "\u0062",
    arrowbolddown = "\u0063",
    arrowboldleft = "\u0064",
    arrowboldright = "\u0065",
    arrowboldtopleft = "\u0066",
    arrowboldtopright = "\u0067",
    arrowboldup = "\u0068",
    arrowlightbottomleft = "\u0069",
    arrowlightbottomright = "\u006a",
    arrowlightdown = "\u006b",
    arrowlightleft = "\u006c",
    arrowlightright = "\u006d",
    chargersingleplug = "\u006e",
    chargerdualplug = "\u006f",
    caroutlined = "\u0070",
    carfilled = "\u0071",
    carandbarrier = "\u0072",
    car = "\u0073",
    baysign = "\u0074",
    batterycharging = "\u0075",
    arrowsshrink = "\u0076",
    arrowsexpand = "\u0077",
    arrowlightup = "\u0079",
    arrowlighttopright = "\u007a",
    arrowlighttopleft = "\u0041",
    cheverondown = "\u0042",
    cheveronleft = "\u0043",
    cheveronright = "\u0044",
    cheveronup = "\u0045",
    dollar = "\u0046",
    dollarandcalculator = "\u0047",
    dollarfilled = "\u0048",
    dollaroutlined = "\u0049",
    electricvehicleplug = "\u004a",
    electricvehicleplugoutlined = "\u004b",
    flashlight = "\u004c",
    hamburger = "\u004d",
    key = "\u004e",
    qrcode = "\u004f",
    pinparking = "\u0050",
    pinlocation2filled = "\u0051",
    pinlocation2 = "\u0052",
    pinlocation1 = "\u0053",
    pincarparktop = "\u0054",
    pincarparkbottom = "\u0055",
    parkingfilled = "\u0056",
    paperplane = "\u0057",
    motorbike = "\u0058",
    moonandstars = "\u0059",
    lock = "\u005a",
    keyoutlined = "\u0030",
    refresh = "\u0031",
    refreshfilled = "\u0032",
    roadandtrees = "\u0033",
    shieldwithtick = "\u0034",
    star = "\u0035",
    starfilled = "\u0036",
    stopwatch = "\u0037",
    stopwatchfilled = "\u0038",
    stopwatchoutlined = "\u0039",
    sun = "\u0021",
    support = "\u0022",
    tick = "\u0023",
    tickfilled = "\u0024",
    mapcenterlocation = "\u0078",
    search = "\u0025",
    carandbarrieroutlined = "\u0026",
    calendar = "\u0027",
    linkexpand = "\u0028",
    checkboxtick = "\u0029",
    awardTrophyStar = "\u002a",
    reservedfilled = "\u002b",
    voucher = "\u002c",
    buildingwork = "\u002d",
    addIcon = "\u002e",
    resetOrClear = "\u002f",
    handwatch = "\u003C",
    multipleUsers = "\u003B",
    eyeCircle = "\u003A",
    phone = "\u003D",
    email = "\u003E",
    user = "\u003F",
    informationCircle = "\u0040",
    buttonRefreshArrows = "\u005B",
    calendarAdd = "\u005D",
    calendarEdit = "\u005E",
    hash = "\u005F",
    notesQuestion = "\u0060",
    phoneActionQuestion = "\u007B",
    timeClockCircleAlternate = "\u007C",
    qrscanner = "\u007e",
}

export type IconName = keyof typeof Icons | ValueOf<typeof Icons>;

export default Icons;
