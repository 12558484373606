import {Image, View} from "react-native";
import React, {useEffect, useState} from "react";
import {createRoute, NavigationProps, useNavigation} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import Colours from "react/parkable-components/styles/Colours";
import {scopes, style} from "./constants";
const splash = require("assets/meta/splash.png");
import {UserAgentApplication} from "msal";
import useConfig from "../../hooks/useConfig";
import * as MicrosoftTeams from "@microsoft/teams-js";
import {PublicClientApplication} from "@azure/msal-browser";


type Props = NavigationProps<Routes.TeamsAuthStart>;

const TeamsAuthStart =  (props: Props) => {

    const config = useConfig();
    const navigation = useNavigation();
    useEffect(() => {

        const fn = async () => {
            console.log("TEAMS inside teams auth start");
            const context = await MicrosoftTeams.app.getContext();
            console.log("TEAMS context = ", context);
            const msalConfig = {
                auth: {
                    clientId: config?.teamsClientId,
                    authority: config?.teamsAuthorityUrl + context?.user?.tenant?.id,
                    redirectUri: window.location.origin + "/teams-auth-end"
                },
            };


            try {
                console.log("TEAMS construct user agent app");
                const msalApp = new UserAgentApplication(msalConfig);
                console.log("TEAMS try login redirect");
                const request = {scopes: scopes, prompt: 'consent',
                    authority: config?.teamsAuthorityUrl + context?.user?.tenant?.id,
                    redirectUri: window.location.origin + "/teams-auth-end"};

                const res = request && request.redirectUri;
                console.log("TEAMS res: ", res);
                await msalApp.loginRedirect(request);
            } catch (err) {
                console.log("TEAMS err", err);
            }
        }
        void fn();
    }, []);

    return (
        <View style={[{flex: 1, backgroundColor: Colours.WHITE, margin: 10}]}>
            <Image style={style.image} source={splash} />
        </View>
    );
};

export default TeamsAuthStart;

export const TeamsAuthStartRoute = createRoute({
    path: Routes.TeamsAuthStart,
})



