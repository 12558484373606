import React, { PropsWithChildren } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Card, { CardProps } from "../card/Card";
import Icon, { IconProps } from "../icon/Icon";
import { IconName } from "../icon/Icons";
import Spinner from "../spinner/Spinner";
import Colours from "../styles/Colours";
import Text, { TextProps } from "../text/Text";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.GREEN,
        height: 54,
        alignItems: "center",
        flexDirection: "row",
        overflow: "hidden",
        position: "relative",
    },
    baseCenter: {
        justifyContent: "center",
    },
    large: {
        height: 72,
    },
    small: {
        height: 36,
    },
    form: {
        height: 45,
    },
    plain: {
        backgroundColor: "transparent",
    },
    border: {
        borderWidth: 1,
        borderColor: Colours.GREY_BORDER,
    },
    outlined: {
        borderWidth: 1,
        borderColor: Colours.GREY_BORDER,
        backgroundColor: "transparent",
    },
    blue: {
        backgroundColor: Colours.BLUE,
    },
    red: {
        backgroundColor: Colours.RED,
    },
    childrenContainerBase: {
        justifyContent: "center",
        paddingHorizontal: 18,
        maxWidth: "100%",
        flex: 1,
    },
    childrenContainerIconLeft: {
        paddingLeft: 0,
    },
    childrenContainerIconRight: {
        paddingRight: 0,
    },
    center: {
        alignItems: "center",
    },
    centerIcon: {
        flex: undefined, //revert flex:1 from childrenContainerBase
        paddingLeft: 9,
        paddingRight: 9,
    },
    textBase: {
        marginBottom: 0,
    },
    textCenter: {
        textAlign: "center",
    },
    textLabelBase: {
        marginTop: -6,
    },
    iconBase: {
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        padding: 9,
    },
    iconBaseCenter: {
        padding: 0,
        paddingLeft: 0,
        paddingRight: 0,
    },
    iconCenter: {
        width: undefined,
    },
    iconBaseSmall: {
        padding: 0,
    },
    iconRight: {},
});

const cn = classnames(styles);

export type ButtonProps = PropsWithChildren<
    {
        onPress?: () => void;
        textProps?: TextProps;
        label?: String;
        labelProps?: TextProps;
        iconLeft?: IconName;
        iconLeftProps?: Omit<IconProps, "name">;
        iconRight?: IconName;
        iconRightProps?: Omit<IconProps, "name">;

        childrenContainerStyle?: StyleProp<ViewStyle>;
        plain?: boolean;
        border?: boolean;
        outlined?: boolean;
        center?: boolean;
        blue?: boolean;
        red?: boolean;
        large?: boolean;
        small?: boolean;
        form?: boolean;
        loading?: boolean;
    } & CardProps
>;

export default function Button(props: ButtonProps) {
    const {
        children,
        onPress,
        textProps,
        label,
        labelProps,
        iconLeft,
        iconLeftProps,
        iconRight,
        iconRightProps,
        plain,
        border,
        outlined,
        blue,
        red,
        large,
        small,
        form,
        loading,
        disabled,
        ...otherProps
    } = props || {};

    const hasIconLeft = !!iconLeft;
    const hasIconRight = !!iconRight || !!loading;
    const hasIcon = hasIconLeft || hasIconRight;
    const center = !!props.center || form;

    const style = [
        cn.styles("base", {
            plain: plain,
            border: border,
            outlined: outlined,
            blue: blue,
            red: red,
            large: large,
            small: small,
            baseCenter: center,
            form: form,
        }),
        props.style,
    ];

    const childrenContainerStyle = StyleSheet.flatten([
        cn.styles("childrenContainerBase", {
            childrenContainerIconLeft: hasIconLeft,
            childrenContainerIconRight: hasIconRight,
            center: center,
            centerIcon: center && hasIcon,
        }),
        props.childrenContainerStyle,
    ]);

    const textStyle = [
        cn.styles("textBase", {
            textCenter: center,
        }),
        textProps?.style,
    ];

    const textLabelStyle = [styles.textLabelBase, labelProps?.style];
    const iconBase = cn.styles("iconBase", {
        iconBaseSmall: small,
        iconBaseCenter: center,
    });

    const iconLeftStyle = [
        cn.styles({
            iconCenter: center,
        }),
        iconLeftProps?.style,
    ];

    const iconRightStyle = [
        cn.styles({
            iconCenter: center,
        }),
        iconRightProps?.style,
    ];

    let iconRightComp = null;
    if (iconRight) {
        iconRightComp = (
            <Icon
                white
                name={iconRight}
                {...(iconRightProps || {})}
                style={iconRightStyle}
                small={small}
                large={large}
            />
        );
    }

    const loadingComponent = loading ? (
        <View style={styles.iconCenter}>
            <View style={iconBase}>
                <Spinner large={large} />
            </View>
        </View>
    ) : null;

    return (
        <Card
            elevation={0}
            {...otherProps}
            disabled={disabled}
            style={style}
            onPress={onPress || (() => {})}
        >
            {iconLeft && (
                <View style={iconBase}>
                    <Icon
                        white
                        name={iconLeft}
                        {...(iconLeftProps || {})}
                        style={iconLeftStyle}
                        small={small}
                        large={large}
                    />
                </View>
            )}

            <View style={childrenContainerStyle}>
                <Text
                    white={(!outlined && !plain) || !!disabled}
                    bold
                    h2={!small}
                    h5={outlined || form}
                    textBreakStrategy={"highQuality"}
                    {...(textProps || {})}
                    style={textStyle}
                >
                    {children}
                </Text>

                {label && large && (
                    <Text
                        white={!plain}
                        {...(labelProps || {})}
                        style={textLabelStyle}
                    >
                        {label}
                    </Text>
                )}
            </View>

            {loadingComponent}

            <View style={styles.iconRight}>
                {iconRightComp && <View style={iconBase}>{iconRightComp}</View>}
            </View>
        </Card>
    );
}
