import Text from "react/parkable-components/text/Text";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "../../../constants/localization/localization";
import { TouchableOpacity, View } from "react-native";
import React, { useRef } from "react";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import {PaymentDetails} from "react/api/stripe/dto/PaymentDetails";

type Props = {
    entity: PaymentDetails,
    currencyCode: string
}
export function ChargeDetails(props: Props) {

    const { entity } = props;
    
    const chargedFormatted = localizeCurrency(entity.amount??0, props.currencyCode)

    if(entity.transactionSuccess) {
        return (
            <TableRow iconLeft={'dollarfilled'} iconLeftProps={{color: Colours.GREEN}} label={Strings.amount_charged}
                      contentRight={(entity.amount ?? 0) > 0 &&
                      <View style={{flexDirection: 'row', alignItems: 'center'}}>
                          <TouchableOpacity style={{
                              backgroundColor: Colours.GREY_10,
                              borderRadius: 30, paddingHorizontal: 12, paddingVertical: 6
                          }} onPress={() => {}}>
                              <Text small>{Strings.payment_received}</Text>
                          </TouchableOpacity>
                      </View>
                      }>{chargedFormatted}</TableRow>)
    }
    else{
        return (<TableRow iconLeft={'dollarfilled'} iconLeftProps={{color: Colours.GREEN}} label={Strings.amount_charged}
                          contentRight={
                          <View style={{flexDirection: 'row', alignItems: 'center'}}>
                              <TouchableOpacity style={{
                                  backgroundColor: Colours.RED_LIGHT,
                                  borderRadius: 30, paddingHorizontal: 12, paddingVertical: 6
                              }} onPress={() => {}}>
                                  <Text small>{entity.transactionResponse === "authentication_required" ? Strings.authentication_required : Strings.payment_required}</Text>
                              </TouchableOpacity>
                          </View>
                          }>{chargedFormatted}</TableRow>)
    }
}
