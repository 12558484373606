import React, { PropsWithChildren } from "react";
import { StyleSheet } from "react-native";
import Card, { CardProps } from "../card/Card";
import Icon, { IconProps } from "../icon/Icon";
import { IconName } from "../icon/Icons";
import Colours from "../styles/Colours";
import classnames from "../util/classnames";

const FAB_SIZE = 45;

const styles = StyleSheet.create({
    base: {
        height: FAB_SIZE,
        width: FAB_SIZE,
        backgroundColor: Colours.WHITE,
        borderRadius: Math.round(FAB_SIZE / 2),
        elevation: 6,
        alignItems: "center",
        justifyContent: "center",
    },
    green: {
        backgroundColor: Colours.GREEN,
    },
});

const cn = classnames(styles);

export type FABProps = {
    onPress?: () => void;
    icon?: IconName;
    iconProps?: Omit<IconProps, "name">;
    green?: boolean;
} & CardProps;

export default function FAB(props: PropsWithChildren<FABProps>) {
    const {
        style: propStyle,
        onPress,
        icon,
        iconProps,
        green,
        ...otherProps
    } = props;

    const style = [
        cn.styles("base", {
            green: green,
        }),
        propStyle,
    ];

    return (
        <Card {...otherProps} style={style} onPress={onPress}>
            {icon && (
                <Icon
                    name={icon}
                    small
                    {...(iconProps || {})}
                    style={{ marginLeft: 1, marginTop: 1 }}
                />
            )}
            {props.children}
        </Card>
    );
}
