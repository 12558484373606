import {Term} from "./Term";
import {BayGroupType} from "./BayGroupType";
import {Park} from "./Park";
import {SharingReminderDay} from "./Types";

export enum EmployeeSubscriptionStatus {
    Invited = 'Invited',
    Active = 'Active',
    HostEnding = 'HostEnding',
    LeaserEnding = 'LeaserEnding',
    PastDue = 'PastDue',
    Deleted = 'Deleted',
    Ended = 'Ended',
    Pending = 'Pending',
    Processing = 'Processing',
    Unpaid = 'Unpaid'
}

export interface AvailableTo {
    //group: number, //deprecated
    date: string,
    createdAt: string | null,
}

export interface AvailabilityRules {
    availableTo: AvailableTo[],
}

export interface EmployeeSubscription {
    id: number,
    park: number,
    bay: number,
    status: EmployeeSubscriptionStatus,
    previousStatus: EmployeeSubscriptionStatus | null,
    createdAt: string,
    endedAt: string | null,
    organisation: number,
    group: number | null,
    owner: number,
    availabilityRules: AvailabilityRules | null,
    term: Term,
    termStartedAt: string | null,
    pricePerTerm: number,
    paymentFailed: string,
    groupType: BayGroupType,
    start: string | undefined,
    anchorDay: number|null,
    weekAnchorDay: string|null,
    vehicles: number[],
    nextPaymentAttempt: string | null,
}
