import React from "react";
import Text from "react/parkable-components/text/Text";
import Card from "react/parkable-components/card/Card";
import classnames from "react/parkable-components/util/classnames";
import Colours from "react/parkable-components/styles/Colours";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import Strings from "../../../constants/localization/localization";
import { usePark } from "react/api/park";
import { useClosestParks } from "../../map/parkableMapView/web/google/map.constants";
import { ParkPinWeb } from "../../map/parkableMapView/web/google/park/park-pin.web";
import { GoogleMap } from "../../map/parkableMapView/web/google/google.web";
import { useDirectionsPressHandler } from "./Map.utils";

const styles = StyleSheet.create({
    card: {
        height: 261,
        flex: 1,
        backgroundColor: Colours.WHITE,
        marginBottom: 26,
    },
    map: {
        flex: 1,
        width: "100%",
        height: "100%",
    },
    footer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 18,
    },
    address: {},
    container: {
        borderRadius: 20,
        margin: 10,
        backgroundColor: Colours.WHITE,
    },
    text: {
        textAlign: "center",
        width: "100%",
        fontSize: 20,
        fontWeight: "400",
        color: Colours.BLUE,
    },
});

const cn = classnames(styles);

type Props = {
    orgName?: string | undefined;
    isHideOrgName?: boolean;
    parkId?: number;
};

export default function LocationCard(props: Props) {
    const { park } = usePark(props.parkId);
    const { latitude, longitude } = park ?? {};

    const centerPoint =
        latitude !== undefined && longitude !== undefined ? { lat: latitude, lng: longitude } : undefined;

    const [mapParks] = useClosestParks({
        boundPoint: centerPoint, // Will produce lowest pin loading radius
        centerPoint,
    });

    const parkInfo = park?.id !== undefined ? mapParks[park.id] : undefined;

    const handleDirectionsPress = useDirectionsPressHandler(park);

    const renderFooter = () => {
        return (
            <View style={cn.styles("footer")}>
                <View style={{ flex: 4 }}>
                    <Text small numberOfLines={2} style={{ marginRight: 60, marginBottom: 0 }}>
                        {park?.address}
                    </Text>
                </View>
                <TouchableOpacity
                    onPress={handleDirectionsPress}
                    style={{
                        flex: 2,
                        flexDirection: "row",
                        alignItems: "flex-start",
                        justifyContent: "flex-end",
                        padding: 0,
                    }}
                >
                    <Text small style={{ color: Colours.BLUE, textAlign: "right" }}>
                        {Strings.directions}
                    </Text>
                </TouchableOpacity>
            </View>
        );
    };

    return (
        <Card style={cn.styles("card")}>
            <GoogleMap
                draggable={false}
                options={{
                    clickableIcons: false,
                    disableDefaultUI: true,
                }}
                center={centerPoint}
                zoom={16}
                style={{ height: 300, position: "relative" }}
            >
                {parkInfo !== undefined && <ParkPinWeb info={parkInfo} />}
            </GoogleMap>
            {renderFooter()}
        </Card>
    );
}
