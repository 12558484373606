import React, { PropsWithChildren } from "react";
import { StyleSheet, View } from "react-native";
import Card, { CardProps } from "../card/Card";
import Colours from "../styles/Colours";
import Text from "../text/Text";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    base: {
        backgroundColor: Colours.ORANGE,
        borderRadius: 0,
        borderLeftWidth: 4,
        alignItems: "center",
        flexDirection: "row",
        borderLeftColor: Colours.BLACK,
    },
    content: {
        paddingVertical: 9,
        paddingHorizontal: 18,
    },
    disabled: {
        backgroundColor: Colours.WHITE,
    },
});

const cn = classnames(styles);

export type BayNumberProps = PropsWithChildren<{} & CardProps>;

export default function BayNumber(props: BayNumberProps) {
    const { children, disabled, ...otherProps } = props;

    const style = cn.styles("base", {
        disabled: disabled,
    });

    return (
        <Card
            elevation={4}
            style={style}
            disabled={disabled}
            {...(otherProps || {})}
        >
            <View style={styles.content}>
                <Text bold grey={!!disabled} small>
                    {children}
                </Text>
            </View>
        </Card>
    );
}
