// @ts-ignore
import React from 'react';
import { View, StyleSheet } from 'react-native';
import {Park} from "../../model/Park";
import ImageModal from "../parkDetails/ImageModal";
import {ParkDTOWithTerritory} from "../../redux/actions/parks";
import {ParkDTO} from "../../model/ParkDTO";

export default function ParkImagesCarousel(props: Props) {
    const {park, height = 108, width = 108} = props;

    if((park?.images || []).length === 0){
        return <></>;
    }

    return (
        <View style={height == 108 ? styles.container : styles.activeContainer}>
            <ImageModal style={styles.imageScrollView}
                        zoomHeight={300}
                        imageBorderRadius={9}
                        imageHeight={height}
                        imageWidth={width}
                        images={park.images || []}/>
        </View>
    )
}

type Props = {
    park: Park | ParkDTOWithTerritory | ParkDTO;
    height?: number;
    width?: number;
}

const styles = StyleSheet.create({
    container:{
        marginBottom: 9
    },
    activeContainer:{
        marginBottom: 0
    },
    imageScrollView: {
        height: 120,
        width: '100%'
    },
});
