import React, { useEffect, useState } from "react";
import { View, TextInput, StyleSheet, Platform } from "react-native";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import CheckBox from "react/parkable-components/checkBox/CheckBox";
import classnames from "react/parkable-components/util/classnames";
import Colours from "react/parkable-components/styles/Colours";
import Strings from '../../../constants/localization/localization'
import { connect } from "react-redux";
import { addUserVehicle, setUser, updateUserVehicle } from "react/redux/actions/user";
import { IRootReducer } from "react/redux/reducers/main";
import { Feature } from "react/model/Bay";
import { Routes } from "react/navigation/root/root.paths";
import { Vehicle } from "react/model/Vehicle";
import { logEvent } from "react/analytics";
import { useUserVehicles } from "react/api/vehicle/vehicle.api";
import { createRoute, NavigationProps} from "react/navigation/constants";
import ParkableBaseView from "../../common/ParkableBaseView";
import { useParkingPrice } from "react/api/parkingPrice/parkingPrice.api";
import { isCreditCardRequired } from "react/services/parkingPrice.service";
import { useCurrentParkingSession } from "react/api/parking";
import { updateParkSessionVehicle } from "react/api/parkSession/parkSession.api";
import { setCurrentParkingSession } from "react/redux/actions/session";
import { useAppDispatch } from "react/redux/redux";
import { useCurrentUser } from "react/api/user/user.api";
import { vehiclesSelected } from "react/redux/actions/vehicles";

type Props = ReturnType<typeof getReduxProps>
  & typeof actions
  & NavigationProps<Routes.AddNewVehicleView>;

const AddNewVehicleView = (props: Props) => {
    const dispatch = useAppDispatch();
    const { user } = useCurrentUser();
    const {
        navigation, addUserVehicle, updateUserVehicle, userId, destination, hasCard, casualVoucher, park,
    } = props;
    const [rego, setRego] = useState<string|undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [showInputError, setShowInputError] = useState(false);
    const [addVehicleError, setAddVehicleError] = useState("");
    const [isMb, setIsMb] = useState(false);
    const [showSameRegoError,setShowSameRegoError] = useState(false);
    const { vehicles: userVehicles, mutate: mutateVehicles } = useUserVehicles();
    const { parkSession, mutate: mutateParkSession } = useCurrentParkingSession();
    const { pricePeriods } = useParkingPrice(parkSession?.parkingPrice??park?.parkingPrice);

    // TODO: remove all dispatch methods once redux is removed from active Session screen and confirm start parking screen
    useEffect(() => {
        if (user) {
            dispatch(setUser(user));
        }
    }, [user]);

    useEffect(() => {
        if (parkSession) {
            dispatch(setCurrentParkingSession(parkSession));
        }
    }, [parkSession]);

    const addVehicle = () => {
        if(!rego || !userId) {return;}
        const regex = /^[a-zA-Z\d\/\- ]+$/
        setAddVehicleError("");
        if (!regex.test(rego)) {
            setShowInputError(true)
        } else if(userVehicles?.map(v => v.registration).includes((rego || '').replace(/[/-]|\s/g,'').trim().toUpperCase())) {
            setShowInputError(false);
            setShowSameRegoError(true);
        }
        else {
            setShowInputError(false);
            setShowSameRegoError(false);
            setLoading(true);
            const feature = isMb ? Feature.MOTORBIKE : Feature.SEDAN;
            const _rego = (rego || '').replace(/[/-]|\s/g,'').trim().toUpperCase()
            addUserVehicle(userId, _rego, feature, onSuccess, onFail);
        }
    };

    const onSuccess = async (vehicle:Vehicle) => {
        const params = { userId: `${userId??0}`, vehicleId: `${vehicle.id}`, registration: vehicle.registration };
        logEvent(undefined,'vehicle_added',params,'vehicle_added',params);
        updateUserVehicle(userId!, vehicle.id, onFinish, onFinish);
        dispatch(vehiclesSelected([vehicle.id]))
        if (parkSession) {
            await updateParkSessionVehicle(parkSession.id, vehicle.id);
        }
        mutateParkSession();
        mutateVehicles();
    };

    const onFail = (err:any) => {
        console.log('FAIL TO ADD VEHICLE: ', err);
        setLoading(false);
        setAddVehicleError(err.message);
    };

    const onFinish = () => {
        setLoading(false);

        if (!destination) {
            navigation.goBack();
        } else if (park && pricePeriods && isCreditCardRequired(park, pricePeriods, hasCard, casualVoucher)) {
            navigation.navigate(Routes.AddNewCard, { destination });
        } else {
            // @ts-ignore
            navigation.navigate(destination.route, destination.params);
        }
    };

    const updateRego = (_rego:string = '') => {
        // https://github.com/facebook/react-native/issues/27449
        // Using `toUpperCase` or `textTransform: 'uppercase'` breaks on an Android controlled TextInput
        // _rego = (/[^ ].*/g.exec(_rego) || [''])[0].replace(" ", ""); //TS - commented out to allow regos of ireland that have spaces between them
        if(Platform.OS === 'android') {
            if(!!rego && !!_rego && _rego.length > rego.length) {
                if(_rego.indexOf(rego) === 0) {
                    //appending, RN bug happens
                    _rego = rego + _rego.substr(_rego.length - 1);
                } else if(_rego.indexOf(rego) === -1) {
                    //inserting, RN bug not happens here
                } else {
                    console.log('Unexpected condition', {_rego, rego})
                }

            }
        }
        setRego(Platform.OS === 'android' ? _rego : _rego.toUpperCase());
    };

    const inputStyle = cn.styles("regoInput", {
        notPlaceholder: !!rego?.length,
    });

    return  <ParkableBaseView loading={loading}>
        <View style={styles.base}>
            <Text h1 style={{marginTop: 10}}>{Strings.what_is_your_rego}</Text>
            <View style={styles.rego}>
                <View style={[styles.pin, styles.pinTL]} />
                <View style={[styles.pin, styles.pinTR]} />
                <View style={[styles.pin, styles.pinBL]} />
                <View style={[styles.pin, styles.pinBR]} />
                <TextInput  placeholderTextColor={Colours.GREY_10}
                            placeholder={Strings.start_typing}
                            style={inputStyle} maxLength={15}
                            autoFocus={false}
                            value={rego}
                            autoCapitalize="characters"
                            onChangeText={updateRego} />

            </View>


            {showInputError && <Text small red>{Strings.oops_please_use_only_alphanumeric_characters}</Text>}
            {addVehicleError && <Text small red>{addVehicleError}</Text>}
            {showSameRegoError && <Text small red>{Strings.this_registration_is_already_added}</Text>}
            <View style={{marginTop: 30}}>
                <CheckBox onChange={() => setIsMb(prev => !prev)} checked={isMb}>{Strings.this_is_motorbike}
                    {/*TODO - EXPO : add icons font*/}
                    {/*<Text style={{fontFamily: Platform.OS === "ios" ? "parkableIcons" : "parkableicons", fontSize: 16}}>{" X"}</Text>*/}
                </CheckBox>
            </View>
            <Button style={{marginTop: 18, justifyContent: 'space-between'}} iconRight={"arrowboldright"} disabled={!rego} onPress={addVehicle}>{Strings.continue}</Button>
        </View>
    </ParkableBaseView>
};

const actions = {
    addUserVehicle,
    updateUserVehicle,
};

const getReduxProps = (state: IRootReducer, props: NavigationProps<Routes.AddNewVehicleView>) => {
    const vehicles = state.user.vehicles || [];
    const hasCard = !!state.user.currentCardId;
    const { destination } = props.route.params || {};
    const park = destination?.params?.park;

    return {
        vehicles,
        userId: state.data.userId,
        hasCard,
        destination,
        park,
        casualVoucher: state.user?.casualVoucher?.[park?.id],
    }
};

class AddNewVehicleRouteParams {
    destination?: {
        route: Routes,
        params: any,
    };
}

export const AddNewVehicleRoute = createRoute({
    path: Routes.AddNewVehicleView,
    params: {
        type: AddNewVehicleRouteParams,
        deserialize: d => ({
            destination: d.Json(),
        })
    }
});

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    rego: {
        position: 'relative',
        borderColor: Colours.BLACK,
        borderWidth: 5,
        height: 120,
        borderRadius: 9,
    },
    pin: {
        position: 'absolute',
        height: 7,
        width: 7,
        backgroundColor: Colours.BLACK,
        borderRadius: 3.5,
    },
    pinTL: {
        top: 5,
        left: 5
    },
    pinTR: {
        top: 5,
        right: 5
    },
    pinBL: {
        bottom: 5,
        left: 5
    },
    pinBR: {
        bottom: 5,
        right: 5
    },
    regoInput: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        fontSize: 35,
        alignItems: "center",
        textAlign: 'center',
        fontFamily: "GTEestiDisplay-Bold",
    },
    notPlaceholder: {
        fontSize: 40,
        letterSpacing: 5,
    }
});

const cn = classnames(styles);

export default connect(getReduxProps, actions)(AddNewVehicleView) as React.FunctionComponent<Props>;
