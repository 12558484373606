import { ScrollView, StyleSheet, View } from "react-native";
import { useEffect, useMemo, useState } from "react";
import Colours from "react/parkable-components/styles/Colours";
import Select from "react/parkable-components/select/Select";
import Text from "react/parkable-components/text/Text";
import Strings from "../../constants/localization/localization";
import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import ParkableBaseView from "../common/ParkableBaseView";
import { Item } from "react-native-picker-select";
import moment, { Moment } from "moment-timezone";
import getParkAvailability from "react/constants/getParkAvailability";
import { usePark } from "react/api/park";
import { useTerritory } from "react/api/territory/territory.api";
import { useCalculateEstimatedAmountByParkingPrice, useParkingPrice } from "react/api/parkingPrice/parkingPrice.api";
import localizeCurrency from "react/constants/localization/localizeCurrency";
import StandardRatesTable from "react/components/pricing/StandardRatesTable";
import CustomRatesTable from "react/components/pricing/CustomRatesTable";
import { getDayAvailability } from "react/model/Availability";
import getOpeningTime from "react/constants/availability/getOpeningTime";
import {getDayOfWeek} from "react/constants/Util";

class PricingViewProps {
    parkId: number;
    parkingPrice: number;
    startTime?: string | null;
}

const timeFormat = "h:mma";
const dayFormat = "dddd";
const minuteStep = 15;

const getRoundedTime = (time: Moment) => {
    const quotient = time.minute() % minuteStep;
    const remainder = quotient === 0 ? 0 : minuteStep - quotient;
    return time.add({ minute: remainder });
};

export default function PricingView(props: NavigationProps<Routes.PricingView>) {
    const { parkId, parkingPrice, startTime: _startTime } = props.route.params;
    const { park } = usePark(parkId);
    const { pricePeriods } = useParkingPrice(parkingPrice);
    const { territory } = useTerritory(park?.territory);

    const timeZone = park?.timeZoneId ?? "Pacific/Auckland";
    const time = getRoundedTime(moment(_startTime ? _startTime : undefined).startOf("minute").tz(timeZone));

    const [startTime, setStartTime] = useState(time);
    const [endTime, setEndTime] = useState(_startTime ? getRoundedTime(moment().tz(timeZone)) : moment(time).add({ hour: 1 }));

    const midnight = moment(time).startOf("day").add({ day: 1 });
    const parkAvailability = useMemo(() => (park ? getParkAvailability(park) : null), [park]);
    const isOpen24hours = parkAvailability?.isOpen24hours;
    const openingTime = useMemo(() => {
        const dayOfWeek = !!startTime ? startTime.weekday() : undefined;
        const openingTime = !!parkAvailability && !!dayOfWeek ?
                getOpeningTime(getDayAvailability(getDayOfWeek(dayOfWeek).toLowerCase(), parkAvailability.availability)) : undefined;
        return openingTime && moment(openingTime, timeFormat).isValid()
            ? moment(openingTime, timeFormat).tz(timeZone)
            : getRoundedTime(moment().tz(timeZone).startOf("day"));
    }, [parkAvailability, startTime])

    const closingTime =
        parkAvailability?.closingTime && moment(parkAvailability.closingTime, timeFormat).isValid() && !isOpen24hours
            ? moment(parkAvailability.closingTime, timeFormat).tz(timeZone)
            : midnight;

    const daysAvailable = useMemo(() => parkAvailability
        ? moment
              .weekdays()
              .filter((weekday) => getDayAvailability(weekday.toLowerCase(), parkAvailability.availability).available)
        : [], [parkAvailability]);

    useEffect(() => {
        if(daysAvailable.find((dayAvailable) => dayAvailable === startTime.format(dayFormat))){
            return;
        }

        let day = moment(startTime).add({ day: 1 });
        while (!daysAvailable.find((dayAvailable) => dayAvailable === day.format(dayFormat))) {
            day.add({ day: 1 });
        }

        if(startTime.day() !== day.day()){
            setStartTime(getRoundedTime(day));
        }
    }, [daysAvailable, startTime]);

    useEffect(() => {
        if(daysAvailable.find((dayAvailable) => dayAvailable === endTime.format(dayFormat))){
            return;
        }

        let day = moment(endTime).add({ day: 1 });
        while (!daysAvailable.find((dayAvailable) => dayAvailable === day.format(dayFormat))) {
            day.add({ day: 1 });
        }

        if(endTime.day() !== day.day()){
            setEndTime(getRoundedTime(endTime));
        }
    }, [daysAvailable, endTime]);

    const startTimeOptions = useMemo(() => {
        const timeOptions: Item[] = [];
        const start = getRoundedTime(moment(openingTime));
        const end = getRoundedTime(moment(closingTime));
        while (start.isBefore(end)) {
            timeOptions.push({
                label: start.format(timeFormat),
                value: start.format(timeFormat),
            });
            start.add({ minute: minuteStep });
        }
        return timeOptions;
    }, [openingTime, closingTime]);

    const endTimeOptions = useMemo(() => {
        const timeOptions: Item[] = [];
        const start = isOpen24hours ? moment().startOf("day").tz(timeZone) : getRoundedTime(moment(startTime));
        const end = moment(closingTime).isBefore(start)
            ? getRoundedTime(moment(closingTime)).add({ day: start.get("day") })
            : getRoundedTime(moment(closingTime));

        while (start.isSameOrBefore(end)) {
            const option = start.format(timeFormat);
            if(!timeOptions.find(o => o.value === option)){
                timeOptions.push({
                    label: option,
                    value: option,
                });
            }
            start.add({ minute: minuteStep });
        }
        return timeOptions;
    }, [startTime, closingTime]);

    const { cost, durationInMinutes } = useCalculateEstimatedAmountByParkingPrice(
        parkingPrice,
        startTime.toISOString(true),
        endTime.isBefore(startTime) ? moment(endTime).add({ week: 1 }).toISOString(true) : endTime.toISOString(true)
    );

    const durationToHours = durationInMinutes ? Math.floor(durationInMinutes / 60) : undefined;
    const durationToMinutes = durationInMinutes ? durationInMinutes % 60 : undefined;

    return (
        <ParkableBaseView scrollable={false}>
            <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
                <Text h1 bold>
                    {Strings.calculate_parking_cost}
                </Text>
                <Text p grey>
                    {Strings.calculate_parking_cost_description}
                </Text>

                <View style={styles.estimateCostBox}>
                    <Text bold style={styles.label}>
                        {Strings.start_parking}
                    </Text>
                    <View style={styles.fieldsBox}>
                        <View style={styles.field}>
                            <Select
                                items={daysAvailable.map((day) => ({ label: day, value: day }))}
                                value={startTime.format(dayFormat)}
                                onValueChange={(value) => {
                                    const dayIndex = moment.weekdays().findIndex((weekday) => weekday === value);
                                    const newStartTime = moment(startTime).set({ day: dayIndex });
                                    setStartTime(newStartTime);
                                    if (!isOpen24hours) {
                                        const newEndTime = moment(endTime).set({ day: dayIndex });
                                        if (newStartTime.isAfter(newEndTime)) {
                                            newEndTime.add({ day: 1 });
                                        }
                                        setEndTime(newEndTime);
                                    }
                                }}
                            />
                        </View>
                        <View style={styles.field}>
                            <Select
                                items={startTimeOptions}
                                value={startTime.format(timeFormat)}
                                onValueChange={(value) => {
                                    const newValue = moment(value, timeFormat);
                                    const newStartTime = moment(startTime).set({
                                        hour: newValue.hour(),
                                        minute: newValue.minute(),
                                    });
                                    setStartTime(newStartTime);
                                    if (!isOpen24hours && newStartTime.isAfter(endTime)) {
                                        setEndTime(moment(newStartTime).add({ minute: minuteStep }));
                                    }
                                }}
                            />
                        </View>
                    </View>
                    <Text bold style={styles.label}>
                        {Strings.stop_parking}
                    </Text>
                    <View style={styles.fieldsBox}>
                        <View style={styles.field}>
                            <Select
                                items={daysAvailable.map((day) => ({ label: day, value: day }))}
                                value={endTime.format(dayFormat)}
                                onValueChange={(value) => {
                                    const dayIndex = moment.weekdays().findIndex((weekday) => weekday === value);
                                    setEndTime((endTime) => moment(endTime).set({ day: dayIndex }));
                                    if (!isOpen24hours) {
                                        if (!moment(endTime).isSame(moment(endTime).startOf("day"))) {
                                            setStartTime((startTime) => moment(startTime).set({ day: dayIndex }));
                                        }
                                    }
                                }}
                            />
                        </View>
                        <View style={styles.field}>
                            <Select
                                items={endTimeOptions}
                                value={endTime.format(timeFormat)}
                                onValueChange={(value) => {
                                    const newValue = moment(value, timeFormat);
                                    setEndTime((endTime) =>
                                        moment(endTime).set({
                                            hour: newValue.hour(),
                                            minute: newValue.minute(),
                                        })
                                    );
                                }}
                            />
                        </View>
                    </View>
                    <View style={styles.costContainer}>
                        <Text bold style={styles.costLabel}>
                            {`${localizeCurrency(cost ?? 0, territory?.currencyCode, false, false)}*`}
                        </Text>
                        <Text p style={styles.durationText}>
                            {Strings.park_session_duration(durationToHours ?? 0, durationToMinutes ?? 0)}
                        </Text>
                    </View>
                    <Text grey small style={styles.disclaimerText}>
                        {Strings.cost_is_an_estimate_and_may_change}
                    </Text>
                </View>
                <View style={styles.border} />
                <View>
                    <StandardRatesTable park={park} pricePeriods={pricePeriods} territory={territory} />
                    <CustomRatesTable park={park} pricePeriods={pricePeriods} territory={territory} />
                </View>
            </ScrollView>
        </ParkableBaseView>
    );
}

export const PricingViewRoute = createRoute({
    path: Routes.PricingView,
    params: { type: PricingViewProps },
});

const styles = StyleSheet.create({
    container: {
        marginBottom: 30,
    },
    estimateCostBox: {
        paddingTop: 7,
    },
    fieldsBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    field: {
        minWidth: "49%",
    },
    costLabel: {
        paddingTop: 24,
        paddingLeft: 17,
        fontSize: 30,
    },
    durationText: {
        marginRight: 18,
        paddingTop: 20,
        color: Colours.GREY_80,
    },
    label: {
        fontSize: 13,
        color: Colours.GREY_70,
        textTransform: "uppercase",
    },
    border: {
        borderTopWidth: 2,
        borderColor: Colours.GREY_BORDER,
        marginTop: 10,
        paddingBottom: 24,
    },
    costContainer: {
        backgroundColor: "#EEF7FF",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        borderRadius: 4,
    },
    disclaimerText: {
        marginTop: 7,
        paddingBottom: 17,
    },
});
