import React from 'react';
import { ParkingType } from '../../model/Types';
import { TableRow } from 'react/parkable-components';
import Strings from "../../constants/localization/localization";
import localizeCurrency from "../../constants/localization/localizeCurrency";
import { View } from 'react-native';
import {ParkDTO} from "../../model/ParkDTO";
import {TerritoryDTO} from "../../api/territory/dto/TerritoryDTO";
import {Territory} from "../../model/Territory";
import {ParkDTOWithTerritory} from "../../redux/actions/parks";
import {Routes} from "react/navigation/root/root.paths";
import {useNavigation} from "react/navigation/constants";
import {useParkingPrice} from "react/api/parkingPrice/parkingPrice.api";
import {
    getCurrentDailyPricePeriod,
    getCurrentHourlyPricePeriod,
} from "react/services/parkingPrice.service";
import {BayDTO} from "react/model/BayDTO";
import {useBayGroup} from "react/api/bayGroup/bayGroup.api";
import {Bay} from "react/model/Bay";
import moment from "moment-timezone";
import {Moment} from "moment";
import Spinner from "../../parkable-components/spinner/Spinner";
import Text from "../../parkable-components/text/Text";

type Props = {
    parkingType?: ParkingType,
    park?: Pick<ParkDTO| ParkDTOWithTerritory, "id" | "availability" | 'parkingPrice' | 'organisation' | 'timeZoneId'>,
    territory?: TerritoryDTO | Territory,
    bay?: Pick<BayDTO | Bay, 'group'>,
    parkingPrice?: number | null;
    time?: string;
    date?: Moment;
}

const casualCharge = (props: Props) => {
    const { parkingType, park, bay, parkingPrice: parkingPriceProp, territory, time, date } = props;

    const { bayGroup } = useBayGroup(park?.organisation, bay?.group);
    const parkingPrice = parkingPriceProp || bayGroup?.parkingPrice || park?.parkingPrice
    const { pricePeriods } = useParkingPrice(parkingPrice);

    const navigation = useNavigation();

    const timeZone = park?.timeZoneId ?? "Pacific/Auckland";
    const timeForPeriod = time??moment().tz(timeZone).format("HH:mm")

    const currentHourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;
    const currentDailyPricePeriod = !!pricePeriods ? getCurrentDailyPricePeriod(pricePeriods, timeForPeriod, date) : null;
    const cap = currentDailyPricePeriod?.cap ?? 24;

    const parkFreePeriodInMinutes = pricePeriods?.find(p => p.freePeriodMinutes !== null)?.freePeriodMinutes ?? 0;
    const freePeriodToHours = parkFreePeriodInMinutes ? Math.floor(parkFreePeriodInMinutes / 60) : undefined;
    const freePeriodToMinutes = parkFreePeriodInMinutes ? parkFreePeriodInMinutes % 60 : undefined;

    if (!park) {
        return (
            <TableRow
                condensed
                contentLeft={
                    <View>
                        <Spinner />
                    </View>
                }
                label={Strings.pricing}
            >
                {Strings.loading}
            </TableRow>
        );
    }

    if (!parkingPrice || !currentDailyPricePeriod || !currentHourlyPricePeriod) {
        return <View/>
    }

    if (parkingType == ParkingType.LONG_TERM) {
        return <View/>
    }

    const pricePerHour = currentHourlyPricePeriod.price;
    const pricePerDay = currentDailyPricePeriod.price;

    if(pricePerHour === 0 && pricePerDay === 0){
        return <View/>
    }

    const localPricePerHour = localizeCurrency(pricePerHour, territory?.currencyCode, false);
    const pricePerHourString = Strings.pricePerHour(localPricePerHour);
    const localPricePerDay = localizeCurrency(pricePerDay, territory?.currencyCode, false);
    const pricePerDayString = `${localPricePerDay} ${Strings.for_up_to_X_hours(cap)}`;

    const hoursText = freePeriodToHours === 1 ? Strings.hour : Strings.hours;
    const minutesText = freePeriodToMinutes === 1 ? Strings.minute : Strings.minutes;

    const getHoursAndMinutesText = () => {
        if (freePeriodToHours === 0 && (freePeriodToMinutes ?? 0) > 0) {
            return `${freePeriodToMinutes} ${minutesText}`
        }
        if (freePeriodToMinutes === 0) {
            return `${freePeriodToHours} ${hoursText}`
        }
        return `${freePeriodToHours} ${hoursText} and ${freePeriodToMinutes} ${minutesText}`
    }

    const displayText = parkFreePeriodInMinutes > 0
        ? `${Strings.pricing_with_free_period(
        getHoursAndMinutesText(),
        localPricePerHour,
        localPricePerDay,
        cap)}`
        : `${pricePerHourString} (${pricePerDayString})`;

    const onCasualPricePress = () =>{
        navigation.push(Routes.PricingView, {
            parkId: park.id,
            parkingPrice: parkingPrice
        });
    }

    return (<View>
        <TableRow
            condensed
            style={{ marginRight: 20 }}
            textProps={{ small: true, numberOfLines: 2 }}
            iconLeft={"dollarfilled"}
            label={Strings.pricing}
            onPress={onCasualPricePress}
            chevron
        >
            <Text
              small
              numberOfLines={2}
              style={{ width: "70%" }}
            >
                {displayText}
            </Text>
        </TableRow>
    </View>
    );

}

export default casualCharge;
