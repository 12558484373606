import { useMySWR } from "../http/use-swr";
import {SupportCaseResponse, SupportCasesResponse} from "./dto/SupportCase.dto";
import {Nully} from "react/constants/nully";
import {get} from "react/api/http/rest";

export const useSupportCases = () => {
    return useMySWR<SupportCasesResponse>(`v1/salesforce/cases`, get, {refreshInterval:5000});
}

export const useSupportCase = (caseId: Nully<string>) => {
    return useMySWR<SupportCaseResponse>(caseId ? `v1/salesforce/case/${caseId}`: undefined);
}
