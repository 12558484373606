/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/09/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, {FC} from "react";
import {Dimensions, Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import moment from "moment/moment";
import { useUnshareBayContext } from "../parkingViews/Subscriptions/context";

interface SiteAvailabilityTileDayProps {
    onDayTilePress: (key: string, day: moment.Moment) => void,
    dayKey: string,
    dayHere: moment.Moment,
    day: number,
    dayGone: boolean,
    available: boolean,
    isSelectedDay: boolean,
    isBookedDay: boolean
    isActivesession: () => boolean
}

const SCREEN_WIDTH = Dimensions.get("window").width;

const SiteAvailabilityTileDay: FC<SiteAvailabilityTileDayProps> = (props) => {

    const {onDayTilePress, dayKey, dayHere, day, dayGone, available, isSelectedDay, isBookedDay, isActivesession} = props;
    /*shouldComponentUpdate(props) {
        const wasSelectedDay = props.isSelectedDay;
        const isSelectedDay = props.isSelectedDay;
        return ((!wasSelectedDay && isSelectedDay) || (wasSelectedDay && !isSelectedDay));
    }*/

    const context = useUnshareBayContext()
    const disabled = dayGone || !available;
    const backgroundColorStyle = !isSelectedDay ? Colours.TRANSPARENT : isBookedDay || isActivesession() ?  Colours.BLUE : Colours.GREEN
    const borderColorStyle = !isSelectedDay ? Colours.GREY_BORDER : isBookedDay || isActivesession() ?  Colours.BLUE : Colours.GREEN

    return (
        <TouchableOpacity style={styles.dayOfWeekContainer} onPress={() => onDayTilePress(dayKey, dayHere)} disabled={disabled}>
            <View style={styles.circleContainer}>
                <View style={[styles.blueCircle, {
                    backgroundColor: backgroundColorStyle,
                    borderColor: borderColorStyle
                }]}/>
            </View>
            {/*When tiles are not disabled the opacity is set to 0.99 rather than 1.*/}
            {/*This is done because opacity is set in Animated.View within the TouchableOpacity component, therefore overrides the style prop.*/}
            <Text style={[styles.dayTilesText, disabled ? {opacity: 0.25} : {opacity: 0.99}]} bold white={props.isSelectedDay}>{day}</Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    dayOfWeekContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 8,
        marginVertical: 3,
    },
    dayTilesText: {
        fontSize: 18,
    },
    circleContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colours.WHITE,
    },
    blueCircle: {
        ...Platform.select({
            native: {
                height: (SCREEN_WIDTH - 21) / 10,
                width: (SCREEN_WIDTH - 21) / 10,
            },
            web: {
                flex: 1,
                maxWidth: "90%",
                aspectRatio: 1,
            }
        }),
        borderRadius: 3,
        opacity: 1,
        margin: 3,
        borderWidth: 1,
    },
});

export default SiteAvailabilityTileDay;
