import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet, View, Keyboard, Platform, ScrollView, Clipboard} from 'react-native';
import {Text, Icon, Colours} from 'react/parkable-components';
import MessageInput from '../common/MessageInput';
import Strings from "../../constants/localization/localization";
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import { useTandemPod } from '../../api/tandem/tandem.api';
import {useAlertSnackbar} from "../../root/alert-snackbar/alert-snackbar";
import ParkableBaseView from "../common/ParkableBaseView";
import {PADDING} from "../../root/root.constants";


export const BubbleLeft = (props: {content: string | null | undefined, allowToCopyToClipboard: boolean | undefined}) => {
    const { content, allowToCopyToClipboard } = props;
    const {showSnackBar} = useAlertSnackbar();

    const copyToClipboard = async () => {
        if (content) {
            await Clipboard.setString(content);
            showSnackBar({text: Strings.copied, hideDismiss: true, });
        }
    };

    const message = <Text small>
        {content}
    </Text>;

    return <View style={{flexDirection: 'row'}}>
        <Icon name="support" color="grey"/>
        <View style={styles.leftBox}>
            <View style={styles.leftBoxArrow}/>
            <View>
                {allowToCopyToClipboard ? <TouchableOpacity onPress={copyToClipboard}>
                    {message}
                </TouchableOpacity> : message}
            </View>
        </View>
    </View>
};

export const BubbleRight = (props: {content: string | null | undefined }) => {
    return <View style={{flexDirection: 'row'}}>
        <View style={styles.rightBox}>
            <View style={styles.rightBoxArrow}/>
            <View>
                <Text small white>
                    {props.content}
                </Text>
            </View>
        </View>
    </View>
};

export const BubbleRightWithAction = (props: {content: string | null | undefined, problemAction: any}) => {
    return <TouchableOpacity onPress={props.problemAction}>
        <View style={{flexDirection: 'row'}}>
            <View style={styles.rightBoxWithAction}>
                <View>
                    <Text small bold>
                        {props.content}
                    </Text>
                </View>
            </View>
        </View>
    </TouchableOpacity>
};

export const TandemBubbleRightOption = (props: { content: any; problemAction: any }) => {
    const { tandemPod } = useTandemPod(props.content.tandemPodId);
    const title = tandemPod?.name ?? props.content.title;
    return <BubbleRightWithAction problemAction={props.problemAction} content={title} />;
};

export const Chat = (props: any) => {
    const containerView = useRef<any>(null);
    const [keyboardHeight, setKeyboardHeight] = useState<number>(0);
    const [inputHeight, setInputHeight] = useState<number>(0);

    useEffect(() => {
        const keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', event => {
            setKeyboardHeight(event.endCoordinates.height);
        });
        const keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', () => {
            setKeyboardHeight(0);
        });
        return () => {
            keyboardWillShowListener.remove();
            keyboardWillHideListener.remove();
        }
    }, ['once']);

    return (
        <ParkableBaseView
            scrollable={false}
            removeStandardMargins
            toolbarStyle={{marginHorizontal: PADDING}}
            toolbarTitleText={Strings.support}
            style={{marginBottom: PADDING}}>
            <View style={{flex: 1}}>
                <ScrollView style={styles.details}>
                    <View style={styles.section}>
                        {props.children}
                    </View>
                </ScrollView>
                <View style={styles.messageInput}>
                    {props.showKeyboard && <MessageInput
                        onActionPress={props.onSendPress}
                        placeholder={Strings.type_number_plate_here}
                        onHeightChange={(height: number) => {
                            if (inputHeight === 0) {
                                containerView.current?.scrollView?.scrollToEnd?.();
                            }
                            setInputHeight(height)
                        }}
                    />}
                </View>
            </View>
        </ParkableBaseView>);
};

const styles = StyleSheet.create({
    statusBar: {
        backgroundColor: Colours.WHITE,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    header: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    spacer: {
        width: "24%",
        height: "100%",
        backgroundColor: Colours.WHITE,
    },
    section: {
        flex: 1,
        top: Platform.OS === "ios" ? -9 : 9,
        paddingBottom: 4,
    },
    container: {
        flex: 1,
        backgroundColor: Colours.GREY_10
    },
    details: {
        flex: 1,
        paddingTop: Platform.OS === "ios" ? 18 : 0,
        paddingHorizontal: 17,
        marginBottom: -20,
        backgroundColor: Colours.GREY_10
    },
    leftBox: {
       borderRadius: 5,
       margin: 5,
       padding: 10,
       backgroundColor: Colours.WHITE,
       flexShrink: 1,
       width: '75%'
    },
    leftBoxArrow: {
       position: 'absolute',
       left: -10,
       top: 10,
       borderTopWidth: 10,
       borderTopColor: 'transparent',
       borderRightWidth: 10,
       borderRightColor: '#fff',
       borderBottomWidth: 10,
       borderBottomColor: 'transparent',
       borderLeftWidth: 0
    },
    rightBoxArrow: {
       position: 'absolute',
       right: -9,
       top: 10,
       borderTopWidth: 10,
       borderTopColor: 'transparent',
       borderLeftWidth: 10,
       borderLeftColor: Colours.BLUE,
       borderBottomWidth: 10,
       borderBottomColor: 'transparent',
       borderRightWidth: 0
    },
    rightBox: {
       borderRadius: 5,
       margin: 9,
       padding: 10,
       backgroundColor: Colours.BLUE,
       marginLeft: 'auto',
       flexShrink: 1
    },
    rightBoxWithAction: {
        borderRadius: 5,
        margin: 5,
        padding: 10,
        marginLeft: 'auto',
        flexShrink: 1 ,
        borderWidth: 1,
        borderColor: Colours.GREY_50
     },
    messageInput: {
        width: "100%",
    }
});
