import React, { useEffect, useState } from "react";
import { View } from "react-native";
import TableRow from "react/parkable-components/tableRow/TableRow";
import { AvailabilityDTO } from "../../model/Availability";
import Strings from "../../constants/localization/localization";
import { getFormattedDayAvailability } from "../common/getFormattedDayAvailability";
import { Rule } from "../parkingViews/history/session-details/operatingHours/convertFromAvailabilityString";

type Props = {
    availability: AvailabilityDTO | undefined;
    label?: string;
    onPress?: () => void;
};

export default function AvailabilityTableRow(props: Props) {
    const { availability, label, onPress } = props;

    const [closingTime, setClosingTime] = useState<undefined | string>(undefined);

    useEffect(() => {
        if (!availability) {
            return;
        }

        const da = availability ? getFormattedDayAvailability(availability) : undefined;

        if (!da || !da.available) {
            setClosingTime(Strings.park_closed);
        } else if (da.openAllDay) {
            setClosingTime(Strings.open_all_day);
        } else {
            da.availabilityRules.forEach((rule: Rule) => {
                const currentTime = new Date();
                let availabilityDateTimeObject = new Date();
                availabilityDateTimeObject.setHours(rule.closeTime?.hour ?? 0, rule.closeTime?.minute);
                // TODO: Fix this. It never really checks the time. Only checks the date.
                if (availabilityDateTimeObject.getDate() >= currentTime.getDate()) {
                    const timeString = availabilityDateTimeObject
                        .toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: true })
                        .replace(" ", "")
                        .toLowerCase();
                    setClosingTime(`${Strings.closes} ${timeString}`);
                }
            });
        }
    }, [availability]);

    return (
        <View>
            {closingTime ? (
                <TableRow
                    condensed
                    textProps={{ small: true }}
                    label={label}
                    iconLeft={"stopwatchoutlined"}
                    iconRight={onPress ? "cheverondown" : undefined}
                    iconRightProps={{ small: true }}
                    onPress={onPress ? onPress : undefined}
                >
                    {closingTime}
                </TableRow>
            ) : null}
        </View>
    );
}
