import React, {useEffect, useState} from 'react';
import {StyleSheet, TouchableOpacity, View, Image, ScrollView, Modal} from "react-native";
import ImageViewer from 'react-native-image-zoom-viewer';
import {IImageInfo} from "react-native-image-zoom-viewer/built/image-viewer.type";

type Props = {
    images: Array<string>,
    style: {height: number | string, width: number | string},
    imageBorderRadius: number,
    imageHeight: number,
    imageWidth: number,
    zoomHeight: number | undefined,
}

export default function ImageModal(props: Props){

    const IMAGE_SIZING_SUFFIX = "=s1200";

    const [imageComponents, setImageComponents] = useState<Array<JSX.Element>|undefined>(undefined);
    const [isOpen, setIsOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState<number>(0);

    useEffect(() => {
        if(!!props.images){
            // LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
            setImageComponents(props.images.map((i, idx) => (<TouchableOpacity key={idx} onPress={() => onPressImage(idx)}>
                <Image  key={idx} source={{uri: i}} style={
                    [styles.imageStyle, {
                        borderRadius: props.imageBorderRadius,
                        height: props.imageHeight,
                        width: props.imageWidth
                    }]}/>
            </TouchableOpacity>)));
        }
    }, props.images);

    const onPressImage = (index: number) => {
        console.log("image press: " + index);
        setCurrentImage(index);
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <View style={{flex: 1}}>
            {!!imageComponents && <ScrollView horizontal={true} style={props.style} >
                {imageComponents}
            </ScrollView>}
            <Modal
                onRequestClose={closeModal}
                animationType="fade"
                visible={isOpen}
                transparent={true}>
                <View style={styles.modalContainer}>
                    <ImageViewer index={currentImage} enableSwipeDown saveToLocalByLongPress={false} onClick={closeModal} onCancel={closeModal} imageUrls={props.images.map(i => {
                        let imageSrc = i;
                        if (!!imageSrc && !imageSrc.endsWith(IMAGE_SIZING_SUFFIX)) {
                            imageSrc += IMAGE_SIZING_SUFFIX;
                        }
                      return {url: imageSrc} as IImageInfo
                    })}/>
                </View>
            </Modal>
        </View>
    );
}

const styles = StyleSheet.create({
    modalContainer:{
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.6)",
    },
    imageStyle: {
        marginRight: 9
    }
});
