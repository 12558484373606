// @ts-ignore
import React from 'react';
import { View } from 'react-native';
import TableRow from "react/parkable-components/tableRow/TableRow";
import Colours from "react/parkable-components/styles/Colours";
import Strings from "../../constants/localization/localization";
import {Park} from "../../model/Park";

export default function LocationCard(props: Props) {
    const {park} = props;

    return (<View>
            <TableRow  iconLeftProps={{ iconStyle: {color: Colours.GREEN}}} iconLeft={"pinlocation2filled"}
                      label={Strings.location}>
                {park.address}
            </TableRow>
        </View>)
}

type Props = {
    park: Pick<Park, 'address'>;
}
