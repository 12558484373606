import { Platform, StyleSheet, View } from "react-native";
import Dialog from "../../../dialog/Dialog";
import Button from "react/parkable-components/button/Button";
import React, { useState } from "react";
import { Colours, Input } from "react/parkable-components";
import Strings from "react/constants/localization/localization";
import { attachRfidToUser, OcpiTokensResponse } from "react/api/ev";
import { theme } from "react/constants/theme";
import { KeyedMutator } from "swr";

type AddRfidCardDialogProps = {
    open: boolean;
    updateModal: (open: boolean) => void;
    mutateTokens: KeyedMutator<OcpiTokensResponse>;
};

export const AddRfidCardDialog = (props: AddRfidCardDialogProps) => {
    const { open, updateModal, mutateTokens } = props;

    const [rfidNumber, setRfidNumber] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [addSuccess, setAddSuccess] = useState<boolean>(true);

    const validateInput = (input: string) => {
        const HEX_REGEX = /^\d+$/;
        const SEPARATOR_REGEX = /[-_:]+/gi;
        const sanitizedCardId = input.replace(SEPARATOR_REGEX, "").toUpperCase().trim();
        if (input.length < 1 || !HEX_REGEX.test(sanitizedCardId)) {
            return false;
        }
        return true;
    };

    const onSubmit = async () => {
        setLoading(true);
        if (!rfidNumber) {
            setLoading(false);
            return;
        }

        try {
            await attachRfidToUser(rfidNumber);
        } catch (err: any) {
            setAddSuccess(false);
            setLoading(false);
        } finally {
            await mutateTokens();
        }
    };

    const onCancel = () => {
        setRfidNumber(undefined);
        updateModal(false);
        setTimeout(() => {
            setAddSuccess(true);
        }, 700);
    };
    return (
        <Dialog
            isVisible={open}
            negativeText={!addSuccess ? Strings.okay : ""}
            onNegativePress={onCancel}
            title={!addSuccess ? Strings.rfid_not_found_message : ""}
            titleProps={{ style: styles.title, h2: undefined }}
            label={addSuccess ? Strings.whats_your_rfid : Strings.rfid_not_found}
            labelProps={{ style: styles.label }}
            onBackdropPress={onCancel}
        >
            <View style={{ width: Platform.OS === "web" ? 325 : "100%" }}>
                {addSuccess && (
                    <>
                        <View>
                            <Input
                                keyboardType={"number-pad"}
                                number
                                placeholder={Strings.rfid_number}
                                value={rfidNumber}
                                onChangeText={(num: string) => {
                                    setRfidNumber(num);
                                    if (rfidNumber) {
                                        validateInput(rfidNumber);
                                    }
                                }}
                            />
                        </View>
                        <View style={styles.buttonContainer}>
                            <Button
                                plain
                                border
                                center
                                textProps={{ h5: true }}
                                style={[styles.button, { marginRight: theme.spacing(1) }]}
                                onPress={onCancel}
                                disabled={loading}
                            >
                                {Strings.cancel}
                            </Button>
                            <Button
                                center
                                textProps={{ h5: true }}
                                disabled={loading || !validateInput(rfidNumber ?? "")}
                                loading={loading}
                                style={[styles.button, { backgroundColor: Colours.GREEN }]}
                                onPress={onSubmit}
                            >
                                {Strings.add}
                            </Button>
                        </View>
                    </>
                )}
            </View>
        </Dialog>
    );
};

const styles = StyleSheet.create({
    buttonContainer: {
        marginTop: 10,
        marginBottom: 10,
        flexDirection: "row",
    },
    button: {
        flex: 1,
        borderRadius: 3,
    },

    singleLineContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "stretch",
    },
    pickerText: {
        backgroundColor: Colours.TRANSPARENT,
        borderColor: Colours.TRANSPARENT,
    },
    pickerViewContainer: {
        flex: 1,
        justifyContent: "center",
    },
    dayOfWeekHeaderContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
    label: {
        color: Colours.BLACK,
        textAlign: "left",
        marginBottom: 0,
        marginTop: theme.spacing(1),
    },
    title: {
        textAlign: "left",
        marginTop: theme.spacing(1),
        marginBottom: 0,
        paddingBottom: 0,
    },
});
