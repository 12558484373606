import React, { useCallback } from "react";
import Button from "react/parkable-components/button/Button";
import Colours from "react/parkable-components/styles/Colours";
import Icon from "react/parkable-components/icon/Icon";
import Text from "react/parkable-components/text/Text";
import { Image, ScrollView, StyleSheet, View } from "react-native";
import Modal from "react-native-modal";
import Strings from "../../constants/localization/localization";
import { AccessGateEx, Orientation } from "../../model/AccessGate";
import { Territory } from "../../model/Territory";
import { MAX_WIDTH_WEB } from "../../root/container/container";

const accessControlImage = require("../../resources/accessControl.png");

type Props = {
    style?: {},
    isVisible: boolean,
    setHidden: () => void,
    gateOpening?: string,
    gateIsOpening: boolean,
    accessGates: Array<AccessGateEx>,
    onPressGate: (gateId: string) => void,
    inRangeGateKeys: string[],
    gateOpenResult: boolean|undefined,
    onProblemPress: () => void,
    territory: Pick<Territory, 'supportPhone'> | undefined | null,
}

export default function AccessControlModal(props: Props) {

    const { territory } = props;

    const onPressGate = (id: string) => props.onPressGate(id);

    const buttons = (props.accessGates??[])
      .filter(gate => !(!!props.gateOpening && gate.id !== props.gateOpening))
      .map((gate) => {

          let style = {};
          if(props.gateOpenResult !== false){
              if(gate?.orientation === Orientation.Entrance){
                  style = styles.entranceGate;
              }else if(gate?.orientation === Orientation.Exit){
                  style = styles.exitGate;
              }
          }
          const buttonEnabled = props.inRangeGateKeys.includes(gate.hash);

          return (
            <Button form center disabled={!buttonEnabled}
                    style={{marginTop: 9, ...style }} key={gate.id}
                    onPress={() => onPressGate(gate.id)}>
                {props.gateOpenResult === false ? Strings.try_again :
                  (props.gateIsOpening ? Strings.opening_gate : gate.name)}
            </Button>);
      });

    const outOfRange = props.inRangeGateKeys.length === 0;

    const onCancel = useCallback(() => {props.setHidden()}, []);

    const hasSupportPhone = !!territory?.supportPhone;

    return (
      <Modal
        animationIn={"bounceIn"}
        animationOut={"fadeOut"}
        isVisible={props.isVisible}>
          <ScrollView style={styles.base}>
              <View style={[styles.mainStyle, {backgroundColor: outOfRange && !props.gateOpenResult ? "#FFE5EB" : Colours.WHITE, borderColor: outOfRange ? "#FFE5EB" : Colours.WHITE}]}>

                  <View style={styles.toolbar}>
                      <Icon onPress={onCancel} name={"arrowlightleft"}/>
                  </View>
                  {props.gateOpenResult === undefined && !props.gateIsOpening && <View style={styles.innerView}>
                      <View>
                          <Image style={styles.image} source={accessControlImage}/>
                          {outOfRange && <View style={styles.bannerView}><Text h2 style={[styles.outOfRangeBanner,]}>{Strings.OUT_OF_RANGE}</Text></View>}
                          <Text h2 style={{marginTop: 10}}>{outOfRange ? Strings.drive_to_gate : Strings.you_are_in_range}</Text>
                          <Text small>{outOfRange ? Strings.currently_to_far_away_for_access : Strings.now_all_you_need_to_do}</Text>
                          <View>
                              {buttons}
                          </View>
                      </View>
                  </View>
                  }

                  {props.gateIsOpening && <View style={styles.innerView}>
                      <View>
                          <Image style={styles.image} source={accessControlImage}/>
                          <Text h2 style={styles.spacer}>{Strings.opening_gate}</Text>
                          <Text small>{Strings.may_take_20_seconds}</Text>
                      </View>
                  </View>}

                  {props.gateOpenResult === true && <View style={styles.errorView}>
                      <View>
                          <Text h1>{Strings.gate_opened}</Text>
                          <Text>{Strings.gate_will_open_within_5_seconds}</Text>
                      </View>
                      <View >
                          <Button style={{marginTop: 9}} center onPress={props.setHidden}>{Strings.continue}</Button>
                          <Button iconLeft={"support"} iconLeftProps={{color: Colours.BLACK,}} plain style={{marginTop: 9}} center border textProps={{h4:true}} onPress={props.onProblemPress}>{Strings.i_have_a_problem}</Button>
                      </View>
                  </View>}

                  {props.gateOpenResult === false && <View style={styles.errorView}>
                      <View>
                          <Text h1>{Strings.i_cannot_open_gate}</Text>
                          <Text style={{paddingBottom:9}}>{Strings.lets_try_the_following}</Text>
                          <Text>{Strings.turn_on_Bluetooth}</Text>
                          <Text style={{paddingBottom:9}}>{Strings.drive_up_to_the_barrier_arm}</Text>
                          <Text>{Strings.if_that_doesnt_work_give_us_a_call}</Text>
                      </View>
                      <View>
                          {buttons}
                          <Button iconLeft={"support"} iconLeftProps={{color: Colours.BLACK,}} plain style={{marginTop: 9}} center border textProps={{h4:true}} onPress={props.onProblemPress}>{hasSupportPhone ? Strings.call_support : Strings.i_have_a_problem}</Button>
                      </View>
                  </View>}
              </View>
          </ScrollView>
      </Modal>
    );
}

const styles = StyleSheet.create({
    base: {
        flex: 1,
        maxWidth: MAX_WIDTH_WEB,
        alignSelf: "center",
    },
    toolbar: {
        marginTop: 20,
        marginLeft: -14,
    },
    mainStyle: {
        marginTop: 27,
        borderWidth: 1,
        borderRadius: 9,
        paddingHorizontal: 27,
        paddingBottom: 27,
        flex: 1,
    },
    innerView: {
        flex: 1,
        justifyContent: "space-between"
    },
    errorView: {
        flex: 1,
        justifyContent: "space-between",
        paddingTop: 27,
    },
    image: {
        alignSelf: "center",
        zIndex: 1,
        height: 250,
        aspectRatio: 340/295
    },
    spacer: {
        paddingTop: 14,
    },
    bannerView:{
        marginTop: 10,
        justifyContent: "center",
        alignItems: "center",
    },
    outOfRangeBanner: {
        width: "85%",
        backgroundColor: Colours.RED,
        textAlign: "center",
        color: Colours.WHITE,
        paddingTop:7,
        paddingBottom: 4.5,
    },
    entranceGate:{
        backgroundColor: Colours.GREEN,
    },
    exitGate:{
        backgroundColor: Colours.RED,
    }

});
