// Colours from Kyle:
// https://xd.adobe.com/view/74ed17a4-884a-4162-4d5c-30c22418a707-02dc/

enum Colours {
    GREEN = "#8CD00E",
    GREEN_TINT = "#A3C423",
    GREEN_LIGHT = "#F3FAE6",
    GREEN_DARK = "#8DAA2A",
    GREEN_LIGHT="#EDF8DA",
    ORANGE = "#FFB200",
    ORANGE_BRIGHT = "#FC910F",
    ORANGE_DARK = "#FB9206",
    YELLOW = "#FFEEBF",
    RED_LIGHT = "#FCE5EA",
    RED = "#E42B43",
    RED_DARK = "#E30037",
    SALMON = "#F3CDCA",
    SALMON900 = "#E99A94",
    BLUE = "#3399FF",
    BLUE_LIGHT = "#DDEEFF",
    BLUE_TINT = "#0082C9",
    BLUE_LIGHT = "#DDEEFF",
    BLUE_DARK = "#006EB9",
    PINK = "#C12D87",
    PINK_DARK = "#B11D72",
    PURPLE = "#57378C",
    PURPLE_DARK = "#4A2D77",
    ROSE = "#E7B8B7",
    ROSE_DARK = "#E89B95",
    WHITE = "#FFFFFF",
    BLACK = "#000000",
    GREY_10 = "#DBE3E7",
    GREY_20 = "#B6C6CE",
    GREY_50 = "#8097A3",
    GREY_60 = "#496370",
    GREY_70 = "#394E59",
    GREY_80 = "#2C3E46",
    GREY_BORDER = "#DBE3E7",
    GREY_LIGHT = "#EDF1F3",
    BUTTON_DISABLED = "#DBE3E7",
    TRANSPARENT = "transparent",
    Transparent = "transparent",
}

export default Colours;
