/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Bruno on 10/03/22.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React from 'react';
import {
    StyleSheet,
    View,
} from 'react-native';
import {Text, Icon, Colours} from 'react/parkable-components';
import moment from 'moment';
import {ChatMessageDTO} from "../../model/chat/ChatMessageDTO";
import {useCurrentUser} from "../../api/user/user.api";
import {pickColor} from "../../components/messages/Util";

type MessageProps = {
    hideDateTime?: boolean,
    message: ChatMessageDTO,
    index: number,
    isCurrentUser: boolean,
    participantIndex?: number,
    sender?: {
        firstName?: string;
        lastName?: string;
        userName?: string;
        email?: string;
        phone?: string;
    },
}

export const ChatMessage = (props: MessageProps) => {

    const {
        hideDateTime,
        message,
        index,
        isCurrentUser,
        participantIndex,
        sender,
    } = props;

    const {user} = useCurrentUser();

    const now = moment();
    const createdAt = moment(message.createdAt);
    const sameDayFormat = createdAt.isBefore(now.startOf("day"))
        ? "D/M, h:mmA"
        : "h:mmA";
    const dateFormat = user && now.year() !== createdAt.year()
        ? `${user.dateFormat ?? "MMM DD, YYYY"} h:mmA`
        : sameDayFormat;

    const isUserMessage = message && 'type' in message && (message.type??"") !== "Event";

    const renderDateTime = () => {
        if (hideDateTime) {
            return;
        }
        return (
            <View style={styles.dateAndTime}>
                <Text grey small bold>
                    { createdAt.format(dateFormat)}
                </Text>
            </View>
        )
    }

    const renderSystemMessage = (message: ChatMessageDTO) => {
        return <View key={`${index}`}>
            {renderDateTime()}
            <View style={styles.systemMessage}>
                <Text small grey>{message.body}</Text>
            </View>
        </View>
    };

    const renderUserMessage = (message: ChatMessageDTO) => {
        return <View key={`${index}`}>
            {renderDateTime()}
            <View style={styles.userMessage}>
                {!isCurrentUser && <Icon style={styles.icon} name={"user"}
                                         color={pickColor(participantIndex ?? 0)}/>}

                <View style={isCurrentUser ? styles.rightBox : styles.leftBox}>
                    <View style={isCurrentUser ? styles.rightBoxArrow : styles.leftBoxArrow}/>

                    <View>
                        {!isCurrentUser && !!sender && !!sender.userName && <Text small bold grey>{sender.userName}</Text>}
                        <Text small style={!isCurrentUser ? {color: Colours.BLACK} : {color: Colours.WHITE}}>
                            {message.body}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    };

    if (!message) {
        return null;
    }

    return isUserMessage ? renderUserMessage(message) : renderSystemMessage(message)
};

const styles = StyleSheet.create({
    icon: {
        marginTop: -5,
    },
    leftBox: {
        borderRadius: 5,
        marginHorizontal: 9,
        padding: 10,
        backgroundColor: Colours.WHITE,
        maxWidth: "80%"
    },
    leftBoxArrow: {
        position: 'absolute',
        left: -10,
        top: 9,
        borderTopWidth: 10,
        borderTopColor: 'transparent',
        borderRightWidth: 10,
        borderRightColor: '#fff',
        borderBottomWidth: 10,
        borderBottomColor: 'transparent',
        borderLeftWidth: 0
    },
    rightBoxArrow: {
        position: 'absolute',
        right: -10,
        top: 9,
        borderTopWidth: 10,
        borderTopColor: 'transparent',
        borderLeftWidth: 10,
        borderLeftColor: Colours.BLUE,
        borderBottomWidth: 10,
        borderBottomColor: 'transparent',
        borderRightWidth: 0
    },
    rightBox: {
        borderRadius: 5,
        marginHorizontal: 9,
        padding: 10,
        backgroundColor: Colours.BLUE,
        marginLeft: 'auto',
        flexShrink: 1,
        maxWidth: "80%"
    },
    dateAndTime: {
        width:"100%",
        alignItems: "center",
    },
    systemMessage:{
        width:"100%",
        paddingHorizontal: 18,
        paddingTop: 5,
        paddingBottom: 18,
        alignItems: "center",
    },
    userMessage: {
        flexDirection: "row",
        paddingTop: 5,
        paddingBottom: 18,
    }
});
