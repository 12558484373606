import React, { useEffect, useState } from "react";
import { Animated, StyleSheet, View } from "react-native";
import usePrevious from "../hooks/usePrevious";
import Colours from "../styles/Colours";
import Text from "../text/Text";
import Nullable from "../types/Nullable";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    helperBase: {
        paddingHorizontal: 12,
        paddingVertical: 2,
        marginTop: 2,
        borderRadius: 3,
    },
    helperTextBase: {
        fontSize: 14,
        lineHeight: 20,
    },
    helperOrange: {
        backgroundColor: Colours.ORANGE_DARK,
    },
    helperRed: {
        backgroundColor: Colours.RED,
    },
    helperOrangeText: {},
    helperRedText: {
        color: Colours.WHITE,
    },
});

const cn = classnames(styles);

export type HelperBlockProps = {
    helper?: string;
    error?: Nullable<string>;
    warning?: Nullable<string>;
};

export default function HelperBlock(props: HelperBlockProps) {
    const { helper, error, warning } = props;

    const hasError = !!error;
    const hasWarning = !!warning;
    const hasHelper = !!helper;
    const shown = hasHelper || hasError || hasWarning;

    const [helperScaleAnimation] = useState(
        new Animated.Value(shown ? 1 : 0)
    );
    const [helperOpacityAnimation] = useState(
        new Animated.Value(shown ? 1 : 0)
    );

    const prevShown = usePrevious(shown);

    useEffect(() => {
        if (shown && !prevShown) {
            Animated.parallel([
                Animated.timing(helperScaleAnimation, {
                    toValue: 1,
                    duration: 150,
                    useNativeDriver: true,
                }),
                Animated.timing(helperOpacityAnimation, {
                    toValue: 1,
                    duration: 350,
                    useNativeDriver: true,
                }),
            ]).start();
        } else if (!shown && prevShown) {
            Animated.parallel([
                Animated.timing(helperScaleAnimation, {
                    toValue: 0,
                    duration: 150,
                    useNativeDriver: true,
                }),
                Animated.timing(helperOpacityAnimation, {
                    toValue: 0,
                    duration: 350,
                    useNativeDriver: true,
                }),
            ]).start();
        }
    });

    const helperStyle = cn.styles("helperBase", {
        helperOrange: hasWarning,
        helperRed: hasError,
    });

    const helperTextStyle = cn.styles("helperTextBase", {
        helperOrangeText: hasWarning,
        helperRedText: hasError,
    });

    return (
        <Animated.View
            style={[
                {
                    transform: [
                        { scale: helperScaleAnimation }
                    ],
                    opacity: helperOpacityAnimation,
                },
            ]}
        >
            <View style={helperStyle}>
                {hasError && <Text style={helperTextStyle}>{error}</Text>}
                {!hasError && hasWarning && (
                    <Text style={helperTextStyle}>{warning}</Text>
                )}
                {!hasError && hasHelper && (
                    <Text style={helperTextStyle}>{helper}</Text>
                )}
            </View>
        </Animated.View>
    );
}
