import Text from "react/parkable-components/text/Text";
import TableRow from "react/parkable-components/tableRow/TableRow";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Colours from "react/parkable-components/styles/Colours";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../../constants/localization/localization";
import { TouchableOpacity, View } from "react-native";
import React, { useRef } from "react";
import { ParkSession } from "../../../model/ParkSession";
import { FinishParkingData } from "../../../redux/reducers/parking";
import localizeCurrency from "../../../constants/localization/localizeCurrency";
import { ParkSessionDTO } from "../../../model/ParkSessionDTO";

type Props = {
    parkSession: ParkSession | ParkSessionDTO,
    finishParkingData: FinishParkingData,
    currencyCode: string
}
export function SessionCharges(props: Props) {

    const dialogRef = useRef<DialogRef>(null);

    /**
     * There are four states the session can be in
     * 1. Session was free
     * 2. Session was not free and user has paid
     * 3. Session was not free and user didnt pay
     * 4. Session was not free but we dont know how much as EV charges are not finalised
     * 5. Payment failed and requires the user to authenticate again
     */
    const chargedFormatted = localizeCurrency(props.parkSession.transactionAmount??0, props.currencyCode)

    //states 1 & 2
    if(props.parkSession.transactionSuccess === true) {
        return (
            <TableRow iconLeft={'dollarfilled'} iconLeftProps={{color: Colours.GREEN}} label={Strings.amount_charged}
                      contentRight={(props.parkSession.transactionAmount ?? 0) > 0 &&
                      <View style={{flexDirection: 'row', alignItems: 'center'}}>
                          <TouchableOpacity style={{
                              backgroundColor: Colours.GREY_10,
                              borderRadius: 30, paddingHorizontal: 12, paddingVertical: 6
                          }} onPress={() => {}}>
                              <Text small>{Strings.payment_received}</Text>
                          </TouchableOpacity>
                      </View>
                      }>{chargedFormatted}</TableRow>)
    }
    //state 3 and 5
    else if(props.parkSession.transactionSuccess === false ||
        (props.parkSession.transactionSuccess === null && props.parkSession.transactionResponse === "authentication_required") ||
        (props.parkSession.transactionSuccess === null && (props.finishParkingData.ocpiSessions || []).length === 0)) {

        return (<TableRow iconLeft={'dollarfilled'} iconLeftProps={{color: Colours.GREEN}} label={Strings.amount_charged}
                          contentRight={
                          <View style={{flexDirection: 'row', alignItems: 'center'}}>
                              <TouchableOpacity style={{
                                  backgroundColor: Colours.RED_LIGHT,
                                  borderRadius: 30, paddingHorizontal: 12, paddingVertical: 6
                              }} onPress={() => {}}>
                                  <Text small>{props.parkSession.transactionResponse === "authentication_required" ? Strings.authentication_required : Strings.payment_required}</Text>
                              </TouchableOpacity>
                          </View>
                          }>{chargedFormatted}</TableRow>)
    }
    //state 4 - we havent attempted payment as ev sessions wernt finished
    else{
        let approxCharge = props.finishParkingData.sessionLineItem?.amount??0;
        const pricePerKwh = props.parkSession.pricePerKWH??0;

        (props.finishParkingData.ocpiSessions || []).forEach((ocpiSession) => {
                const kwh = !!ocpiSession.kwh ? ocpiSession.kwh : 0;
                approxCharge+= (kwh * pricePerKwh);
            });

        const approximateChargeFormatted = localizeCurrency(approxCharge, props.currencyCode);

        return (<View>
                    <TableRow iconLeft={'dollarfilled'} chevron onPress={() => dialogRef.current?.show()}
                          iconLeftProps={{color: Colours.GREEN}}
                          label={Strings.approximate_charge}>{approximateChargeFormatted}</TableRow>
                    <Dialog ref={dialogRef}
                            label={Strings.approximate_charges}
                            labelProps={{style: {color: Colours.BLACK, textAlign: 'left'}}}
                            title={Strings.approximate_charge_explanation}
                            titleProps={{style: {textAlign: 'left'}, h2: undefined}}
                            positiveText={Strings.ok}
                            positiveProps={{textProps: {h5: true}}}
                            onPositivePress={() => dialogRef.current?.hide()}/>
                </View>)
    }
}
