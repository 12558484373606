import React, { useCallback, useEffect, useRef, useState } from "react";
import Text from "react/parkable-components/text/Text";
import InputWrapper from "react/parkable-components/inputWrapper/InputWrapper";
import Input from "react/parkable-components/input/Input";
import HelperBlock from "react/parkable-components/helperBlock/HelperBlock";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Colours from "react/parkable-components/styles/Colours";
import CheckBox from "react/parkable-components/checkBox/CheckBox";
import Button from "react/parkable-components/button/Button";
import Dialog from "react/components/dialog/Dialog";
import {
    Keyboard,
    KeyboardEvent,
    LayoutChangeEvent,
    Linking,
    ScrollView,
    StyleSheet,
    TextInput,
    TouchableOpacity,
    View,
} from "react-native";
import { connect } from "react-redux";
import { IRootReducer } from "../../redux/reducers/main";
import { createUser, setUserId } from "../../redux/actions/data";
import validatePhoneNumber from "../../constants/validatePhoneNumber";
import Strings from "../../constants/localization/localization";
import CountryPicker, {
    CallingCode,
    Country,
    CountryCode
} from "react-native-country-picker-modal";
import { createRoute, NavigationProps, useNavigation } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import { setCreateUserData, setUser } from "../../redux/actions/user";
import { useAppDispatch, useSelector } from "../../redux/redux";
import { useLogout } from "../../root/auth/logoutProvider";
import ParkableBaseView from "../common/ParkableBaseView";
import {useCountries} from "react/constants/Util";
import {screenWidth} from "../../constants/constants";

class CreateAccountParams {
    samlToken?: string;
    email: string | undefined | null;
    password?: string | undefined;
    firstName?: string;
    lastName?: string;
    organisationId?: number;
    usingSSO?: boolean;
    logoutOnBack?: boolean;
}

const CreateAccountView = (props: ReturnType<typeof getReduxProps> & typeof actions & NavigationProps<Routes.CreateAccountView>) => {
    const { createUser } = props;

    const {
        usingSSO,
        email,
        firstName,
        lastName,
        password,
        samlToken,
        logoutOnBack,
    } = props.route.params ?? {};

    const navigation = useNavigation();
    const dispatch = useAppDispatch();
    const logout = useLogout();
    const user = useSelector((state: IRootReducer) => state.user.user)

    const [page, setPage] = useState(usingSSO ? 1 : 0);
    const [loading, setLoading] = useState(false);

    const [part1, setPart1] = useState<Part1 | Partial<Part1>>({ email, password, firstName, lastName, usingSSO });
    const [failMessage, setFailMessage] = useState<string>();

    const dialogRef = useRef<DialogRef>(null);

    useEffect(() => {
        page < 0 && navigation.pop()
    }, [page])

    const onAcknowledgeError = () => {
        navigation.pop();
    }

    useEffect(() => {
        if (user) {
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            })
        }
    }, [user])

    const onSignUp = async (newPart1: Part1) => {

        const data = {
            ...part1,
            firstName: newPart1.firstName,
            lastName: newPart1.lastName,
            phone: newPart1.phone,
            countryCode: newPart1.countryCode
        }

        setPart1(data);
        setLoading(true);

        try {
            const user = await createUser(
              data!.email!,
              data!.password,
              data.firstName,
              data.lastName,
              data.phone,
              data.countryCode,
              !!usingSSO,
              samlToken
            );

            if(usingSSO){
                //onAuthStateChanged event wont be triggered as firebase already logged in for SSO users
                dispatch(setUser(user));
                dispatch(setUserId(user.id));
            }
        } catch (error) {
            setLoading(false)
            setFailMessage((error as any)?.message || Strings.try_again)
            dialogRef.current?.show();
        }
    }

    const doLogout = async () => {
        dispatch(setCreateUserData(undefined));
        await logout();

        navigation.reset({ routes: [{
                name: Routes.LandingView
            }]});
    }


    return <ParkableBaseView scrollable={false} loading={loading} backButtonOverride={logoutOnBack ? doLogout : () => setPage(page - 1)}>
        <ScrollView
          style={{ flex: 1 }}
          keyboardShouldPersistTaps="handled">
            <View style={styles.body}>
                <Text allowFontScaling={false} style={styles.welcome}>{Strings.sign_up}</Text>
                {usingSSO && <Text>{Strings.signed_on_need_a_few_more_details}</Text>}
                <Page1 part1={part1} onSignUp={(part1: Part1) => onSignUp(part1)} />
            </View>
        </ScrollView>
        <Dialog ref={dialogRef} label={Strings.failed_to_signup} labelProps={{ red: true }} title={failMessage} positiveText={Strings.ok} onPositivePress={onAcknowledgeError} />
    </ParkableBaseView>
}

type GeoLocation = {
    ip: string,
    hostname: string,
    city: string,
    region: string,
    country: string,
    loc: string,
    org: string,
    postal: string,
    timezone: string,
    readme: string
};

const useGeoLocation = () => {
    const [geoLocation, setGeoLocation] = useState<GeoLocation | undefined>();
    useEffect(() => {
        fetch("https://ipinfo.io")
          .then(value => value.json())
          .then(value => setGeoLocation(value))
    }, []);
    return geoLocation;
}

const useCallingCode = (allCountries?: Country[], countryCode?: CountryCode) => {
    const [callingCode, setCallingCode] = useState<CallingCode | undefined>()

    useEffect(() => {
        if (!allCountries || !countryCode) {
            return;
        }
        const country = allCountries.find(value => value.cca2 === countryCode);
        if (!country) {
            return;
        }
        setCallingCode(country.callingCode[0]);
    }, [allCountries, countryCode])

    return callingCode
}

interface Page1Props {
    onSignUp: any,
    part1: Part1 | Partial<Part1>,
}

const Page1 = ({ onSignUp, part1 }: Page1Props) => {

    const [firstName, setFirstName] = useState<string>(part1?.firstName ?? '');
    const [lastName, setLastName] = useState<string>(part1?.lastName ?? '');
    const [phone, setPhone] = useState<string>(part1?.phone ?? '');
    const [error, setError] = useState<{ phone: string | null }>({ phone: null });
    const [phoneRef, setPhoneRef] = useState<TextInput | null>(null)
    const [keyboardHeight, setKeyboardHeight] = useState<number>(0)
    const [bottomAt, setBottomAt] = useState<number>(0)
    const [phoneFocus, setPhoneFocus] = useState<boolean>(false)
    const [termsChecked, setTermsChecked] = useState(false);

    const countries = useCountries();
    const geoLocation = useGeoLocation();

    const preferredCountries: CountryCode[] = ["NZ", "AU", "US", "GB"]
    const [selectedCountry, setSelectedCountry] = useState<CountryCode>(preferredCountries[0])
    const [isCountrySelectVisible, setIsCountrySelectVisible] = useState(false)
    const callingCode = useCallingCode(countries, selectedCountry)
    const countryName = countries?.find(value => value.cca2 === selectedCountry)?.name as string;

    const geoCountry = geoLocation?.country;
    useEffect(() => {
        if(geoCountry && countries?.find(value => value.cca2 === geoCountry)) {
            setSelectedCountry(geoCountry as CountryCode)
        }
    }, [geoCountry, countries])

    useEffect(() => {
        const listenerKBShow = Keyboard.addListener('keyboardDidShow', keyboardDidShow);
        const listenerKBHide = Keyboard.addListener('keyboardDidHide', keyboardDidHide);
        return () => {
            listenerKBShow.remove();
            listenerKBHide.remove();
        }
    }, [])

    const keyboardDidShow = useCallback((e: KeyboardEvent) => {
        setKeyboardHeight(e.endCoordinates.height);
    }, [])

    const keyboardDidHide = useCallback(() => {
        setKeyboardHeight(0)
    }, [])

    const onViewLayout = useCallback((event: LayoutChangeEvent) => {
        const { y, height } = event.nativeEvent.layout;
        setBottomAt(y + height)
    }, [])

    const onTermsClick = () => {
        (async () => {
            const URL = "https://www.parkable.com/terms-of-service";
            if (await Linking.canOpenURL(URL)) {
                await Linking.openURL(URL);
            }
        })();
    }

    const onSignUpPress = useCallback(() => {
        const mobile = `+${callingCode}${phone}`
        const invalidPhoneMessage = validatePhoneNumber(mobile)

        if (invalidPhoneMessage != null) {
            setError({ phone: Strings.invalid_phone })
            phoneRef?.focus()
            return
        }

        setError({ phone: null })
        onSignUp({
            firstName,
            lastName,
            phone: mobile,
            countryCode: selectedCountry === "GB" ? "UK" : selectedCountry
        })
    }, [firstName, lastName, phone, selectedCountry])

    const toggleTermsChecked = () => {
        setTermsChecked(!termsChecked);
    }

    const onPhoneTextChanged = (value: string) => {
        setPhone((prev) => {
            if (value.length <= prev.length + 1) {
                return value.replace(/\D+/, "");
            }
            const country = (countries || []).find(c => value.includes('+' + c.callingCode[0]));
            if (!country) {
                return value.replace(/\D+/, "");
            }
            setSelectedCountry(country.cca2);
            return value.replace(`+${country.callingCode[0]}`, "").replace(/\D+/, "");
        });
        setError({phone: null})
    }
    const isTablet =  screenWidth >= 500;

    return <TouchableOpacity style={[styles.base]} onPress={Keyboard.dismiss} activeOpacity={1}>
        <View style={{top: 14, flexDirection: 'row', justifyContent: 'space-between'}}>
            <View style={{ flex: 2 }}>
                <Input
                  autoFocus
                  label={Strings.first_name.toUpperCase()}
                  onChangeText={v => setFirstName(v.trim())}
                  defaultValue={firstName} />
            </View>
            <View style={{ width: 10 }} />
            <View style={{ flex: 3 }}>
                <Input
                  label={Strings.last_name.toUpperCase()}
                  onChangeText={v => setLastName(v.trim())}
                  defaultValue={lastName} />
            </View>
        </View>

        <View style={{ marginTop: 14 }}>
            <InputWrapper label="Country"
                          iconRight={"cheverondown"}
                          iconRightProps={{ small: true }}
                          onPress={() => setIsCountrySelectVisible(true)}
                          focused={false}>
                <View style={{flexDirection: 'row'}}>
                    {countryName && <Text small>{countryName}</Text>}
                    {selectedCountry != null && <CountryPicker visible={isCountrySelectVisible}
                                                               onClose={() => setIsCountrySelectVisible(false)}
                                                               containerButtonStyle={{ marginTop: -9 }}
                                                               onSelect={country => setSelectedCountry(country.cca2)}
                                                               preferredCountries={preferredCountries}
                                                               countryCode={selectedCountry}
                                                               withCloseButton
                                                               withEmoji={false}
                                                               withFlagButton={false}
                                                               withFilter />}
                </View>
            </InputWrapper>
        </View>
        <View style={styles.phoneContainer}>
            <Text bold strapline>{Strings.phone_number}</Text>
            <View style={styles.phoneInputs}>
                <Input
                  keyboardType={'phone-pad'}
                  textContentType="telephoneNumber"
                  dataDetectorTypes="phoneNumber"
                  ref={ref => setPhoneRef(ref)}
                  iconLeft={callingCode != null ? <Text grey small>+{callingCode}</Text> : undefined}
                  style={{
                      ...(
                        error.phone != null
                          ? { borderColor: Colours.RED }
                          : {}
                      )
                  }}
                  inputStyle={{
                      marginLeft: 0
                  }}
                  number
                  value={phone}
                  onChangeText={onPhoneTextChanged}
                  onSubmitEditing={Keyboard.dismiss}
                  onFocus={() => setPhoneFocus(true)}
                  onBlur={() => setPhoneFocus(false)} />
            </View>
        </View>
        <View style={{ flex: 1 }} onLayout={onViewLayout}>
            <View style={{flexDirection: "row", marginBottom: 18, marginTop: 5}}>
                <View style={{ width: 30, overflow: "hidden", marginRight: 9 }}>
                    <CheckBox checked={termsChecked} onChange={toggleTermsChecked} />
                </View>
                    <Text small style={{
                        fontWeight: "normal",
                        padding: isTablet ? 6 : 0,
                        flexWrap: "wrap",
                        width: !isTablet ? 0.75 * screenWidth : '100%',
                        textAlignVertical: "center"}}>
                        {Strings.by_signing_up}{' '}
                        <TouchableOpacity onPress={onTermsClick}>
                            <Text small style={{
                                color: Colours.BLUE,
                                fontWeight: "normal",
                                textDecorationLine: "underline" }}>
                                {Strings.terms_conditions.toLowerCase()}
                            </Text>
                        </TouchableOpacity>
                    </Text>
            </View>
        </View>

        <HelperBlock error={error.phone} />
        <View style={{ flex: 1 }} onLayout={onViewLayout}>
            <Button center form textProps={{ h5: false, h3: true }} disabled={!firstName || !lastName || !phone || !termsChecked} onPress={onSignUpPress}>{Strings.sign_up}</Button>

        </View>
    </TouchableOpacity>
}

type Part1 = {
    email?: string|null,
    password?: string,
    firstName: string,
    lastName: string,
    phone: string,
    countryCode: string,
    usingSSO: boolean
}

const actions = {
    createUser
}

const getReduxProps = (state: IRootReducer) => {
    return {
        api: state.data.api,
    }
}

export const CreateAccountRoute = createRoute({
    path: Routes.CreateAccountView,
    params: {type: CreateAccountParams,}
})


const styles = StyleSheet.create({
    body: {
        flex: 1,
        paddingBottom: 18,
    },
    welcome: {
        fontSize: 45,
        lineHeight: 49,
        marginBottom: 18,
        backgroundColor: Colours.WHITE,
        fontFamily: "GTEestiDisplay-Bold",
    },
    topLeft: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    topTitle: {
        position: 'absolute',
        paddingTop: 15,
        alignSelf: 'center'
    },
    base: {
        flex: 1,
    },
    subText: {
        top: 20,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    phoneContainer: {
    },
    phoneInputs: {
    }
})

export default connect(getReduxProps, actions)(CreateAccountView);
