import {get, post, put, Token} from "./rest";
import {ParkingRequest} from "../model/ParkingRequest";
import {ParkSession} from "../model/ParkSession";
import {HoldBayFullResponse} from "../dto/HoldBayResponse";
import {useMySWR} from "./http/use-swr";
import { useSelector } from "../redux/redux";
import { ParkSessionDTO } from "../model/ParkSessionDTO";
import { SessionLineItem } from "../model/SessionLineItem";
import { OcpiSession } from "../model/ocpi/OcpiSession";
import { Subscription } from "../model/Subscription";
import { FailedPaymentInfo } from "../model/FailedPaymentInfo";
import { updateEstimatedLeaveTime } from "./tandem/tandem.api";
import { put as httpPut } from "./http/rest";
import { BayDTO } from "../model/BayDTO";
import { BayProblemResponseFullStatus } from "../model/Types";
import {PaymentDetails} from "react/api/stripe/dto/PaymentDetails";
import {FinalisePaymentRequest} from "react/api/stripe/dto/FinalisePaymentRequest";
import { RestartPaymentRequest } from "./stripe/dto/RestartPaymentRequest";
const FULL_SESSIONS_URL = "v2/parksessions";

export type BayProblemResponse = {
    parkSession: ParkSessionDTO | null;
    bay: BayDTO | null;
    status: BayProblemResponseFullStatus;
}

export type CreateParkSession2Response = {
    userCardlastFour: string,
    userCardBrand: string,
    parkSession: ParkSessionDTO,
}

export type ParkSession2Response = {
    parkSession: ParkSessionDTO | null,
    sessionLineItem: SessionLineItem | null,
    ocpiSessions: OcpiSession[] | null,
}

export type FinalisePaymentResponse = {
    parkSession: ParkSessionDTO | null;
    subscription: Subscription | null;

}

export type FailedPaymentsResponse = {
    failedPayments: FailedPaymentInfo | null;
}

export type RetryParkSessionTransactionResponse = {
    success: boolean;
    message: string;
    updatedSession: ParkSession | null;
}

export type RestartPaymentResponse = {
    paymentIntentId: string,
    paymentIntentClientSecretId: string
}

export function selectAndHoldBayAPI(url: string, token: Token, parkId: number, vehicleId: number | undefined, bayId: number | undefined, bayFeatureOverride: any) {
    url = `${url}v3/parksessions/hold-bay`;

    const payload = {
        parkId,
        vehicleId,
        bayId,
        bayFeature: bayFeatureOverride
    };
    return post(token, url, payload) as Promise<HoldBayFullResponse>;
}

export function cancelHoldAPI(url: string, token: Token) {
    url = `${url}v2/parksessions/cancel-hold`;
    const payload = {};
    return put(token, url, payload);
}

export type ParkingRequestResponse = {parkingRequest: ParkingRequest | null};

export const getCurrentParkingSessionAPI = (url: string, token: Token) => get(token, `${url}v3/parksessions/active`) as Promise<ParkSession2Response>;

export const useCurrentParkingSession = () => {
    const { fireBaseToken } = useSelector(state => state.auth);
    return useMySWR<ParkSession2Response>(fireBaseToken ? `v3/parksessions/active` : null);
};

export const getSessionAPI = (url: string, token: Token, sessionId: number | string) => get(token, `${url}v3/parksessions/${sessionId}`) as Promise<ParkSession2Response>;

export const keepSessionRunningAPI = (url: string, token: Token, sessionId: number) => put(token, `${url}v1/parksessions/${sessionId}/renew`);

// todo this calls a deprecated api endpoint
export const getLastSessionApi = (url: string, token: Token, parkId: number, userName: string) => {

    const params = { q: parkId,
        offset: 0,
        count: 1,
        username: userName};

    return get(token, `${url}v1/parksessions/park`, params) as Promise<ParkSessionsResponse>;
}

export interface ParkSessionsResponse {
    count: number;
    cursor: string;
    parkSessions: ParkSession[];
}

export function loadSessions(token: Token, api: string, count: number) {
    return get(token, api + FULL_SESSIONS_URL, {count});
}

export function getSessionWithEverything(api: string, token: Token, sessionId: number) {
    return get(token, `${api}${FULL_SESSIONS_URL}/${sessionId}`);
}

export function startParkingAPI(url: string, token: Token, parkId: number, bayId: number | undefined, vehicleId: number, isReservation: boolean, gateId: string | undefined, estimatedLeaveTime: string | null) {
    url = `${url}v3/parksessions`;

    const createObject = {
        parkId,
        bayId,
        vehicleId,
        isReservation,
        gateId,
        estimatedLeaveTime,
        startSessionOnReservationTimeout: true
    };

    return post(token, url, createObject) as Promise<CreateParkSession2Response>;
}

export const updateParkingSessionAPI = (url: string, token: Token, sessionId: number,
                                        status: number | undefined, vehicleId: number | undefined, gateId: string | undefined) => {
    const update = {} as any;

    if(!!gateId) {
        update.gateId = gateId;
    }

    if (!!status) {
        update.status = status;
    }

    if (!!vehicleId) {
        update.vehicleId = vehicleId;
    }

    return put(token, `${url}v3/parksessions/${sessionId}`, update) as Promise<ParkSession2Response>;
};

export const stopParkingAPI = (url: string, token: Token, sessionId: number) => updateParkingSessionAPI(url, token, sessionId, 4, undefined, undefined);

export const cancelReservationAPI = (url: string, token: Token, sessionId: number) => updateParkingSessionAPI(url, token, sessionId, 3,  undefined, undefined);

export const startParkingFromReservationAPI = async (
    url: string,
    token: Token,
    sessionId: number,
    gateId: string | undefined,
    estimatedLeaveTime: string | null
) => {
    if (estimatedLeaveTime) {
        await updateEstimatedLeaveTime(sessionId, {
            estimatedLeaveTime,
        });
    }
    return await updateParkingSessionAPI(url, token, sessionId, 1, undefined, gateId);
};

export function reportCarInBay(
    api: string,
    token: Token,
    parkId: number,
    bayId: number | undefined,
    carRegistration: string | null,
    currentSession: number | undefined,
    employeeSubscriptionId: number | undefined) {

    const data = {
        carRegistration,
        currentSession,
        employeeSubscriptionId,
    };

    return post(token, `${api}v2/bay/${parkId}/${bayId}/reportproblem`, data) as Promise<BayProblemResponse>;
}

export const completePayment = (api:string, token:Token, entity:PaymentDetails, paymentIntent: string) => {
    
    const data = {
        paymentIntentId: paymentIntent
    } as FinalisePaymentRequest;
    
    switch (entity.entityType) {
        case "ParkSession":
            data.sessionId = parseInt(entity.entityId);
            break;
        case "Deposit":
            data.depositId = parseInt(entity.entityId);
            break;
        case "Infringement":
            data.infringementId = parseInt(entity.entityId);
            break;
        case "StripeCharge":
            data.stripeChargeId = parseInt(entity.entityId);
            break;
        case "StripeSubscriptionInvoice":
            data.stripeSubscriptionInvoiceId = entity.entityId;
            break;
        default:
            break;
    }
    
    return put(token, `${api}v1/payments/finalise`, data) as Promise<FinalisePaymentResponse>;
};

export const restartPayment = (api:string, token:Token, entity:PaymentDetails, paymentIntent: string) => {

    const data = {
        paymentIntentId: paymentIntent
    } as RestartPaymentRequest;
    
    switch (entity.entityType) {
        case "ParkSession":
            data.sessionId = parseInt(entity.entityId);
            break;
        case "Deposit":
            data.depositId = parseInt(entity.entityId);
            break;
        case "Infringement":
            data.infringementId = parseInt(entity.entityId);
            break;
        case "StripeCharge":
            data.stripeChargeId = parseInt(entity.entityId);
            break;
        case "StripeSubscriptionInvoice":
            data.stripeSubscriptionInvoiceId = entity.entityId;
            break;
        default:
            break;
    }
    
    return post(token, `${api}v1/payments/restart`, data) as Promise<RestartPaymentResponse>;
};

export const getPaymentsRequiringAuthentication = (api:string, token:Token) => {
    return get(token, `${api}v1/payments/authRequired`) as Promise<FailedPaymentsResponse>;
};

export const retryFailedPayment = (api:string, token:Token, sessionId:number) => post(token, `${api}v1/parksessions/${sessionId}/retryTransaction`) as Promise<RetryParkSessionTransactionResponse>;
