import React from 'react';
import {StyleSheet, TouchableOpacity, View, Linking} from 'react-native';
import connect from "../../../constants/connect";
import Strings from "../../../constants/localization/localization";
import {IRootReducer} from "../../../redux/reducers/main";
import TableRow from "react/parkable-components/tableRow/TableRow";
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import Icons from "react/parkable-components/icon/Icons";
import localizeCurrency from '../../../constants/localization/localizeCurrency';
import moment from 'moment-timezone';
import { useVehicle } from '../../../api/vehicle/vehicle.api';
import { useInvoiceToken } from '../../../api/user/user.api';
import { useBay } from '../../../api/bay/bay.api';
import { usePark } from "../../../api/park/park.api";
import { useParkSessionV3 } from "../../../api/parkSession/parkSession.api";
import { useTerritory } from "../../../api/territory/territory.api";
import { useTandemPod } from '../../../api/tandem/tandem.api';
import {createRoute, NavigationProps} from "../../../navigation/constants";
import {Routes} from "../../../navigation/root/root.paths";
import { useSelector } from "../../../redux/redux";
import ParkableBaseView from "../../common/ParkableBaseView";
import { CarParkNameRow } from 'react/components/widgets/table-rows/car-park-row';

class SessionHistoryDetailViewParams {
    sessionId: number;
}

const getReduxProps = (state: IRootReducer) => ({
    territories: state.territories.territories,
    dateformat: state.settings.dateformat,
    vouchers: ((state.user || {}).casualVoucher || {}),
});

const sessionDateFormat = "hh:mma, dddd DD MMMM YYYY";

type Props = NavigationProps<Routes.SessionHistoryDetailView>;

const SessionHistoryDetailView = (props: Props) => {
    const {parkSession: session} = useParkSessionV3(props.route.params.sessionId);
    const {park} = usePark(session?.park);
    const {territory} = useTerritory(park?.territory);
    const { vehicle } = useVehicle(session?.vehicle);
    const {signage, bay} = useBay(park?.id, session?.bay);
    const { tandemPod } = useTandemPod(bay?.tandemPod);
    const {api} = useSelector(state => state.data);

    const {token: invoiceToken} = useInvoiceToken();

    const downloadInvoice = () => {
        if (!session) {
            return
        }

        const url = `${api}v1/parksessions/${session.id}/invoice?token=${invoiceToken}`;

        (async () => {
            if (await Linking.canOpenURL(url)) {
                await Linking.openURL(url);
            }
        })();
    };

    if (!session) {
        return <View />
    }

    return (
        <ParkableBaseView>
            <View style={styles.details}>
                <View style={styles.header}>
                    <Text h1 bold>{Strings.session_details}</Text>
                </View>
                <View style={[styles.section]}>

                {!!park &&
                <>
                    <CarParkNameRow displayName={park?.displayName} />
                    <TableRow
                        label={Strings.location}
                        iconLeft={Icons.pinlocation2filled}
                        iconLeftProps={{ color: Colours.GREEN }}>
                        {park.address}
                    </TableRow>
                </>
                }

                <TableRow
                    label={Strings.session_started}
                    iconLeft={Icons.stopwatchfilled}
                    iconLeftProps={{ color: Colours.GREEN }}>
                    {moment(session?.startedAt || undefined).tz(park?.timeZoneId??'UTC').format(sessionDateFormat)}
                </TableRow>

                <TableRow
                    label={Strings.session_ended}
                    iconLeft={Icons.tickfilled}
                    iconLeftProps={{ color: Colours.GREEN }}>
                    {moment(session?.endedAt || undefined).tz(park?.timeZoneId??'UTC').format(sessionDateFormat)}
                </TableRow>

                {(session.voucherAmount??0) > 0 && <>
                    <TableRow
                        label={Strings.amount}
                        iconLeft={Icons.dollarfilled}
                        iconLeftProps={{ color: Colours.GREEN }}>
                        {(session.amount !== null)?localizeCurrency(session.amount, territory?.currencyCode, false): Strings.not_available}
                    </TableRow>

                    <TableRow
                        label={Strings.voucher_applied_label}
                        iconLeft={Icons.voucher}
                        iconLeftProps={{ color: Colours.GREEN }}>
                        {(session.voucherAmount !== null)?localizeCurrency(session.voucherAmount*-1, territory?.currencyCode, false): Strings.not_available}
                    </TableRow>
                </>}

                <TableRow
                    label={Strings.total_amount}
                    iconLeft={Icons.dollarfilled}
                    iconLeftProps={{ color: Colours.GREEN }}>
                    {(session.transactionAmount !== null)?localizeCurrency(session.transactionAmount, territory?.currencyCode, false): Strings.not_available}
                </TableRow>

                {session.bay !== null && signage !== null && signage !== undefined &&
                    <TableRow
                        label={ bay?.tandemPod
                            ? Strings.tandem_parking.tandem_bays
                            : Strings.bay_number}
                        iconLeft={Icons.baysign}
                        iconLeftProps={{ color: Colours.ORANGE }}>
                        { bay?.tandemPod
                            ? tandemPod?.name
                            : signage ?? session.bay ?? Strings.bay_not_available
                        }
                    </TableRow>
                }

                <TableRow
                    label={Strings.vehicle}
                    iconLeft={Icons.car}
                    iconLeftProps={{ color: Colours.BLACK }}>
                    {vehicle?.registration}
                </TableRow>

                {((session.transactionAmount??0) > 0)  &&
                    <TouchableOpacity onPress={downloadInvoice}>
                        <TableRow
                            label={Strings.receipt}
                            iconLeft={Icons.linkexpand}
                            iconLeftProps={{ color: Colours.BLUE }}>
                            {Strings.download}
                        </TableRow>
                    </TouchableOpacity>
                }
                </View>
            </View>
        </ParkableBaseView>
    );
}


export default connect(getReduxProps)(SessionHistoryDetailView) as React.FC<Props>;

export const SessionHistoryDetailRoute = createRoute({
    path: Routes.SessionHistoryDetailView,
    params: {type: SessionHistoryDetailViewParams}
});

const styles = StyleSheet.create({
    header: {
        top: 9,
    },
    section: {
        top: 9,
    },
    container: {
        flex: 1,
        backgroundColor: Colours.WHITE,
    },
    details: {
        marginBottom:18,
        backgroundColor: Colours.WHITE,
    }
});
