import {StyleSheet} from "react-native";
import {theme} from "../../../constants/theme";

export const containerStyles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: theme.palette.WHITE,
    },
    main: {
        paddingTop: 14,
    },
});