import React, {} from "react";
import Text from "react/parkable-components/text/Text";
import TableRow from "react/parkable-components/tableRow/TableRow";
import {useOrganisation} from "../../../../api/organisation/organisation.api";
import {usePark} from "../../../../api/park/park.api";
import {getIcon} from "./styles";
import {theme} from "../../../../constants/theme";
import {ParkingRequestStatus} from "../../../../model/ParkingRequest";
import {useCampus} from "../../../../api/campus/campus.api";
import { Routes } from "react/navigation/root/root.paths";
import {ActiveParkingRequest} from "../../../../constants/parking-request.constants";
import moment from "moment";
import {useNavigation} from "../../../../navigation/constants";

type BookingRowProps = {
    request: ActiveParkingRequest,
};

export default function BookingRow({request}: BookingRowProps) {

    const navigation = useNavigation();
    const {park} = usePark(request.park);
    const {campus} = useCampus(request.organisation, request.campus);
    const {organisation} = useOrganisation(request.organisation);

    const icon = getIcon(request.status);

    const dateString = request.date;

    if (!(organisation && (park || campus))) {
        return null
    }

    return (
        <TableRow
            onPress={() => {
                if (request.status == ParkingRequestStatus.Confirmed) {
                    navigation.push(Routes.ConfirmedBookingView, {
                        parkingRequestId: request.id,
                        backButtonToMapView: false
                    });
                } else {
                    navigation.push(Routes.FutureBookingView, {
                        parkId: request.park ?? campus?.parks[0] ?? undefined,
                        campusId: request.campus ?? undefined,
                        organisationId: organisation.id,
                    });
                }
            }}
            label={organisation.name ?? ""}
            labelBottom={moment(dateString).format("Do MMMM YYYY")}
            iconRight="cheveronright"
            iconRightProps={{color: theme.palette.GREY_50, small: true}}
            iconLeft={icon.name}
            iconLeftProps={{color: icon.color}}
        >
            <Text>{park?.displayName ?? park?.address ?? campus?.name ?? ''}</Text>
        </TableRow>
    );
};
