import {StyleSheet} from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import {theme} from "../../../constants/theme";

export const contentStyles = StyleSheet.create({
    container: {
    },
    section: {
        marginTop: theme.spacing(1),
    },
    infoText: {
        ...theme.typography.subtitle
    }
});

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colours.WHITE,
    },
    row: {
        display: 'flex'
    },
    cancelContainer: {
        marginTop: theme.spacing(3)
    },
    failedPayment: {
        marginTop: theme.spacing(3)
    },
    failedPaymentButton: {
        marginTop: theme.spacing(1)
    },
});
