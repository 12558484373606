import Strings from "../../constants/localization/localization";
import getParkAvailability, { ParkAvailability } from "../../constants/getParkAvailability";
import {ParkingType} from "../../model/Types";
import localizeCurrency from "../../constants/localization/localizeCurrency";
import { EmployeeSubscription } from "../../model/EmployeeSubscription";
import {getSharingStatus} from "../parkingViews/Subscriptions/getSharingStatus";
import moment from "moment";
import getOpeningTime from "../../constants/availability/getOpeningTime";
import { getNextOpenDay, isPermanentlyAvailable, NextOpenDay } from "../../constants/Util";
import Constants from "../../constants/constants";
import {ParkSessionDTO} from "../../model/ParkSessionDTO";
import {ParkDTO} from "../../model/ParkDTO";
import { Nully } from "../../constants/nully";
import {getLongTermPrices} from "../../constants/price.util";
import {BayGroup} from "../../model/BayGroup";
import { TerritoryDTO } from "../../api/territory/dto/TerritoryDTO";
import {BayDTO} from "react/model/BayDTO";

export type StartParkingButtonStatusProps = {
    activeSession: ParkSessionDTO | null,
    activeSubscriptionInPark: EmployeeSubscription | null | undefined,
    parkingType: ParkingType,
    availableBays: BayDTO[],
    park:ParkDTO,
    loading: boolean,
    isMotorBikeDefault: boolean,
    bayGroups: BayGroup[],
    territory: TerritoryDTO
}
export type StartParkingButtonStatus = {
    disabled: boolean,
    buttonText: string,
    buttonSubtext: string | undefined,
    buttonIcon: string
}
export function startParkingButtonStatus(props: StartParkingButtonStatusProps): StartParkingButtonStatus{
    const {
        activeSession,
        activeSubscriptionInPark,
        parkingType,
        availableBays,
        park,
        loading,
        isMotorBikeDefault,
        bayGroups,
        territory
    } = props;
    const parkAvailability = getParkAvailability(park) || null;

    const hasBaysAvailable = !(availableBays?.length === 0);

    const result = {} as {disabled: boolean, buttonText: string, buttonSubtext: string | undefined, buttonIcon: string};

    if(parkingType === ParkingType.LONG_TERM){

        result.buttonIcon = "key";
        if(!!activeSubscriptionInPark){
            result.disabled = true;
            result.buttonText = Strings.view_options;
            result.buttonSubtext = Strings.you_have_a_sub;
        }
        else if(hasBaysAvailable){
            result.disabled = false;
            result.buttonText = Strings.view_options;

            const bay = availableBays.length > 0 ? availableBays[0] : undefined;
            const bayGroup = bay && bayGroups.filter(g => g.id === bay.group)[0];
            const prices = getLongTermPrices(park, bayGroup);
            let pricePerWeek = prices.pricePerWeek;
            let pricePerMonth = prices.pricePerMonth;

            if(!!pricePerWeek) {
                result.buttonSubtext = Strings.parks_from(localizeCurrency(pricePerWeek||0, territory.currencyCode, false, false), Strings.week);
            }
            else if(!!pricePerMonth) {
                result.buttonSubtext = Strings.parks_from(localizeCurrency(pricePerMonth||0, territory.currencyCode, false, false), Strings.month);
            }
            else{
                result.buttonSubtext = undefined;
            }
        }
        else{
            result.disabled = true;
            result.buttonText = Strings.no_subs_available;
        }
    }
    else if(parkingType === ParkingType.ELECTRIC){
        result.buttonIcon = "electricvehicleplug";
        if (!parkAvailability?.isOpenNow) {
            result.disabled = true;
            result.buttonText = Strings.park_closed;
        } else if (!!activeSession) {
            if (activeSession.startedAt === null && park.id === activeSession.park) {
                //user has active reservation at this park
                result.buttonText = Strings.start_session;
                result.disabled = false;
            } else {
                result.buttonText = Strings.start_session;
                result.buttonSubtext = Strings.currently_charging_elsewhere;
                result.disabled = true;
            }
        } else if (!!activeSubscriptionInPark) {
            let isSharing:boolean = false;
            if (!!park.organisation) {
                const employeeSubscription = activeSubscriptionInPark as EmployeeSubscription;
                isSharing = getSharingStatus(employeeSubscription);
            }
            if(isSharing && hasBaysAvailable && !loading) {
                result.buttonText = Strings.park_casually;
                result.buttonSubtext = Strings.your_bay_has_been_shared;
                result.disabled = false;
            } else {
                result.buttonText = Strings.start_session;
                result.disabled = false
            }
        } else if (!hasBaysAvailable) {
            result.buttonText = Strings.reserved_or_full;
            result.disabled = true;
        } else if (loading) {
            result.buttonText = Strings.start_session;
            result.disabled = true;
        } else {
            result.buttonText = Strings.start_session;
            result.disabled = false;
        }
    }
    else {
        result.buttonIcon = isMotorBikeDefault ? "motorbike" : "car";

        if (!parkAvailability?.isOpenNow) {
            result.disabled = true;
            result.buttonText = Strings.park_closed;
        } else if (!!activeSession) {
            if (activeSession.startedAt === null && park.id === activeSession.park) {
                //user has active reservation at this park
                result.buttonText = Strings.view_reservation;
                result.disabled = false;
                // result.buttonSubtext = Strings.view_pricing;
            } else {
                result.buttonText = Strings.start_parking;
                result.buttonSubtext = Strings.you_are_currently_parking;
                result.disabled = true;
            }
        } else if (!!activeSubscriptionInPark) {
            let isSharing:boolean = false;
            if (!!park.organisation) {
                const employeeSubscription = activeSubscriptionInPark as EmployeeSubscription;
                isSharing = getSharingStatus(employeeSubscription);
            }
            if(isSharing && hasBaysAvailable && !loading) {
                result.buttonText = Strings.park_casually;
                // if(hasAccessControl && inRangeGates){
                //     result.buttonText = Strings.open_gate_start_parking;
                // }
                result.buttonSubtext = Strings.your_bay_has_been_shared;
                result.disabled = false;
            } else {
                result.buttonText = Strings.start_parking;
                result.disabled = false
            }
        } else if (!hasBaysAvailable) {
            result.buttonText = Strings.reserved_or_full;
            result.disabled = true;
        } else if (loading) {
            result.buttonText = Strings.start_parking;
            result.disabled = true;
        } else {
            result.buttonText = Strings.start_parking;
            // result.buttonSubtext = Strings.view_pricing;
            // if (hasAccessControl && inRangeGates) {
            //     result.buttonSubtext = Strings.open_gate_start_parking;
            // }

            result.disabled = false;
        }
    }
    return result;
}

export type FutureParkingInfo = {
    availability: ParkAvailability,
    openingTime: moment.Moment,
    isParkOpenTomorrow: boolean,
    nextOpenDay: NextOpenDay | undefined,
    availableToday: boolean,
};

export function futureParkingInfo(park: Omit<ParkDTO, 'territory'>): FutureParkingInfo  {
    const availability = getParkAvailability(park);
    const openingTime = moment(getOpeningTime(availability.todayAvailability), 'h:mm');
    const isParkOpenTomorrow = availability.tomorrowAvailability.available;
    const nextOpenDay = getNextOpenDay(availability.availability);
    const availableToday = moment().isSameOrBefore(openingTime.add(-10, "minutes")) && availability.todayAvailability.available;

    return {
        availability,
        openingTime,
        isParkOpenTomorrow,
        nextOpenDay,
        availableToday
    }
}

export function futureParkingButtonStatus(park: Omit<ParkDTO, 'territory'>, activeSubscriptionInPark: Nully<EmployeeSubscription>, isFutureBooking: boolean): AvailabilityHelper {
    const {
        availability,
        //openingTime,
        isParkOpenTomorrow,
        nextOpenDay,
        availableToday,
    } = futureParkingInfo(park);
    const permanentlyAvailable = isPermanentlyAvailable(availability);
    const parkTomorrowDisabled = (!park.organisation || permanentlyAvailable) ? true : (availableToday ? false : (isParkOpenTomorrow ? false : !nextOpenDay));
    const disableFutureParkButton = !isFutureBooking && parkTomorrowDisabled;

    const futureParkingButtonText = availableToday ?
            Strings.park_later_today : (isParkOpenTomorrow ?
                Strings.park_tomorrow : (!nextOpenDay ?
                    Strings.park_tomorrow : Strings.park_on_someday({isNextWeek: nextOpenDay.isNextWeek??false, text:localizedDay(nextOpenDay.dayOfWeek)})));
    const futureParkingButtonStatus = {availability, isParkOpenTomorrow, nextOpenDay, availableToday, disableFutureParkButton, futureParkingButtonText};
    const subStatus = activeSubscriptionInPark?.status;
    if(!!subStatus && !disableFutureParkButton) {
        if(([...Constants.Active_Subscription_Status_Array, Constants.Subscription_Status_Pending]).includes(subStatus)) {
            let disableFutureParkButtonWhenSub = true;
            if(!!activeSubscriptionInPark?.start && subStatus === Constants.Subscription_Status_Pending) {
                const subStart = moment(activeSubscriptionInPark.start);
                const today = moment();
                const tomorrow = moment().add(1, 'day');
                const someDay = nextOpenDay?.openDay;
                disableFutureParkButtonWhenSub = availableToday ? subStart.isSameOrBefore(today) :
                                            (isParkOpenTomorrow ? subStart.isSameOrBefore(tomorrow) :
                                            !!nextOpenDay && subStart.isSameOrBefore(someDay));
            }
            futureParkingButtonStatus.disableFutureParkButton = disableFutureParkButtonWhenSub;
        }
    }
    return futureParkingButtonStatus;
}

export type AvailabilityHelper = {
    availability: ParkAvailability,
    isParkOpenTomorrow: boolean,
    nextOpenDay: NextOpenDay | undefined,
    availableToday:boolean,
    disableFutureParkButton:boolean,
    futureParkingButtonText: string
}

const localizedDay = (day: string) => {
    switch(day) {
        case 'sunday':
            return Strings.sunday;
        case 'monday':
            return Strings.monday;
        case 'tuesday':
            return Strings.tuesday;
        case 'wednesday':
            return Strings.wednesday;
        case 'thursday':
            return Strings.thursday;
        case 'friday':
            return Strings.friday;
        case 'saturday':
            return Strings.saturday;
        default:
            throw "localizedDay: Unknown day"
    }
};
