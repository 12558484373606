import React, { useContext, useEffect } from "react";
import { ChatContext, ProblemActionContext } from "../Context";
import Strings from "../../../constants/localization/localization";
import { AllProblemViewProps } from "../ProblemView";
import { QuestionTypes } from "./ProblemFlow.types";
import { usePark } from "react/api/park";
import { useBay } from "react/api/bay/bay.api";
import { useParkSessionV3 } from "react/api/parkSession/parkSession.api";
import {onStartChat, onStartChatWeb} from "react/components/support/constants";
import {useUserVehicles} from "react/api/vehicle/vehicle.api";
import {useBayGroup} from "react/api/bayGroup/bayGroup.api";
import * as Device from "expo-device";
import {Platform} from "react-native";

const flow: QuestionTypes = {
    default:	{
        isItSupport: true,
        title: Strings.support_greeting_msg,
        type: "content"
    }
}

export const MessageParkable = (props: AllProblemViewProps) => {

    const { user } = props;

    const {parkId, sessionId, subscription, bayId} = props.route.params;

    const { park } = usePark(parkId);
    const { bay } = useBay(parkId, bayId);
    const { parkSession: session } = useParkSessionV3(sessionId);
    const { vehicles } = useUserVehicles();
    const { bayGroup } = useBayGroup(park?.organisation, bay?.group);
    
    const {messages} = useContext(ChatContext);
    const {problemActions, updateProblemActions } = useContext(ProblemActionContext);

    useEffect(()=> {
        problemActions["onMessageParkablePress"] = onMessageParkablePress;
        updateProblemActions(problemActions);
    }, [messages])

    const onMessageParkablePress = () => {
        if(user.user && vehicles){
            if (!Device.isDevice || Platform.OS === "web") {
                void onStartChatWeb(user.user, vehicles, park?.organisation ?? undefined, bayGroup?.id, session ?? undefined, subscription);
            }else{
                void onStartChat(user.user, vehicles, park?.organisation ?? undefined, bayGroup?.id, session ?? undefined, subscription);
            }
        }
    };

    return <></>
}
