import { useMySWR } from "../http/use-swr";
import { EmployeeSubscriptionResponse, EmployeeSubscriptionsResponse } from "./dto/employeesubscription.response";
import { generateQueryString, get, put } from "../http/rest";
import { Nully } from "react/constants/nully";

export const useEmployeeSubscriptions = () => {
    return useMySWR<EmployeeSubscriptionsResponse>(`v2/employeesubscriptions?onlyActive=true`);
};

export const useUserSubscription = (userId: Nully<number>, showOnlyActive: boolean) => {
    const response = useMySWR<EmployeeSubscriptionsResponse>(userId ? `/v2/employeesubscriptions/user/${userId}?active=${showOnlyActive}` : null, get);
    return {
        ...response,
        employeeSubscriptions: response.data?.subscriptions ?? [],
    };
}

export const updateSubscriptionVehicles = (subscriptionId: number, vehicleIds: Nully<number[]>) => {
    const queryString = generateQueryString({ vehicleIds });
    return put<EmployeeSubscriptionResponse>(`v3/employeesubscriptions/${subscriptionId}/updateVehicles${queryString}`);
};
