export enum PricePeriodType {
    Hourly = "Hourly",
    EarlyBird = "EarlyBird",
    Day = "Day",
    Night = "Night"
}

export enum PricePeriodDay {
    Default = "Default",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday",
    Sunday = "Sunday"
}

export type PricePeriod = {
    id: number,
    parkingPrice: number,
    freePeriodMinutes: number | null,
    type: PricePeriodType,
    price: number,
    day: PricePeriodDay,
    cap: number
    start: number,
    end: number,
}
